/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import {
  getTemplateNamesByCategory,
  splitTemplateNames,
  createTemplateSets,
} from '../utils/categoryUtils';

/**
 * Custom hook to filter and organize templates based on category
 *
 * @param {Array} templates - All available templates
 * @param {String} selectedCategory - Currently selected category
 * @param {Array} templateOrder - Order of templates
 * @param {Object} organizedCategoryMap - Map of template names to categories
 * @returns {Object} Filtered and organized templates for both sides
 */
export const useTemplateFiltering = (
  templates,
  selectedCategory,
  templateOrder,
  organizedCategoryMap,
) => useMemo(() => {
    if (!templates || templates.length === 0) {
      return { leftSideData: [], rightSideData: [] };
    }

    // Get template names for the selected category
    const categoryTemplateNames = getTemplateNamesByCategory(
      selectedCategory,
      templateOrder,
      organizedCategoryMap,
    );

    // Split template names into two sides
    const { rightTemplateNames, leftTemplateNames } = splitTemplateNames(categoryTemplateNames);

    // Create Sets for faster lookups
    const { rightTemplateMap, leftTemplateMap } = createTemplateSets(
      rightTemplateNames,
      leftTemplateNames,
    );

    // Filter and organize templates
    const rightSide = [];
    const leftSide = [];

    templates.forEach(template => {
      // Skip if not in template order
      if (!templateOrder.includes(template?.name)) {
        return;
      }

      // Get template categories
      const templateInfo = organizedCategoryMap[template?.name];
      if (!templateInfo) return;

      const { categories } = templateInfo;

      // Check if template matches selected category
      const matchesCategory =
        selectedCategory === 'All' ||
        categories.includes(selectedCategory);

      if (!matchesCategory) return;

      // Add to appropriate side based on template name
      if (rightTemplateMap.has(template.name)) {
        rightSide.push(template);
      } else if (leftTemplateMap.has(template.name)) {
        leftSide.push(template);
      }
    });

    // Sort templates according to their order in template names
    rightSide.sort((a, b) =>
      rightTemplateNames.indexOf(a.name) - rightTemplateNames.indexOf(b.name),
    );

    leftSide.sort((a, b) =>
      leftTemplateNames.indexOf(a.name) - leftTemplateNames.indexOf(b.name),
    );

    return {
      rightSideData: rightSide,
      leftSideData: leftSide,
    };
  }, [templates, selectedCategory, templateOrder, organizedCategoryMap]);