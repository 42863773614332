import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';
import { lineHeight } from 'jodit/esm/plugins/line-height/line-height';
import { Linkedin } from 'lucide-react';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_5' },
  name: 'Emily Brown',
  position: 'Freelance Graphic Designer',
  email: 'contact@emilybrown.com',
  phone: '484-833-3764',
  website: 'https://www.emilybrown.com',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/3',
  behance: 'https://www.behance.com/#',
  dribbble: 'https://www.dribbble.com/#',
  ctaTitle: 'CHECKOUT MY PORTFOLIO',
  ctaUrl: 'https://newcta.com/#',
};

export const inputValues2 = {
  name: 'Mary Johnson, RN',
  companyName: 'Foster Clinic',
  companyAddress: '456 Wellness BlvdNY 10001',
  companyLogo: { url: 'companylogo_new_TEMPLATE_5_1' },
  phone: '831-316-4684',
  email: 'mary.johnson@fosterclinic.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_5_1',
  ctaBannerUrl: 'https://google.com/#',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_5_2' },
  name: 'Thomas Wilson - Science Teacher',
  companyName: 'Riverside High School',
  email: 'thomas.wilson@riversidehs.edu',
  phone: '+1 728 607 2433',
  phoneTitle: 'Phone.',
  websiteTitle: 'Website.',
  emailTitle: 'Email.',
  facebook: 'https://facebook.com/#',
  twitter: 'https://twitter.com/#',
  linkedin: 'https://linkedin.com/#',
  website: 'https://www.riversidehs.edu/#',
  footer:
    'Please consider the environment before printing this email. Every unprinted email helps the environment.',
  signOff: 'Sincerely,',
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_TEMPLATE_5_3' },
  name: 'Brinley Morris',
  position: 'General Lawyer at Morris Co.',
  companyAddress: '804 Farland Avenue San Francisco, 78045 USA',
  email: 'brinley@morris.com',
  phone: '346-284-144, 267-254-914',
  website: 'www.moris.com',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_5_3',
  ctaBannerUrl: 'https://yourwebsite.com',
  twitter: 'https://www.twitter.com/#',
  skype: 'https://www.skype.com/#',
  wechat: 'https://www.wechat.com/#',
  signOff: 'Best regards,',
  phoneTitle: 'P.',
  emailTitle: 'E.',
  websiteTitle: 'W.',
  addressTitle: 'A.',
};

export const inputValues5 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_5_4' },
  name: 'MICHAEL DAVIS',
  position: 'Student - Job seeker',
  email: 'Michael.davis@gmail.com',
  phone: '555-123-4567',
  ctaTitle: 'Download My CV',
  ctaUrl: 'https://bnb.com/#',
  linkedin: 'https://www.linkedin.com/#',
  facebook: 'https://www.facebook.com/#',
  twitter: 'https://www.twitter.com/#',
  github: 'https://www.github.com/#',
  signOff: 'Sincerely,',
};

const Template5 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#A06962',
      font: 'Comic Sans MS, cursive, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 240,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#5F413D',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'word',
          style: {
            color: '#A06962',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 140,
            borderRadius: 4,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#A06962',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
      name: 'template 5',
      image: null,
      key: 'TEMPLATE_5',
      description: null,
      isActive: true,
      config: null,
      templateId: 5,
    },
    name: '5',
  },
  {
    id: '33887eac-71df-40c0-af9a-964e39acc1f0',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#1B609F',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 300,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#143654',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            color: '#143654',
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            color: '#1B609F',
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1B609F',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 100,
          },
        },
        ctaBanner: {
          style: {
            width: 360,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#1B609F',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
    headshotId: null,
    headshot: null,
    template: {
      id: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
      name: 'template 5',
      image: null,
      key: 'TEMPLATE_5',
      description: null,
      isActive: true,
      config: null,
      templateId: 5,
    },
    name: '5-1',
  },
  {
    id: '4b41828a-ddd5-4a60-9fbc-d47adbfbec6c',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#370099',
      font: 'Trebuchet MS',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 155,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#13062B',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#370099',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 100,
          },
        },
        ctaBanner: {
          style: {
            width: 350,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 15,
          },
        },
        separator: {
          style: {
            color: '#370099',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 380,
            color: '#9C9C9C',
          },
        },
        content: {
          style: {
            width: 600, // Updated signature width
          },
        },
        socialBanners: {
          style: {
            width: 161,
          },
        },
      },
    },
    templateId: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
    headshotId: null,
    headshot: {
      id: '4b41828a-ddd5-4a60-9fbc-d47adbfbec6c',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#cc2425',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
      name: 'template 5',
      image: null,
      key: 'TEMPLATE_5',
      description: null,
      isActive: true,
      config: null,
      templateId: 5,
    },
    name: '5-2',
  },
  {
    id: '2a14d0ff-4168-4d96-bde8-566eed919379',
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#515454',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner',
            width: 470,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#333333',
            fontSize: 14,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 18,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#515454',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 100,
          },
        },
        ctaBanner: {
          style: {
            width: 470,
            ctaborderRadius: 4,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 4,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#515454',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
    headshotId: null,
    headshot: {
      id: '97a31a39-9dd0-4956-8c1a-3da4e566ef2a',
      image:
        'https://static.dev.sendsig.com/headshots/process-headshot-1729752201658.png?timestamp=1729752264382',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7f56d9',
        processedImageKey: 'headshots/process-headshot-1729752201658.png',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
      name: 'template 5',
      image: null,
      key: 'TEMPLATE_5',
      description: null,
      isActive: true,
      config: null,
      templateId: 5,
    },
    name: '5-3',
  },

  {
    id: 'fd33ec2f-78a9-4e23-85c8-42035314629b',
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#253818',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#253818',
            fontSize: 12,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#253818',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 100,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 140,
            borderRadius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 18,
          },
        },
        separator: {
          style: {
            color: '#253818',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
    headshotId: null,
    headshot: null,
    template: {
      id: 'bda1c8a0-9ce0-432e-bbd1-7b878194e28a',
      name: 'template 5',
      image: null,
      key: 'TEMPLATE_5',
      description: null,
      isActive: true,
      config: null,
      templateId: 5,
    },
    name: '5-4',
  },
];

export default Template5;
