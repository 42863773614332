import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: null,
  name: 'Angela Amidon',
  position: 'Operation Consultant, Swell Stays',
  department: 'Don’t miss out! Stay 2 nights get the third for FREE',
  companyAddress: '567 Ocean Drive, Miami Beach, FL',
  phone: '(345) 087-1239',
  website: 'https://www.swellstays.com',
  companyPhone: '(345) 087-1210',
  companyPhoneTitle: 'Cell:',
  phoneTitle: 'Ph:',
  addressTitle: 'Address.',
  websiteTitle: 'Website.',
};

const Template40 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#c85250',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 108,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.42,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#c85250',
            size: 16,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 450,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#c85250',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00246B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 40',
      image: null,
      key: 'TEMPLATE_40',
      description: null,
      isActive: true,
      config: null,
      templateId: 40,
    },
    name: '40',
  },
];

export default Template40;
