import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_21' },
  name: 'Amy Johnson',
  position: 'Marketing Executive',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  companyLogo: { url: 'companylogo_new_TEMPLATE_21', format: 'gif' },
  email: 'amy@clickcrafted.com',
  phone: '(345) 087-1239, (345) 087-2222',
  website: 'www.clickcrafted.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues2 = {
  headshotUrl: null,
  name: 'Oscar Rivera',
  position: 'Certified Life Coach',
  companyName: 'Yoga Trainer',
  companyAddress: '4863 Farland Avenue, AJ',
  email: 'oscar@youryogatrainer.com',
  phone: '(345) 087 - 1239, (345) 087 - 1239',
  website: 'https://youryogatrainer.com',
  ctaBannerUrl: 'https://facebook.com/#',
};

const Template21 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#F5467E',
      font: 'Arial',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 100,
          },
        },
        font: {
          style: {
            color: '#540315',
            fontSize: 13,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
            color: '#F5467E',
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 20,
            color: '#F5467E',
          },
        },
        companyLogo: {
          style: {
            width: 100,
          },
        },
        headshot: {
          style: {
            width: 110,
            borderRadius: 100,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#F5467E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 21',
      image: null,
      key: 'TEMPLATE_21',
      description: null,
      isActive: true,
      config: null,
      templateId: 21,
    },
    name: '21',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#ff6567',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 100,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#7f56d9',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 163,
            borderRadius: 11,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#7f56d9',
            width: 1,
          },
        },
        footer: {
          style: {
            color: '#000000', // Added as per new data
            width: 500, // Added as per new data
            fontSize: 9, // Added as per new data
          },
        },
      },
    },
    templateId: '47def4fb-4a61-4f83-9c4d-781e66be1262',
    headshotId: null, // No headshot ID provided
    template: {
      id: '47def4fb-4a61-4f83-9c4d-781e66be1262',
      name: 'template 21',
      image: null,
      key: 'TEMPLATE_21',
      description: null,
      isActive: true,
      config: null,
      templateId: 21,
    },
    name: '21-1',
  },
];

export default Template21;
