import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_13' },
  name: 'David Johns',
  position: 'Fashion Influencer',
  email: 'collab.david@gmail.com',
  facebook: 'https://www.facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  youtube: 'https://www.youtube.com/#',
  website: 'https://www.davidjohns.com',
  ctaTitle: 'Get inspired by my Instagram Profile',
  ctaUrl: 'https://www.link.com/#',
};

export const inputValues2 = {
  name: 'RACHEL JOHNSON',
  position: 'Yoga Instructor, Asana',
  companyLogo: { url: 'companylogo_TEMPLATE_13_1', format: 'gif' },
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  email: 'rachel.johnson@asanayoga.com',
  phone: '443-554-6089',
  website: 'www.asanayoga.com',
  ctaTitle: 'Book your slot now',
  ctaUrl: 'https://www.google.com',
  instagram: 'https://www.instagram.com/',
  youtube: 'https://www.youtube.com',
  facebook: 'https://www.google.com',
  signOff: 'Thanks,',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_TEMPLATE_13_2', format: 'gif' },
  name: 'David Harris',
  position: 'Senior Sales Director, Synergy Solutions',
  email: 'david@synergysolutions.com',
  phone: '+91 87991 53900',
  website: 'https://www.synergysolutions.com',
  ctaTitle: 'SCHEDULE A DEMO',
  ctaUrl: 'https://bnb.com/#',
  facebook: 'https://www.facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  youtube: 'https://www.youtube.com/#',
};

const Template13 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#632DF6',
      font: 'Trebuchet MS, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 280,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#180F40',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 19,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#632DF6',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 120,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#632DF6',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F8CA0B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 13',
      image: null,
      key: 'TEMPLATE_13',
      description: null,
      isActive: true,
      config: null,
      templateId: 13,
    },
    name: '13',
  },
  {
    id: 'abb36872-29dc-4fad-a35a-9e03277ad160',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#4D6A66',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 150,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#171B1A',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'word',
          style: {
            color: '#4D6A66',
            size: 16,
          },
        },
        companyLogo: {
          style: {
            width: 170,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 87,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
            color: '#4D6A66',
          },
        },
        separator: {
          style: {
            color: '#4D6A66',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 134,
          },
        },
      },
    },
    templateId: 'b30764e8-ed75-4ea3-8e9a-b8acf29a3fad',
    headshotId: null,
    headshot: null,
    template: {
      id: 'b30764e8-ed75-4ea3-8e9a-b8acf29a3fad',
      name: 'template 13',
      image: null,
      key: 'TEMPLATE_13',
      description: null,
      isActive: true,
      config: null,
      templateId: 13,
    },
    name: '13-1',
  },
  {
    id: '8d1a81d0-8834-4d39-abf8-c44d36da3bd2',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#7F56D9',
      font: 'Verdana, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 180,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#180F40',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 18,
          },
        },
        socialIcon: {
          type: 'word',
          style: {
            color: '#7F56D9',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 441,
          },
        },
        headshot: {
          style: {
            width: 80,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#7F56D9',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 92,
          },
        },
      },
    },
    templateId: 'b30764e8-ed75-4ea3-8e9a-b8acf29a3fad',
    headshotId: null,
    headshot: null,
    template: {
      id: 'b30764e8-ed75-4ea3-8e9a-b8acf29a3fad',
      name: 'template 13',
      image: null,
      key: 'TEMPLATE_13',
      description: null,
      isActive: true,
      config: null,
      templateId: 13,
    },
    name: '13-2',
  },
];

export default Template13;
