import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import TemplateCard from 'modules/Templates/components/Template/components/TemplateCard';
import { useRouter } from 'hooks/router';
import { useUpdateGroup, useUpdateGroupQueue } from 'hooks/group';
import { get } from 'lodash';
import AlertDialog from 'components/AlertDialog';
import { toast } from 'hooks/use-toast';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';

const SwitchTemplateDialog = forwardRef(
  ({ fetchWorkSpaceTemplate, alreadySelectedTemplateId, onClose }, ref) => {
    const alertDialogRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [getWorkspaceTeammateByWorkspaceId] =
      useGetWorkspaceTeammateByWorkspaceId();

    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [updateGroup] = useUpdateGroup();
    const [updateGroupQueue] = useUpdateGroupQueue();
    const {
      navigate,
      params: { workspaceId, groupId },
    } = useRouter();
    const isSameTemplateSelected =
      selectedTemplate?.id === alreadySelectedTemplateId?.id;

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => {
        setIsOpen(false);
        setData(null);
        setSearchTerm('');
      },
      onDataChange: (d) => setData(d),
    }));

    const handleTemplateCardOnClick = (template) => {
      setSelectedTemplate(template);
    };

    // const handleSubmit = async () => {
    //   const res = await updateGroup({
    //     variables: {
    //       data: { templateId: selectedTemplate?.id },
    //       where: { id: groupId },
    //     },
    //   });

    //   const response = get(res, 'data.updateGroup', null);
    //   if (response) {
    //     toast({
    //       closeicn: 'success',
    //       description: 'Template assign successfully!',
    //     });
    //   }
    //   fetchWorkSpaceTemplate(selectedTemplate?.id);
    //   await getWorkspaceTeammateByWorkspaceId({
    //     variables: {
    //       where: {
    //         workspaceId,
    //       },
    //     },
    //   });
    //   setData([]);
    //   setIsOpen(false);

    //   alertDialogRef?.current?.close();
    // };

    const handleSubmit = async () => {
      const res = await updateGroupQueue({
        variables: {
          data: { templateId: selectedTemplate?.id },
          where: { id: groupId },
        },
      });

      const response = get(res, 'data.updateGroupQueue', null);
      if (response) {
        toast({
          closeicn: 'success',
          description: 'Template assign successfully!',
        });
      }
      fetchWorkSpaceTemplate(selectedTemplate?.id);
      await getWorkspaceTeammateByWorkspaceId({
        variables: {
          where: {
            workspaceId,
          },
        },
      });
      setData([]);
      setIsOpen(false);

      alertDialogRef?.current?.close();
      if (onClose) {
        onClose();
      }
    };

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const filteredData =
      data?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
      ) ?? [];

    return (
      <>
        <AlertDialog ref={alertDialogRef} handleOk={handleSubmit} />

        <Dialog
          open={isOpen}
          onOpenChange={(e) => {
            setIsOpen(e);
            setData(null);
            setSearchTerm('');
          }}
        >
          <DialogContent
            className="rounded-[6px] max-w-[900px] py-[22px]"
            hideCloseButton
          >
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">
                Assign signature template to group
              </h2>
            </div>
            {filteredData?.length ? (
              <>
                <div className="p-4 bg-white rounded-lg shadow-lg overflow-auto custom-scrollbar">
                  {/* <div className="mb-4">
            <p className="text-lg">Select signature template</p>
            <Input
              type="text"
              placeholder="Search"
              className="border border-gray-300 rounded-lg mt-2"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div> */}
                  <div className="flex p-2 gap-3">
                    {filteredData.map((item) => (
                      <div key={item?.id}>
                        <TemplateCard
                          handleTemplateCardOnClick={handleTemplateCardOnClick}
                          data={item}
                          count={filteredData.length}
                          hideAction
                          size="sm"
                          templateCardClassname={`max-w-[200px] min-w-[200px] h-[130px] m-0 ${
                            selectedTemplate?.id === item?.id
                              ? 'shadow-signature'
                              : ''
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-center">
                    <Button
                      disabled={!selectedTemplate || isSameTemplateSelected}
                      onClick={() => {
                        alertDialogRef?.current?.open();
                      }}
                      // className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700"
                    >
                      Select a template
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center mt-8">
                <div className="text-lg font-semibold text-gray-700 mb-4">
                  No templates available
                </div>
                <Button
                  onClick={() => {
                    navigate(`/app/workspace/${workspaceId}/templates`);
                  }}
                  className="px-4 py-2 text-white-0 rounded transition"
                  size="sm"
                >
                  Add Template
                </Button>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  },
);

export default SwitchTemplateDialog;
