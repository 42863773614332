import React, { useEffect, useRef, useState } from 'react';
import { EllipsisHorizontalIcon } from 'assets/svg';
import SignatureTemplate from 'components/SignatureTemplate';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { cn } from 'lib/utils';
import { useUserPermission, useGetRoleForWorkspace } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';

const TemplateCard = ({
  data = [],
  actions = {},
  count,
  hideAction = false,
  handleTemplateCardOnClick,
  templateCardClassname,
  signatureTemplateClassname,
  size = 'md',
  showAwsIconsFlag = true,
}) => {
  const {
    handleAssignToGroup = () => {},
    handleEdit = () => {},
    handleDelete = () => {},
    handleDuplicate = () => {},
  } = actions;

  const contentRef = useRef();
  const { role } = useGetRoleForWorkspace();
  const { isFeaturesAllowed } = useUserPermission();
  const [transformStyle, setTransformStyle] = useState({});

  const items = [
    // {
    //   onClick: (e) => {
    //     handleAssignToGroup(data);
    //   },
    //   label: <span>Assign to group</span>,
    //   key: '0',
    //   hide: false,
    // },
    {
      onClick: (e) => {
        handleEdit(data);
      },
      label: <span>Rename</span>,
      key: '1',
      hide: false,
    },
    {
      onClick: (e) => {
        handleDuplicate(data);
      },
      label: <span>Duplicate</span>,
      key: '2',
      // hide: count === 1,
    },

    {
      onClick: (e) => {
        handleDelete(data);
      },
      label: <span>Delete</span>,
      key: '3',
      hide: count === 1,
    },
  ];

  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  const DIMENSION_MAP = {
    sm: {
      width: '200px',
      height: '130px',
      scale: 0.4,
    },
    md: {
      width: '350px',
      height: '220px',
      scale: 0.7,
    },
  };

  useEffect(() => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      const selectedSize = DIMENSION_MAP[size] || DIMENSION_MAP.md;
      const scaleFactor = selectedSize.scale;
      const DIMENSION_FACTOR_MAP = {
        sm: 1.2,
        md: 0.2,
      };

      const widthFactor = DIMENSION_FACTOR_MAP[size] || DIMENSION_FACTOR_MAP.md;
      const heightFactor =
        DIMENSION_FACTOR_MAP[size] || DIMENSION_FACTOR_MAP.md;

      // Adjust the transform based on dynamic width and height
      setTransformStyle({
        transform: `scale(${scaleFactor}) translate(-${width * scaleFactor * widthFactor}px, -${height * scaleFactor * heightFactor}px)`,
        pointerEvents: 'none',
      });
    }
  }, [size, contentRef]);

  const dynamicDimensions = DIMENSION_MAP[size] || DIMENSION_MAP.md;

  return (
    <div
      onClick={() => handleTemplateCardOnClick(data)}
      // onClick={handleOnClick}
      className={cn(
        `bg-white-0 relative flex justify-start
max-w-[350px] min-w-[350px] h-[220px] rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2
bg-white border-[1px] border-solid border-gray-0 hover:border-solid hover:border-[1px]  hover:border-primary hover:shadow-signature`,
        templateCardClassname,
      )}
    >
      <div className="w-full relative">
        <div style={transformStyle} className={cn(signatureTemplateClassname)}>
          <div
            ref={contentRef}
            style={{
              height: dynamicDimensions.height,
              width: dynamicDimensions.width,
            }}
          >
            {data && (
              <SignatureTemplate
                signature={data}
                showAwsIcons={showAwsIconsFlag}
                hideBranding={hideBranding}
              />
            )}
          </div>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute bottom-0 w-full box-border flex justify-between items-center px-3 py-2 bg-primary-foreground"
      >
        <div>{data?.name}</div>
        {!hideAction && (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <EllipsisHorizontalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" bg-white-0 border-solid border-1 border-gray-400 ">
                {items.map(({ label, key, onClick, hide = false }) => {
                  if (hide) {
                    return;
                  }
                  return (
                    <DropdownMenuItem
                      key={key}
                      value={key}
                      onClick={onClick}
                      className="w-[auto]"
                    >
                      {label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateCard;
