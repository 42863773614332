import { ChevronRight, Zap } from 'lucide-react';
import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { get, isString } from 'lodash';
import { useAppContext } from 'AppContext';
import { PRIMARY_COLOR, onboardingStatus } from 'common/constants';
import { Button } from 'components/ui/button';
import HeadshotList from 'components/HeadshotList';
import ColorPicker from 'components/ColorPicker';
import { useRouter } from 'hooks/router';
import { useHeadshotUpload } from 'hooks/headshot';
import { UPDATE_USER } from 'modules/Auth/graphql/Mutations';
import { GET_STYLES } from 'modules/Headshot/graphql/Queries';
import { useGetUser } from 'hooks/user';
import { useSignatureId, useUpsertSignature } from 'hooks/signature';
import AiHeadshotButton from 'components/AiHeadshotButton';
import { useWindowSize } from 'helpers/useWindowSize';
import { useWorkspaceId } from 'hooks/workspace';
import OnboardingSignaturePreview from 'components/OnboardingSignaturePreview';

function HeadshotEditor() {
  const {
    state: { signature, headshot },
    setUserLocalData,
    setHeadshot,
  } = useAppContext();

  const primaryBrandColor = get(signature, 'design.primaryBrandColor', {});
  const { handleGlobalHeadshotUpload } = useHeadshotUpload();
  const [primaryColor] = useState(primaryBrandColor || PRIMARY_COLOR);
  const { size } = useWindowSize();
  const { navigate } = useRouter();
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const [upsertSignature] = useUpsertSignature();

  const [getUser] = useGetUser();
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted(res) {
      setUserLocalData(res?.updateUser?.data);
    },
    onError() {},
  });

  const { data } = useQuery(GET_STYLES, {
    variables: {
      pagination: { skip: 0, limit: 6 },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (!headshot?.style?.id) {
        const { __typename, ...styles } = res?.styles?.data?.[0] || {};
        setHeadshot({
          ...headshot,
          config: {
            'background-color': primaryColor,
          },
          styleId: styles?.id,
          style: styles,
        });
      }
    },
    onError() {},
  });

  const { styles: { data: stylesData } = {} } = data || {};
  const handleNextStep = async () => {
    if (!signatureId) {
      return;
    }

    const updatedHeadshot = await handleGlobalHeadshotUpload(
      headshot,
      `${headshot?.style?.key}`,
    );
    const previousDownloadUrl = updatedHeadshot?.config?.output
      ? `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${updatedHeadshot?.config?.output}`
      : '';
    const { id, ...restData } = signature;
    const sigabatureClone = { ...restData };
    const updatedFields = sigabatureClone?.fields?.map((field) =>
      field.name === 'headshotUrl'
        ? { ...field, value: previousDownloadUrl }
        : field,
    );
    if (!updatedFields.some((field) => field.name === 'headshotUrl')) {
      updatedFields.push({
        name: 'headshotUrl',
        value: previousDownloadUrl,
        type: 'employee',
        isVariable: true,
        variableValue: null,
        label: 'Headshot URL',
        utagEnabled: false,
        link: '',
        shortLink: '',
      });
    }

    await upsertSignature({
      variables: {
        data: { ...restData, fields: updatedFields },
        where: {
          signatureId: id,
          workspaceId,
        },
      },
    });

    // await updateUser({
    //   variables: {
    //     data: { onboardingStatus: onboardingStatus?.COMPLETED },
    //   },
    // });

    navigate(`/app/${workspaceId}/onboarding/info/${signatureId}`);
  };

  const handleSelect = async (ref) => {
    const { __typename, ...selectedStyle } = stylesData?.find(
      (d) => d.key === ref.current.id,
    );

    setHeadshot({
      ...headshot,
      style: {
        ...headshot?.style,
        ...selectedStyle,
      },
      styleId: selectedStyle?.id,
    });
  };

  return (
    <div className="min-h-screen h-full max-h-screen overflow-auto">
      {/* {!['xs', 'sm'].includes(size) && (
        <div className="h-[36px] max-w-full px-[24px] py-[16px] bg-[#1F192B] text-white-0  font-[600] text-[22px] leading-[36px] m-auto flex justify-center items-center">
          Experience the taste of AI-headshots for free! ✨{' '}
          <AiHeadshotButton className=" ml-4 flex h-[32px] px-[20px] justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Try for free
          </AiHeadshotButton>
        </div>
      )} */}
      <div className="h-screen w-full bg-primary-foreground flex-col table">
        <div className=" py-40 px-24 md:p-24 sm:py-12 sm:px-6 bg-primary-foreground flex-col table-cell  ">
          {/* <section className="mb-[30px] 2xl:mb-[20px] flex items-center gap-[6px] ">
            <img src="/logo.svg" height={40} width={200} alt="logo" />
          </section> */}

          <section className="headshot-preview-section flex items-center justify-center mb-[60px]">
            <div className="max-w-[850px] w-full">
              <HeadshotList
                colorPicked={
                  headshot?.config?.['background-color'] || primaryColor
                }
                handleSelect={handleSelect}
                svgColor="white"
                rounded
                stylesData={stylesData}
                clickable
              />
            </div>
          </section>
          <section className="headshot-control-panel flex justify-center gap-4">
            <div className="flex justify-center gap-1">
              <ColorPicker
                value={headshot?.config?.['background-color'] || primaryColor}
                onChange={(value) => {
                  const color = isString(value) ? value : `#${value?.toHex()}`;

                  setHeadshot({
                    ...headshot,
                    config: {
                      ...headshot?.config,
                      'background-color': color,
                    },
                  });
                }}
                colorPickerWrapperClassName="flex items-center justify-center"
                className="signature-color-picker"
                isPresetShow
              />
            </div>
          </section>
          <section className="flex justify-center">
            <Button onClick={handleNextStep}>
              <div className="flex items-center justify-center space-x-1 w-[200px]">
                <span>Next</span>
                <ChevronRight width="20" height="20" />
              </div>
            </Button>
          </section>
        </div>
      </div>
      {/* {['xs', 'sm'].includes(size) && (
        <div className="flex justify-center w-full bg-primary-foreground">
          <AiHeadshotButton className="flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Try AI Headshot
            <Zap size={16} className="ml-[6px] fill-white-0" />
          </AiHeadshotButton>
        </div>

      )} */}
      {/* <div className="bg-[#E2D7F8] flex items-center justify-center">
        <OnboardingSignaturePreview signature={signature} />
      </div> */}
    </div>
  );
}

export default HeadshotEditor;
