/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { SCROLL_LOAD_COUNT, SCROLL_THRESHOLD } from '../utils/constants';

/**
 * Custom hook to handle scroll events for loading more templates
 *
 * @param {Boolean} isOpen - Whether the dialog is open
 * @param {Boolean} initialLoadComplete - Whether initial loading is complete
 * @param {Object} dialogContentRef - Ref to the dialog content element
 * @param {Object} scrollListenerRef - Ref to store scroll listener info
 * @param {Number} rightSideDataLength - Number of templates on right side
 * @param {Number} leftSideDataLength - Number of templates on left side
 * @param {Function} setVisibleRightCount - Function to update visible right count
 * @param {Function} setVisibleLeftCount - Function to update visible left count
 */
export const useTemplateScroll = (
  isOpen,
  initialLoadComplete,
  dialogContentRef,
  scrollListenerRef,
  rightSideDataLength,
  leftSideDataLength,
  setVisibleRightCount,
  setVisibleLeftCount,
) => {
  useEffect(() => {
    // Skip if dialog is not open, or if refs are not available
    if (!isOpen || !dialogContentRef.current || !initialLoadComplete) return;

    // Cleanup previous listener if exists
    if (scrollListenerRef.current) {
      const prevElement = scrollListenerRef.current.element;
      const prevListener = scrollListenerRef.current.listener;
      if (prevElement && prevListener) {
        prevElement.removeEventListener('scroll', prevListener);
      }
      scrollListenerRef.current = null;
    }

    // Function to handle scroll events
    const handleScroll = () => {
      if (!dialogContentRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;

      // Load more when user scrolls down to the threshold
      if (scrollTop + clientHeight >= scrollHeight * SCROLL_THRESHOLD) {
        // Load more templates on both sides
        setVisibleRightCount(prev =>
          Math.min(prev + SCROLL_LOAD_COUNT, rightSideDataLength),
        );

        setVisibleLeftCount(prev =>
          Math.min(prev + SCROLL_LOAD_COUNT, leftSideDataLength),
        );
      }
    };

    // Add scroll event listener
    const contentElement = dialogContentRef.current;
    contentElement.addEventListener('scroll', handleScroll);

    // Store references for cleanup
    scrollListenerRef.current = {
      element: contentElement,
      listener: handleScroll,
    };

    // Cleanup function
    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    isOpen,
    initialLoadComplete,
    rightSideDataLength,
    leftSideDataLength,
    dialogContentRef,
    scrollListenerRef,
    setVisibleRightCount,
    setVisibleLeftCount,
  ]);
};