import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_30' },
  name: 'Robert Brown',
  position: 'Managing Director - Creative Venture Co.',
  email: 'robert.brown@creative.com',
  website: 'www.creative.com',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  ctaTitle: 'Need work? We are hiring!',
  ctaUrl: 'https://www.link.com',
  linkedin: 'https://www.linkedin.com/',
  twitter: 'https://www.x.com',
};

const Template30 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#0081C6',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 160,
            borderRadius: 3,
          },
        },
        font: {
          style: {
            color: '#072E4A',
            fontSize: 13,
            lineHeight: 1.39,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
            color: '#0081C6',
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 19,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 19,
            color: '#0081C6',
          },
        },
        headshot: {
          style: {
            width: 110,
            borderRadius: 10,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#0081C6',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshotUrl: '',
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: '',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F8CA0B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 30',
      image: null,
      key: 'TEMPLATE_30',
      description: null,
      isActive: true,
      config: null,
      templateId: 30,
    },
    name: '30',
  },
];

export default Template30;
