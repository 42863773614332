// hooks/useQueueStatus.js
import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_QUEUE_STATUS } from 'modules/Queue/graphql/Queries';
import { toast } from './use-toast';

/* eslint-disable import/prefer-default-export */
export const useGetQueueStatus = () => {
  const [getQueueStatus, { loading, data, error }] = useLazyQuery(
    GET_QUEUE_STATUS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const queueStatus = res?.getQueueStatus;
        if (queueStatus) {
          // eslint-disable-next-line no-console
          console.log(`Queue Status: ${JSON.stringify(queueStatus)}`);
        }
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching queue status:', error.message);
        toast({
          variant: 'destructive',
          description: 'Failed to fetch queue status. Please try again later.',
        });
      },
    },
  );

  return {
    getQueueStatus, // Function to trigger the query
    loading, // Indicates whether the query is loading
    data, // Response data
    error, // Error object, if any
  };
};

export const useQueuePolling = (queueName, onQueueFinish) => {
  const [queueStatus, setQueueStatus] = useState({
    completed: 0,
    delayed: 0,
    failed: 0,
  });

  const [queueLoading, setQueueLoading] = useState(false);
  const { getQueueStatus } = useGetQueueStatus();

  const pollQueueStatus = async () => {
    if (!queueName) {
      // eslint-disable-next-line no-console
      console.error('Queue name is required for polling');
      return;
    }
    try {
      const interval = setInterval(async () => {
        const { data } = await getQueueStatus({
          variables: { queueName },
        });

        if (data?.getQueueStatus) {
          setQueueStatus({
            completed: data.getQueueStatus.completed,
            delayed: data.getQueueStatus.delayed,
            failed: data.getQueueStatus.failed,
          });

          if (data.getQueueStatus.delayed > 0) {
            setQueueLoading(true);
          } else {
            setQueueLoading(false);
            clearInterval(interval); // Stop polling when no delayed jobs
            if (onQueueFinish) {
              onQueueFinish();
            }
          }
        }
      }, 2000); // Poll every 2 seconds

      return () => clearInterval(interval); // Cleanup function
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error polling queue status:', error);
    }
  };

  useEffect(() => {
    pollQueueStatus(); // Automatically start polling when hook is used
  }, []);

  return { queueStatus, queueLoading, pollQueueStatus };
};
