import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_10' },
  name: 'Ryan Purcell',
  position: 'Tax Advisor at Lorex Accountants',
  companyAddress: '4317 Saint Clair Street, Tupelo',
  email: 'ryan.purcell.@lorex.com',
  phone: '662-572-9043',
  website: 'https://www.lorexsolution.com',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.x.com/#',
  facebook: 'https://www.google.com/#',
  medium: 'https://www.medium.com/#',
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.',
};

const Template10 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#09415C',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            width: 220,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#020F15',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#09415C',
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#09415C',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 520,
            color: '#BCBCB3',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#8E8E8E',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 2',
        image: null,
        key: 'STYLE_2',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 10',
      image: null,
      key: 'TEMPLATE_10',
      description: null,
      isActive: true,
      config: null,
      templateId: 10,
    },
    name: '10',
  },
];

export default Template10;
