import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_29' },
  name: 'Marcin Kowalski',
  email: 'Marcin@gmail.com',
  phone: '(345) 087-7896',
  website: 'https://www.marcin.pl',
  ctaUrl: 'https://facebook.com/#',
  companyPhone: '(888) 234-5678',
  footer:
    '<p><strong style="font-size: 16px;">Autumn Deal! Premium software free for a year</strong></p><p><strong style="font-size: 16px;"><br>Or <a href="http://www.google.com">protect 10 </a><a href="http://www.google.com"><a href="http://www.google.com">devices for the price of 1</a>.</a></strong></p>',
};

const Template29 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#67595e',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#67595e',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 300,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 75,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#900020',
            width: 3,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 29',
      image: null,
      key: 'TEMPLATE_29',
      description: null,
      isActive: true,
      config: null,
      templateId: 29,
    },
    name: '29',
  },
];

export default Template29;
