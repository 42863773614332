import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNATURE = gql`
  query Signature($where: SignatureUniqueWhereInput) {
    signature(where: $where) {
      id
      workspaceId
      installation
      fields
      information {
        personalInformation {
          name
          position
          department
        }
        businessInformation {
          companyName
          companyAddress
          companyLogo
          logoWidth
        }
        contactInformation {
          email
          phone
          website
          websiteShortLink
          emailShortLink
        }
        socialLinks
        shortSocialLinks
        footer
      }
      design {
        primaryBrandColor
        font
        theme
        icons
        styles
      }
      cta {
        title
        url
        shortUrl
      }
      templateId
      headshotId
      headshotUrl
      headshot {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
      }
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
      name
    }
  }
`;
export const GET_TEAMMATE_SIGNATURE = gql`
  query GetTeammateSignature($where: GetTeammateSignatureInput!) {
    getTeammateSignature(where: $where) {
      id
      fields
      installation
      information {
        personalInformation {
          name
          position
          department
        }
        businessInformation {
          companyName
          companyAddress
          companyLogo
          logoWidth
        }
        contactInformation {
          email
          phone
          website
          websiteShortLink
          emailShortLink
        }
        socialLinks
        shortSocialLinks
        footer
      }
      design {
        primaryBrandColor
        font
        theme
        icons
        styles
      }
      cta {
        title
        url
        shortUrl
      }
      templateId
      headshotId
      headshotUrl
      headshot {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
      }
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
      workspaceId
      groupId
      name
    }
  }
`;

export const GET_SIGN_URL = gql`
  query GetCompanyLogoUploadSignedUrl(
    $data: GetCompanyLogoUploadSignedUrlInput!
  ) {
    getCompanyLogoUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
export const GET_CTA_BANNER_SIGN_URL = gql`
  query GetCtaBannerUploadSignedUrl($data: GetCtaBannerUploadSignedUrlInput!) {
    getCtaBannerUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_SIGN_OFF_IMG_SIGNED_URL = gql`
  query GetSignOffImgSignedUrl($data: GetSignOffImgSignedUrlInput!) {
    getSignOffImgSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_SIGNATURE_ICON_SIGNED_URL = gql`
  query GetSignatureIconSignedUrl($data: UploadIconInput!) {
    getSignatureIconSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const GET_ANALYTICS = gql`
  query AnalyticLinks($where: SignatureUniqueWhereInput!) {
    analyticLinks(where: $where) {
      data {
        refId
        shortLink
        destinationLink
        createdAt
      }
      count
    }
  }
`;

export const GET_ANALYTICS_FOLDER = gql`
  query FolderAnalytics(
    $where: SignatureUniqueWhereInput!
    $filter: AnalyticsFilter
  ) {
    folderAnalytics(where: $where, filter: $filter) {
      clicks {
        clicks
        monthWise {
          clicks
          month
          year
        }
      }
      countries {
        count
        totalCountryCount
        country {
          country
          count
          countryCode
        }
      }
      browsers {
        count
        totalBrowserCount
        browser {
          browser
          count
        }
      }
      operatingSystems {
        count
        totalOsCount
        operatingSystem {
          os
          count
        }
      }
      devices {
        count
        totalDeviceCount
        devices {
          count
          name
          platform
          platformCount
        }
      }
    }
  }
`;

export const GET_ANALYTICS_LINKS = gql`
  query LinkAnalytics(
    $where: AnalyticsWhereUniqueInput!
    $filter: AnalyticsFilter
  ) {
    linkAnalytics(where: $where, filter: $filter) {
      clicks {
        clicks
        monthWise {
          clicks
          month
          year
        }
      }
      countries {
        count
        totalCountryCount
        country {
          country
          count
          countryCode
        }
      }
      browsers {
        count
        totalBrowserCount
        browser {
          browser
          count
        }
      }
      operatingSystems {
        count
        totalOsCount
        operatingSystem {
          os
          count
        }
      }
      devices {
        count
        totalDeviceCount
        devices {
          count
          name
          platform
          platformCount
        }
      }
    }
  }
`;

export const GET_SIGNATURE_LISTING = gql`
  query Signature($pagination: Pagination, $where: GetAllSignatureInput!) {
    signatures(pagination: $pagination, where: $where) {
      count
      data {
        id
        name
        fields
        templateId
        headshotId
        headshotUrl
        installation

        headshot {
          id
          image
          config
          styleId
          style {
            id
            name
            image
            key
            description
            isActive
          }
        }
        cta {
          title
          url
          shortUrl
        }
        design {
          primaryBrandColor
          font
          theme
          icons
          styles
        }
        information {
          personalInformation {
            name
            position
            department
          }
          businessInformation {
            companyName
            companyAddress
            companyLogo
            logoWidth
          }
          contactInformation {
            email
            phone
            website
            websiteShortLink
            emailShortLink
          }
          socialLinks
          shortSocialLinks
          footer
        }
        template {
          id
          name
          image
          key
          description
          isActive
          config
          templateId
        }
      }
    }
  }
`;
