import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_3' },
  name: 'David Johnson',
  position: 'Co-Founder & CTO',
  email: 'david.johnson@innovatechlabs.com',
  phone: '(345) 087 - 1239',
  website: 'https://www.innovatechlabs.com',
  companyAddress: '11266 Monarch St. Ste B, Garden Grove, CA 92841',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
  youtube: 'https://www.youtube.com/#',
  socialBanners: [
    {
      link: 'https://appstore.com/#',
      name: 'socialBanners-AppStore',
      value: 'https://appstore.com/#',
      imgUrl:
        'https://static.sendsig.com/asset/appstore-social-banner-icon-black.png?variant=1',
      platform: 'App Store',
    },
    {
      link: 'https://googleplay.com/#',
      name: 'socialBanners-GooglePlay',
      value: 'https://googleplay.com/#',
      imgUrl:
        'https://static.sendsig.com/asset/googleplay-social-banner-icon-black.png?variant=1',
      platform: 'Google Play',
    },
  ],
  footer:
    'If you do not wish to receive more information about our products and/or services, you can unsubscribe by replying to this email with the subject line "DO NOT SEND"',
};

export const inputValues2 = {
  name: 'Melvin McClain',
  position: 'Tax Advisor at Treadease',
  phone: '(789) 456-1230',
  website: 'https://www.treadease.com',
  companyEmail: 'melvin.mcclain@treadease.com',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_3_2' },
  name: 'David Wilson',
  position: 'Freelance Designer',
  email: 'david.wilson@gmail.com',
  phone: '(345) 087 - 1239',
  website: 'https://www.davidwilson.com',
  ctaTitle: 'Checkout My Portfolio',
  ctaUrl: 'https://bookcall.com',
  linkedin: 'https://linkin.com/company/#',
  behance: 'https://www.google.com/company/#',
  dribbble: 'https://www.dribbble.com/company/#',
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_TEMPLATE_3_3' },
  name: 'Dr. Michael Johnson, DDS',
  position: 'Cosmetic Dentist',
  companyName: 'Johnson Smile Clinic',
  companyAddress: '234 Bright Smile Road, Suite 400, Orlando, FL 32801',
  email: 'michael.johnson@johnsonsmile.com',
  phone: '(321) 654-9876',
  website: 'https://www.johnsonsmile.com',
  ctaTitle: 'Read Reviews 🖍',
  ctaUrl: 'https://bookcall.com',
};

export const inputValues5 = {
  headshotUrl: { url: 'headshot_TEMPLATE_3_4' },
  name: 'Dr. Emily Carter, MD',
  position: 'Cardiologist Specialist',
  department: 'HeartHealth Medical Center',
  companyEmail: 'emily.carter@hearthealth.com',
  companyPhone: '443-554-6089',
  website: 'https://www.hearthealth.com',
  companyAddress: '123 Heartbeat Lane, Suite 200, Baltimore, MD 21201',
  ctaTitle: 'Do you need help? Schedule Consultation',
  ctaUrl: 'https://www.bnb.com/#',
  facebook: 'https://www.facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
};

export const inputValues6 = {
  headshotUrl: 'headshot_new_TEMPLATE_3_5',
  name: 'GUNNER LEE',
  position: 'Nurse at Private Clinic',
  email: 'lee@gmail.com',
  phone: '(345) 087 - 1239',
  companyAddress: '4456 Clousson Road Station, IA 51573',
  ctaTitle: 'Book an online consultation',
  ctaUrl: 'https://bnb.com/#',
  facebook: 'https://www.facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/#',
  phoneTitle: 'Phone.',
  addressTitle: 'Address.',
  emailTitle: 'Email.',
};

const Template3 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#008352',
      font: 'Arial, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 420,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#003020',
            fontSize: 13,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#008352',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#003020',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#AEBAB3',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#1C2993',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#111755',
            fontSize: 13,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1C2993',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#1C2993',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3-1',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#CD612C',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 140,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#3A1810',
            fontSize: 13,
            lineHeight: 1.29,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#CD612C',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
          },
        },
        headshot: {
          style: {
            width: 110,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#CD612C',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: null,
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3-2',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#40838d',
      font: 'Times New Roman, Times, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 173,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.25,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 98,
            borderRadius: 7,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
          },
        },
        separator: {
          style: {
            color: '#40838d',
            width: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#40838d',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'a73adff7-0d3b-45f7-90e4-42fa33a78e48',
      image:
        'https://static.dev.sendsig.com/headshots/process-headshot-1729679327262.png?timestamp=1729679391873',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7f56d9',
        processedImageKey: 'headshots/process-headshot-1729679327262.png',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3-3',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#027f9a',
      font: 'Trebuchet MS, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 266,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#585858',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 100,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
          },
        },
        separator: {
          style: {
            color: '#1f87a8',
            width: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1f87a8',
            size: 19,
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: null,
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3-4',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues6),
    design: {
      primaryBrandColor: '#539ed9',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 210,
            borderRadius: '15px',
          },
        },
        font: {
          style: {
            color: '#373c41',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 20,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: '100px',
          },
        },
        ctaBanner: {
          style: {
            width: 250,
          },
        },
        separator: {
          style: {
            color: '#539ed9',
            width: 2,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#539ed9',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: null,
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 3',
      image: null,
      key: 'TEMPLATE_3',
      description: null,
      isActive: true,
      config: null,
      templateId: 3,
    },
    name: '3-5',
  },
];

export default Template3;
