import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';
import CompanyAddress from '../Templates/components/CompanyAddress';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_14' },
  name: 'Amber Medeows',
  position: 'Property Manager at MONOLINE & CO.',
  email: 'amber.medeows@monoline.com',
  phone: '(345) 087 - 1239',
  website: 'https://www.monoline.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_14',
  ctaBannerUrl: 'https://yourwebsite.com',
  facebook: 'https://facebook.com/company/#',
  instagram: 'https://www.instagram.com/company/#',
  tiktok: 'https://www.tiktok.com/company/#',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_TEMPLATE_14_1' },
  name: 'Liam King',
  position: 'Musician & Composer',
  phone: '775-427-8134',
  email: 'liam.king@popmusic.com',
  website: 'www.popmusic.com',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  ctaUrl: 'https://yourwebsite.com',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_14_1',
  ctaBannerUrl: 'https://yourwebsite.com',
  facebook: 'https://www.facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  youtube: 'https://www.youtube.com/#',
};

const Template14 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#207539',
      font: 'Arial',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 141,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#041409',
            fontSize: 13,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#207539',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
            borderRadius: 10,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
            borderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 120,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#207539',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 14',
      image: null,
      key: 'TEMPLATE_14',
      description: null,
      isActive: true,
      config: null,
      templateId: 14,
    },
    name: '14',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#6E5285',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 420,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#3A2C49',
            fontSize: 12,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#6E5285',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 140,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#6E5285',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '25ceb378-1148-4acb-b784-4d71d03771ff',
    headshotId: null,
    headshot: null,
    template: {
      id: '25ceb378-1148-4acb-b784-4d71d03771ff',
      name: 'template 14',
      image: null,
      key: 'TEMPLATE_14',
      description: null,
      isActive: true,
      config: null,
      templateId: 14,
    },
    name: '14-1',
  },
];

export default Template14;
