/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useRouter } from 'hooks/router';
import { useAppContext } from 'AppContext';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import { TEMPLATE_ORDER, useFilteredTemplates } from 'hooks/template';
import { map } from 'lodash';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import LoaderComponent from 'components/LoaderComponent';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { createOrganizedCategoryMap } from 'components/TemplatesModel/utils/categoryUtils';
import {
  CATEGORY_MODELS,
  INITIAL_LOAD_DELAY,
} from 'components/TemplatesModel/utils/constants';
import TemplatePlaceholder from 'components/TemplatesModel/components/TemplatePlaceholder';
import { useTemplateFiltering } from 'components/TemplatesModel/hooks/useTemplateFiltering';
import Button from 'components/Button';
import { useWindowSize } from 'helpers/useWindowSize';

// Pre-organize category map for faster lookups
const organizedCategoryMap = createOrganizedCategoryMap();

/**
 * Template Selection Page Component
 * Displays all available templates for user selection during onboarding
 */
const SelectTemplate = () => {
  const { navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();
  const { size } = useWindowSize();
  const { setSignature } = useAppContext();
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const contentRef = useRef(null);

  const { templates, loading } = useFilteredTemplates();
  const { allowedTemplates = [], isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  // Calculate if branding should be hidden
  const hideBranding = useMemo(
    () =>
      isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
      role === ROLES.TEAMMATE ||
      role === ROLES.MANAGER,
    [isFeaturesAllowed, role],
  );

  // Use the same template filtering hook as the original component
  const { leftSideData, rightSideData } = useTemplateFiltering(
    templates,
    selectedCategory,
    TEMPLATE_ORDER,
    organizedCategoryMap,
  );

  // Handle category change
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setInitialLoadComplete(false);

    // Reset scroll position
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }

    // Delay showing templates similar to the original component
    setTimeout(() => {
      setInitialLoadComplete(true);
    }, INITIAL_LOAD_DELAY);
  };

  // Initialize with loading delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoadComplete(true);
    }, INITIAL_LOAD_DELAY);

    return () => clearTimeout(timer);
  }, []);

  const handleTemplateSelect = (template) => {
    // Set the selected template in the signature context
    setSignature({
      templateId: template?.templateId,
      template: template?.template,
      fields: template?.fields,
      design: template?.design,
    });
    // Navigate to the onboarding page
    navigate(`/app/${workspaceId}/onboarding/information`);
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className=" h-[calc(100vh)] w-full bg-primary-foreground overflow-auto">
      <div className="max-w-[1200px] mx-auto p-4 md:p-8">
        <img
          src="/logo.svg"
          height={40}
          width={200}
          alt="logo"
          className="mb-6 md:mb-8"
        />

        <h1 className="text-primary font-primary text-2xl md:text-[32px] font-semibold leading-tight md:leading-[36px] mb-6 md:mb-8">
          Choose an Email Signature Template
        </h1>

        {/* Category tabs */}
        {!['xs', 'sm'].includes(size) && (
          <Tabs
            defaultValue="All"
            onValueChange={handleCategoryChange}
            value={selectedCategory}
          >
            <TabsList className="flex pb-2 justify-center bg-transparent px-1 rounded-full mb-4 sticky top-0 z-10 backdrop-blur-sm overflow-hidden ">
              {CATEGORY_MODELS.map((category) => (
                <TabsTrigger
                  key={category}
                  value={category}
                  className="rounded-full flex-shrink-0 flex items-center justify-center border-0 bg-transparent data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer mr-4 p-4 min-w-[100px] bg-white-0"
                >
                  {category}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        )}

        <div
          ref={contentRef}
          className="overflow-auto"
          key={`templates-${selectedCategory}`} // Force re-render when category changes
        >
          {!initialLoadComplete ? (
            // Show placeholders during initial load
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
              <div className="space-y-4">
                {Array.from({
                  length: Math.min(6, rightSideData.length || 4),
                }).map((_, index) => (
                  <TemplatePlaceholder key={`right-placeholder-${index}`} />
                ))}
              </div>
              <div className="space-y-4">
                {Array.from({
                  length: Math.min(6, leftSideData.length || 4),
                }).map((_, index) => (
                  <TemplatePlaceholder key={`left-placeholder-${index}`} />
                ))}
              </div>
            </div>
          ) : (
            // Always use 2 columns grid layout, with templates distributed evenly
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-1">
              {/* Left column templates - rendered first on mobile */}
              <div className="space-y-4 order-2 max-sm:order-1">
                {map(leftSideData, (template, index) => (
                  <TemplateCard
                    key={`left-${index}-${selectedCategory}`}
                    template={template}
                    handleTemplateSelect={handleTemplateSelect}
                    allowedTemplates={allowedTemplates}
                    hideBranding={hideBranding}
                  />
                ))}
              </div>

              {/* Right column templates - rendered second on mobile */}
              <div className="space-y-4 order-1 max-sm:order-2">
                {map(rightSideData, (template, index) => (
                  <TemplateCard
                    key={`right-${index}-${selectedCategory}`}
                    template={template}
                    handleTemplateSelect={handleTemplateSelect}
                    allowedTemplates={allowedTemplates}
                    hideBranding={hideBranding}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Add some bottom padding for comfortable scrolling */}
        <div className="h-16 md:h-24" />
      </div>
    </div>
  );
};

const TemplateCard = ({ template, handleTemplateSelect, hideBranding }) => {
  const TemplateComponent = dynamicTemplate({
    templateKey: template?.template?.key,
  });

  return (
    <div className="p-2">
      <div
        className="group relative h-fit headshot-border p-4 bg-white-0 overflow-x-hidden
          hover:inset-0 hover:shadow-template hover:bg-primary-foreground hover:bg-opacity-100
          hover:backdrop-blur-md rounded-[8px] break-inside-avoid transition-all duration-200 cursor-pointer"
        onClick={() => handleTemplateSelect(template)}
      >
        <div className="p-4 max-h-[300px] overflow-hidden">
          {TemplateComponent ? (
            <TemplateComponent
              signature={template}
              showAwsIcons={false}
              hideBranding={hideBranding}
            />
          ) : (
            <div className="h-40 flex items-center justify-center bg-gray-100">
              Template Preview Not Available
            </div>
          )}
        </div>

        {/* Overlay that appears on hover */}
        <div className="hidden group-hover:flex absolute inset-0 items-center justify-center">
          <Button
            className="h-[40px] w-[137px] opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleTemplateSelect(template);
            }}
          >
            Use Template
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectTemplate;
