import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Anna Johns',
  position: 'Customer Relations, General Administrationr',
  department: '',
  companyName: 'Aaron Kaartinez',
  companyAddress: '123 Business Street | Cityville, 56789',
  email: 'Anna.Johns@example.com',
  phone: '(345) 087-1239',
  website: 'https://www.AaronKaartinez.com',
  companyLogo: { url: 'companylogo_TEMPLATE_44' },
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  footer: `<p>"<span style="color: rgb(255, 0, 0);">BEWARE! WIRE FRAUD IS ON THE RISE</span>."</p><p><span>Accepting wire instructions by email is dangerous. The sender will never request you to send funds or any sensitive personal information (e.g., credit card numbers, bank account information, routing numbers) via unsecured email. If you receive such a request, you are advised to immediately call the sender.</span></p><p><br></p><p><strong>Links in Warning: </strong></p><p><a href="http://www.google.com" class="http://www.google.com" target="_blank">Consumer Protection Notice</a></p>`,
};
const Template44 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#3d3b3a',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#666666',
            fontSize: 11,
            lineHeight: 1.25,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        icon: {
          type: 'none',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#3d3b3a',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 489,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#4e1a3d',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 44',
      image: null,
      key: 'TEMPLATE_44',
      description: null,
      isActive: true,
      config: null,
      templateId: 44,
    },
    name: '44',
  },
];

export default Template44;
