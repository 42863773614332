import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_4' },
  name: 'Smith Jacobs',
  position: 'Accounts Head',
  companyName: 'S2 Associates',
  companyAddress: '984 Penn Rd. NY 102',
  companyLogo: { url: 'companylogo_TEMPLATE_4' },
  footer:
    'The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof',
  email: 'sj@s2associates.com',
  phone: '(345) 087 - 1239',
  website: 'https://s2associates.com',
  ctaTitle: 'Book a call',
  ctaUrl: 'https://facebook.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues2 = {
  name: 'Davida Wilson',
  position: 'Freelance Graphic Designer',
  email: 'davida.wilson@gmail.com',
  phone: '(345) 087 - 1123',
  website: 'www.davidawillson.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_4_1',
  ctaBannerUrl: 'https://yourwebsite.com',
  linkedin: 'https://www.linkedin.com/',
  dribbble: 'https://www.dribbble.com',
  behance: 'https://www.google.co',
  emailTitle: 'Email.',
  phoneTitle: 'Phone.',
  websiteTitle: 'Website.',
};

const Template4 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#D48E00',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 86,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 18,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#D48E00',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 135,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 92,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#D48E00',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 370,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '79a610ce-39eb-42fa-a647-5d895f912a20',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#D48E00',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '79a610ce-39eb-42fa-a647-5d895f912a20',
      name: 'template 4',
      image: null,
      key: 'TEMPLATE_4',
      description: null,
      isActive: true,
      config: null,
      templateId: 4,
    },
    name: '4',
  },
  {
    id: 'b50f964f-f382-45c9-9721-9dc1a6c7b15f',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#23251A',
      font: 'Courier New, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner',
            width: 500,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#23251A',
            fontSize: 13,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 18,
          },
        },
        socialIcon: {
          type: 'word',
          style: {
            color: '#23251A',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 135,
          },
        },
        ctaBanner: {
          style: {
            width: 500,
          },
        },
        headshot: {
          style: {
            width: 92,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#23251A',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '79a610ce-39eb-42fa-a647-5d895f912a20',
    headshotId: null,
    headshot: null,
    template: {
      id: '79a610ce-39eb-42fa-a647-5d895f912a20',
      name: 'template 4',
      image: null,
      key: 'TEMPLATE_4',
      description: null,
      isActive: true,
      config: null,
      templateId: 4,
    },
    name: '4-1',
  },
];

export default Template4;
