import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_9' },
  name: 'Avery Davis',
  position: 'Studio Shodwe Photo Studio',
  email: 'davis@studioshodwe.com',
  phone: '+1 (555) 789-2345',
  website: 'www.studioshodwe.com',
  ctaTitle: '🗓️ Click here to meet up and discuss your photo album',
  ctaUrl: 'https://yourwebsite.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  tiktok: 'https://www.tiktok.com/#',
  youtube: 'https://www.youtube.com/#',
};

const Template9 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#1E7151',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 320,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#0A241C',
            fontSize: 13,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1E7151',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 320,
          },
        },
        headshot: {
          style: {
            width: 150,
            radius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#1E7151',
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '411d31b4-5947-4b7c-aa77-f6025dc1dcd1',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#52C41A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '411d31b4-5947-4b7c-aa77-f6025dc1dcd1',
      name: 'template 9',
      image: null,
      key: 'TEMPLATE_9',
      description: null,
      isActive: true,
      config: null,
      templateId: 9,
    },
    name: '9',
  },
];

export default Template9;
