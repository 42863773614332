/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import { DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import {
  useGetSignature,
  useShowAwsIcons,
} from 'hooks/signature';
import { get } from 'lodash';
import TeammateSignaturePriview from 'components/TeammateSignaturePriview';

import { useWorkspaceId } from 'hooks/workspace';
import { cloneAndRemoveTypename } from 'common/utils';

const SendSignatureForm = ({ onClose, data }) => {
  useShowAwsIcons(true)
  const [getSignature, { loading: signatureLoading }] = useGetSignature();

  const { workspaceId } = useWorkspaceId();

  const [signature, setSignature] = useState(null);

  const handleGetSignature = async (row) => {
    const res = await getSignature({
      variables: {
        where: { signatureId: data?.signatureId, workspaceId },
      },
    });

    const sig = get(res, 'data.signature');
    setSignature(cloneAndRemoveTypename(sig));
  };

  useEffect(() => {
    if (data?.signatureId) {
      handleGetSignature();
    }
  }, [data?.signatureId]);

  return (
    <DialogContent
      className="rounded-[6px] max-w-[max-content] max-h-[70vh] overflow-auto"
      hideCloseButton
    >
      <div>
        <DialogHeader>
          <DialogTitle className="mb-2">Send Signature via email</DialogTitle>
          <p className="m-0">
            Awesome! your signature is ready to be sent via email.
          </p>
        </DialogHeader>
        <div>
          <TeammateSignaturePriview
            signature={signature}
            showSendSignature
            onClose={onClose}
          />
        </div>
      </div>
    </DialogContent>
  );
};

export default SendSignatureForm;
