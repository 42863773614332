import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_22' },
  name: 'Ehsan Loy',
  position: 'Director, Driveway Media',
  email: 'ehsan.loy@drivewaymedia.com',
  phone: '(345) 087-1239',
  website: 'www.drivewaymedia.com',
  ctaUrl: 'https://facebook.com/#',
  ctaBannerImg: 'ctabanner_TEMPLATE_22',
  ctaBannerUrl: 'https://facebook.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  phoneTitle: 'Phone.',
  emailTitle: 'Email.',
  websiteTitle: 'Website.',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_22_1' },
  name: 'Natalie Lewis',
  position: 'Product Designer | Creatix Labs',
  email: 'natalie@creatixlabs.com',
  phone: '222 335 3632',
  website: 'www.creatix.com',
  ctaTitle: 'View My Portfolio',
  ctaUrl: 'https://facebook.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues3 = {
  name: 'Dr. Richard Roland',
  position: 'Department of Computer Science',
  companyAddress: 'Richmond Staten Island, NY 10306 USA',
  companyLogo: { url: 'companylogo_TEMPLATE_22_2' },
  email: 'richard@rimberiouniversity.edu',
  phone: '(654) 321-9870',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_22_2', // The "New Class" banner from Image 1
  ctaBannerUrl: 'https://yourwebsite.com/#',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_22_3' },
  name: 'ETHAN ROBERTS',
  position: 'General Physician at GRADE CARE',
  companyAddress: '853 Plymouth Road, Slough, SL1 4LP',
  email: 'ethan.roberts@gradecare.com',
  website: 'www.gradecare.com',
  ctaTitle: 'Book an appointment',
  ctaUrl: 'https://www.gradecare.com/appointments',
  facebook: 'https://www.facebook.com',
  linkedin: 'https://www.linkedin.com',
  youtube: 'https://www.youtube.com',
};

const Template22 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#331832',
      font: 'Arial, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner',
            width: 420,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#190C18',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#331832',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 130,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#331832',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 22',
      image: null,
      key: 'TEMPLATE_22',
      description: null,
      isActive: true,
      config: null,
      templateId: 22,
    },
    name: '22',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#5A917A',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#2A453B',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
            color: '#5A917A',
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 20,
            color: '#5A917A',
          },
        },
        headshot: {
          style: {
            width: 130,
            borderRadius: 4,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#5A917A',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: '06067a8d-cf85-4239-a61f-302990776cae',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '06067a8d-cf85-4239-a61f-302990776cae',
      name: 'template 22',
      image: null,
      key: 'TEMPLATE_22',
      description: null,
      isActive: true,
      config: null,
      templateId: 22,
    },
    name: '22-1',
  },
  {
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#350443',
      font: 'Times New Roman, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner',
            width: 419,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#2B2B2B',
            fontSize: 14,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#350443',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 419,
            borderRadius: 4,
          },
        },
        headshot: {
          style: {
            width: 130,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#350443',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '6184ce78-940c-4480-8acb-6d8429ba1908',
    headshotId: null,
    template: {
      id: '6184ce78-940c-4480-8acb-6d8429ba1908',
      name: 'template 22',
      image: null,
      key: 'TEMPLATE_22',
      description: null,
      isActive: true,
      config: null,
      templateId: 22,
    },
    name: '22-2',
  },
  {
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#6C6D96',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 160,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#2C2C3A',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#6C6D96',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#FFFFFF',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '6184ce78-940c-4480-8acb-6d8429ba1908',
    headshotId: null,
    template: {
      id: '6184ce78-940c-4480-8acb-6d8429ba1908',
      name: 'template 22',
      image: null,
      key: 'TEMPLATE_22',
      description: null,
      isActive: true,
      config: null,
      templateId: 22,
    },
    name: '22-3',
  },
];

export default Template22;
