import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Emma Johnson',
  position: 'Customer Relations, Training, General Administrationr',
  email: 'emma.johnson@example.com',
  phone: '+1 123 456 7890',
  companyAddress: '123 Business Street | Cityville, 56789',
  companyLogo: { url: 'companylogo_new_TEMPLATE_35' },
  companyPhone: '+1 987 654 3210',
  companyEmail: 'info@globalbusiness.com',
  website: 'www.globalbusiness.com',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com/comapny/#',
  skype: 'https://www.skype.com/company/#',
  footer: `IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.`,
};

const Template35 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#4E1A3D',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#250C1D',
            fontSize: 12,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'none',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#4E1A3D',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 489,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#4E1A3D',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#9C969B',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 35',
      image: null,
      key: 'TEMPLATE_35',
      description: null,
      isActive: true,
      config: null,
      templateId: 35,
    },
    name: '35',
  },
];

export default Template35;
