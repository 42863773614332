import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Jane Smith',
  headshotUrl: { url: 'headshot_new_TEMPLATE_37' },
  position: 'Luxury Realty Specialist',
  department: 'Sales and Marketing',
  companyName: 'Elite Realty Group',
  companyAddress: '789 Fin, Chicago, IL 60603',
  email: 'jane.smith@eliterealtygroup.com',
  phone: '+1 234 567 8901',
  website: 'https://www.eliterealtygroup.com',
  companyEmail: 'info@eliterealtygroup.com',
  ctaTitle: 'Explore Our Services',
  ctaUrl: 'https://yourwebsite.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  signOff: 'Thank you for choosing Elite Royalty Group.',
};

const Template37 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#EA186E',
      font: 'Times New Roman',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 140,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#560120',
            fontSize: 14,
            lineHeight: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#EA186E',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 489,
          },
        },
        headshot: {
          style: {
            width: 140,
            borderRadius: 0,
          },
        },
        separator: {
          style: {
            color: '#EA186E',
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 310,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 37',
      image: null,
      key: 'TEMPLATE_37',
      description: null,
      isActive: true,
      config: null,
      templateId: 37,
    },
    name: '37',
  },
];

export default Template37;
