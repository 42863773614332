/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { cn } from 'lib/utils';
import {
  EllipsisHorizontalIcon,
  GoogleWorkspaceIcon,
  MicrosoftLogo,
  MicrosoftoutlookIcon,
} from 'assets/svg';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import GoogleWorkspaceIntegration from './components/GoogleWorkspaceIntegration/index';
import MicrosoftIntegration from './components/MicrosoftIntegration/index';
import IntegrationDialog from './components/IntegrationDialog';

const IntegrationCard = ({
  onClick,
  title,
  image,
  isConnected,
  isDisabled,
}) => {
  // Create the card component first
  const CardComponent = (
    <div
      onClick={isDisabled && !isConnected ? null : onClick}
      className={cn(
        `!bg-white-0 relative flex justify-start
        max-w-[350px] min-w-[350px] h-[220px] rounded-[8px] overflow-hidden shadow-lg m-2
        bg-white border-[1px] border-solid border-gray-0`,
        isConnected
          ? 'border-primary shadow-signature'
          : isDisabled
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:border-primary hover:border-solid hover:border-[1px] hover:shadow-signature cursor-pointer',
      )}
    >
      <div className="w-full h-full flex justify-center items-center px-4">
        {isConnected && (
          <div className="absolute top-2 font-bold right-2 text-semantic-success text-xs px-2 py-1 rounded-full ">
            Connected
          </div>
        )}
        {image}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute bottom-0 w-full h-11 box-border flex justify-between items-center px-3 py-2 bg-primary-foreground"
      >
        <div>{title}</div>
      </div>
    </div>
  );

  // If the card is disabled, wrap it with tooltip
  if (isDisabled && !isConnected) {
    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{CardComponent}</TooltipTrigger>
          <TooltipContent
            side="bottom"
            align="center"
            sideOffset={5}
            className="bg-primary-foreground text-white rounded shadow-lg max-w-xs text-center"
          >
            <p>
              This workspace already has an active integration. Create a new
              workspace to connect this integration.
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  // Otherwise just return the card
  return CardComponent;
};

const Integrations = () => {
  const integrationDialogRef = useRef();

  const iconClassName = 'w-full';

  const {
    state: { isGoogleWorkspaceConnected, isMicrosoftConnected },
  } = useWorkspaceContext();

  const isAnyIntegrationConnected =
    isGoogleWorkspaceConnected || isMicrosoftConnected;

  const integrationMap = [
    {
      onClick: () => {
        integrationDialogRef?.current?.type('google-integration');
        integrationDialogRef?.current?.open();
      },
      title: 'Google Workspace',
      image: <GoogleWorkspaceIcon className={cn(iconClassName)} />,
      isConnected: isGoogleWorkspaceConnected,
    },
    {
      onClick: () => {
        integrationDialogRef?.current?.type('microsoft-integration');
        integrationDialogRef?.current?.open();
      },
      title: 'Microsoft App Source',
      image: <MicrosoftLogo className={cn('!w-[70%]', iconClassName)} />,
      isConnected: isMicrosoftConnected,
    },
  ];

  return (
    <>
      <IntegrationDialog ref={integrationDialogRef} />
      <div className="bg-primary-foreground w-full h-[calc(100vh_-_71px)] overflow-hidden">
        <div className="p-[24px]">
          <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
            <div className="flex justify-between px-[24px] py-[18px]">
              <h1 className="m-0">Integrations</h1>
            </div>
            <div className="flex flex-wrap gap-2 p-4 ">
              <div className="flex ">
                {integrationMap.map((item, idx) => (
                  <IntegrationCard
                    key={idx}
                    onClick={item?.onClick}
                    title={item?.title}
                    image={item?.image}
                    isConnected={item?.isConnected}
                    isDisabled={isAnyIntegrationConnected && !item?.isConnected}
                  />
                ))}
              </div>
            </div>
            {/* <div className="flex items-center justify-center">
        <div className="px-[24px]">
          <GoogleWorkspaceIntegration />
        </div>
        <div className="px-[24px]">
          <MicrosoftIntegration />
        </div>
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Integrations;
