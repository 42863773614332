import { getFieldsArray } from 'common/helpers/getFieldsArray';

// export const inputValues1 = {
//   name: 'John Doe',
//   position: 'Dream Homes Realty',
//   companyAddress: '123 Business Street | Cityville, 56789',
//   footer:
//     '<p>"<span style="color: rgb(255, 0, 0);">BEWARE! WIRE FRAUD IS ON THE RISE</span>."</p><p><span>Accepting wire instructions by email is dangerous.The sender will never request you to send funds or any sensitive personal information (e.g., credit card numbers, bank account information, routing numbers) via unsecured email.If you receive such a request, you are advised to immediately call the sender.</span></p><p><br></p><p><strong>Links in Warning: </strong></p><p><a href="http://www.google.com" class="http://www.google.com" target="_blank">Consumer Protection Notice</a></p>',
//   email: 'john.doe@dreamhomesrealty.com',
//   phone: '+1 123 456 7890',
//   ctaBannerUrl: 'https://yourwebsite.com',
//   companyPhone: '+1 987 654 3210 ',
// };
export const inputValues1 = {
  name: 'John Doe',
  position: 'Dream Homes Realty',
  companyAddress: '123 Business Street | Cityville, 56789',
  website: 'www.dreamhomesrealty.com',
  email: 'john.doe@dreamhomesrealty.com',
  phone: '+1 123 456 7890, +1 987 654 3210',
  footer:
    '<p>"<span style="color: rgb(255, 0, 0);">BEWARE! WIRE FRAUD IS ON THE RISE.</span>"</p><p>Accepting wire instructions by email is dangerous. The sender will never request you to send funds or any sensitive personal information (e.g., credit card numbers, bank account information, routing numbers) via unsecured email. If you receive such a request, you are advised to immediately call the sender.</p><br><p><strong>Links in Warning:</strong></p><p><a href="#" target="_blank">Consumer Protection Notice</a></p>',
};

const Template36 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#303616',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 120,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#303616',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#303616',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 489,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#303616',
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 540,
            color: '#666666',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00BC6F',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '4450d9a6-7e8c-45ba-acd6-77bd2cfec6e3',
      name: 'template 36',
      image: null,
      key: 'TEMPLATE_36',
      description: null,
      isActive: true,
      config: null,
      templateId: 36,
    },
    name: '36',
  },
];

export default Template36;
