/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import { useAppContext } from 'AppContext';
import { GoogleWorkspaceIcon } from 'assets/svg';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  useConnectGoogleMarketplaceApp,
  useRemoveAccessToken,
} from 'hooks/google-marketplace';
import { useFetchWorkspaceById, useWorkspaceId } from 'hooks/workspace';
import { Button } from 'components/ui/button';
import GoogleConnectDialog from './GoogleConnectDialog';

const GoogleWorkspaceIntegration = ({ onIntegrationComplete }) => {
  const { state: currentUser } = useAppContext();
  const {
    state: { isGoogleWorkspaceConnected },
    setIsGoogleWorkspaceConnected,
  } = useWorkspaceContext();

  const [fetchWorkspaceById] = useFetchWorkspaceById();
  const [connectGoogleMarketplaceApp, { loading: connecting }] =
    useConnectGoogleMarketplaceApp();
  const { workspaceId } = useWorkspaceId();

  const [workspace, setWorkspace] = useState({});
  const userId = currentUser?.currentUser?.id;

  const [removeAccessToken, { loading: removingToken }] =
    useRemoveAccessToken();
  const dialogRef = useRef(null);

  const fetchCurrentWorkspace = async () => {
    const res = await fetchWorkspaceById({
      variables: {
        where: {
          id: workspaceId,
        },
      },
    });
    if (res) {
      setWorkspace(res?.data?.getWorkspaceById?.data);
    }
  };

  useEffect(() => {
    fetchCurrentWorkspace();
  }, []);

  const handleConnectClick = () => {
    dialogRef.current?.open(); // Open the dialog when the user clicks Connect
  };

  const handleDataOnSubmit = async (values) => {
    const { email } = values;
    if (userId) {
      const res = await connectGoogleMarketplaceApp({
        variables: {
          where: {
            email,
            workspaceId,
          },
        },
      });
      if (res?.data?.connectGoogleMarketplaceApp) {
        setIsGoogleWorkspaceConnected(true);
        fetchCurrentWorkspace();
        dialogRef.current?.close();
        onIntegrationComplete();
      }
    }
  };

  const handleDisconnectClick = async () => {
    if (workspaceId) {
      // Call the mutation to remove the access token
      await removeAccessToken({
        variables: {
          where: {
            workspaceId,
          },
        },
      });
      setIsGoogleWorkspaceConnected(false); // Update state to reflect the disconnection
      fetchCurrentWorkspace();
      onIntegrationComplete();
    }
  };

  const handleInstallClick = () => {
    window.open(
      'https://workspace.google.com/marketplace/app/syncsignature/130630954689',
      '_blank',
    );
  };

  return (
    <>
      <GoogleConnectDialog
        connecting={connecting}
        handleDataOnSubmit={handleDataOnSubmit}
        ref={dialogRef}
      />
      <div className="flex flex-col items-center justify-center bg-primary-foreground">
        <div className="bg-white rounded-lg p-10 shadow-md text-center bg-white-0">
          <div className="max-w-[600px] mx-auto mb-5">
            <div className="flex justify-center mb-6">
              <GoogleWorkspaceIcon className="w-1/2" />
            </div>
            <p className="text-gray-600 mb-4 text-[15px]">
              Centralize and automate signature management across your company
              using Google Workspace. Simply install our Marketplace app and
              connect it here.
            </p>

            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="text-gray-600 font-semibold my-0">Step 1:</p>
              <p className="text-gray-600 text-[15px]">
                Install the SyncSignature Google Workspace Marketplace app. This
                requires Google Workspace admin access. If you are not an admin,
                please contact your workspace administrator to install the app.
              </p>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg">
              <p className="text-gray-600 font-semibold my-0">Step 2:</p>
              <p className="text-gray-600 text-[15px]">
                After the admin installs the app, connect SyncSignature with the
                Google Workspace Marketplace app. You will need to enter your
                Google Workspace admin email during the connection process.
              </p>
            </div>
          </div>
          {isGoogleWorkspaceConnected && workspace?.workspaceDomain && (
            <div>
              <p className="text-[red] text-[15px]">
                Connected Domain: <span>{workspace?.workspaceDomain}</span>
              </p>
            </div>
          )}
          <Button
            className="mr-2"
            onClick={
              isGoogleWorkspaceConnected
                ? handleDisconnectClick
                : handleConnectClick
            }
            disabled={removingToken}
          >
            {removingToken
              ? 'Loading...'
              : isGoogleWorkspaceConnected
                ? 'Disconnect'
                : 'Connect'}
          </Button>
          <Button onClick={handleInstallClick}>Install</Button>
        </div>
      </div>
    </>
  );
};

export default GoogleWorkspaceIntegration;
