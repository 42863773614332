/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UPSERT_SIGNATURE_HTML_MUTATION = gql`
  mutation UpsertSignatureHtml($data: UpsertSignatureHtmlInput!) {
    upsertSignatureHtml(data: $data) {
      id
      userId
      platform
      html
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_SIGNATURE_HTML_MUTATION = gql`
  mutation DeleteSignatureHtml($data: DeleteSignatureHtmlInput!) {
    deleteSignatureHtml(data: $data) {
      success
      deletedCount
    }
  }
`;
