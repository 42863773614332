import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Button } from 'components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { Check, LoaderCircle } from 'lucide-react';
import { Checkbox } from 'components/ui/checkbox';

const FormSchema = z.object({
  items: z.array(z.string()).min(1, 'You have to select at least one item.'),
});

const TeammateListing = ({ teammatesData, handleAddTeammates, loading }) => {
  const {
    state: { workspaces },
  } = useWorkspaceContext();
  const { workspaceId } = useWorkspaceId();
  const currentWorkspace = workspaces.find((w) => w.id === workspaceId);

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: { items: [] },
  });

  const selectedItems = useWatch({
    control: form.control,
    name: 'items',
  });

  const onSubmit = ({ items }) => {
    handleAddTeammates(items);
  };

  const filteredTeammateData = teammatesData;

  const handleSelectAll = (isChecked) => {
    const newSelectedItems = isChecked
      ? filteredTeammateData.map((user) => user.id)
      : [];
    form.setValue('items', newSelectedItems);
  };

  const isAddButtonDisabled = () =>
    selectedItems.length === 0 ||
    selectedItems.length > currentWorkspace?.signatureLimit;

  const isAllSelected =
    filteredTeammateData.length > 0 &&
    selectedItems.length === filteredTeammateData.length;

  const getAddButtonText = () =>
    `Add ${selectedItems.length} ${selectedItems.length === 1 ? 'Teammate' : 'Teammates'}`;

  const remaningSignatures =
    currentWorkspace?.signatureLimit - currentWorkspace?.signatureUsed;
  const getFormMessage = () =>
    selectedItems.length > remaningSignatures
      ? `Cannot add more than ${remaningSignatures} teammates in this workspace, Please increase you signature limits.`
      : '';
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex items-center space-x-2 mb-2 mt-3">
            <Checkbox
              checked={isAllSelected}
              onCheckedChange={handleSelectAll}
            />
            <span>Select all</span>
          </div>
          <div className="max-h-[200px] overflow-auto relative">
            {filteredTeammateData.map(
              ({ workspaceTeammateDetails: teammate, id }) => (
                <FormField
                  key={id}
                  control={form.control}
                  name="items"
                  render={({ field }) => (
                    <FormItem
                      className="flex flex-row items-center space-x-3 space-y-0 rounded-lg hover:bg-gray-100 p-3 cursor-pointer"
                      onClick={() => {
                        // Toggle the selection on row click
                        if (field.value.includes(id)) {
                          field.onChange(
                            field.value.filter((value) => value !== id),
                          );
                        } else {
                          field.onChange([...field.value, id]);
                        }
                      }}
                    >
                      <FormControl>
                        <div className="w-4 h-4 flex justify-center items-center">
                          {field.value.includes(id) && (
                            <Check
                              size={20}
                              enableBackground
                              className="h-4 w-4 shrink-0 rounded-sm border border-primary bg-primary text-white-0"
                            />
                          )}
                        </div>
                      </FormControl>
                      <div className="flex items-center space-x-3">
                        <Avatar className="w-8 h-8">
                          <AvatarImage
                            src={teammate.avatarUrl}
                            alt={teammate.name}
                          />
                          <AvatarFallback>
                            {teammate?.name?.charAt(0) || ''}
                          </AvatarFallback>
                        </Avatar>
                        <div>
                          <FormLabel className="font-medium text-gray-900">
                            {teammate.name}
                          </FormLabel>
                          <div className="text-sm text-gray-500">
                            {teammate.email}
                          </div>
                        </div>
                      </div>
                    </FormItem>
                  )}
                />
              ),
            )}
            <FormMessage />
          </div>

          <Button type="submit" disabled={isAddButtonDisabled()}>
            {getAddButtonText()}
            {loading && (
              <LoaderCircle className="ml-2 w-5 h-5 text-black-70 animate-spin" />
            )}
          </Button>
          <FormMessage>{getFormMessage()}</FormMessage>
        </form>
      </Form>
    </div>
  );
};

export default TeammateListing;
