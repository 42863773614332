import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: null,
  name: 'Priya Sharma',
  position: 'Marketing Manager',
  companyAddress: '56 Corporate Avenue | Mumbai, 400001',
  website: 'https://www.creativemedia.in',
  phone: '+91 98765 43210',
};

const Template47 = [
  {
    id: '8be53886-3c9f-4e2a-8f94-9606f7d1a44f',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#0c4160', // Provided primary brand color
      font: 'Trebuchet MS, Helvetica, sans-serif', // Provided font
      theme: 'LIGHT',
      icons: null, // Set icons to null
      styles: {
        cta: {
          style: {
            type: 'colored', // Provided CTA style
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000', // Provided font style
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 20, // Provided header font size
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 15,
            color: '#0c4160',
          },
        },
        companyLogo: {
          style: {
            width: 108,
          },
        },
        ctaBanner: {
          style: {
            width: 400, // Provided CTA banner width
          },
        },
        headshot: {
          style: {
            width: 141, // Provided headshot width
          },
        },
        signOff: {
          style: {
            fontSize: 20, // Provided sign off style
          },
        },
        separator: {
          style: {
            color: '#0c4160',
            width: 1, // Provided separator width
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100, // Provided social banner width
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d', // Provided template ID
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 47', // Provided template name
      key: 'TEMPLATE_47', // Provided template key
      description: null,
      isActive: true,
      config: null,
      templateId: 47,
    },
    name: '47',
  },
];

export default Template47;
