/* eslint-disable no-nested-ternary */
import React from 'react';
import WithImageConversion from 'common/helpers/htmlToImage';
import { get } from 'lodash';
import { SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { socialImages } from 'assets/social-images';
import { getFieldData } from 'common/utils';

const CompanyAddressTypeWrapper = ({
  type,
  styles,
  iconDimensions,
  children,
  signature,
  showAwsIcons,
  customWord,
  separator = '.',
}) => {
  const { fontSize } = styles;
  const child = {
    letter: `A${separator}`,
    // word: `Address${separator}`,
    word: `${customWord || ''}`,
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: 'auto',
      maxWidth: '100%',
      fontSize,
    },
    word: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: 'auto',
      maxWidth: '100%',
      fontSize,
    },
    rounded: { borderRadius: '50%', ...iconDimensions },
    square: { borderRadius: '5px', ...iconDimensions },
    none: { display: 'none' },
  };

  const content = ['letter', 'word'].includes(type) ? (
    <span
      id={TEXT_IDS.ADDRESSWORD}
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </span>
  ) : (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </p>
  );
  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="company-address"
        fileName="company-address"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function CompanyAddress({
  design,
  data,
  style = {},
  tdProps = {},
  signature,
  breakAtCommas = false,
  showAwsIcons,
  separator = '.',
  customType,
  breakAtHash = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = customType ?? get(icon, 'type', 'rounded');
  // const size = get(icon, 'style.size', 16);
  const size = fontSize + 4;
  const fields = get(signature, 'fields', []);
  const contentPadding = get(design, 'styles.content.style.padding', 6);

  const textStyle = {
    fontSize,
    lineHeight:
      type === 'letter' || type === 'word' || type === 'none' ? lineHeight : '',
    color,
  };

  const renderAddress = () => {
    if (breakAtCommas) {
      // Split the address by commas and insert <br /> after each part
      return (data?.value || '').split(',').map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {part.trim()}
          {index < data?.value.split(',').length - 1 && <br />}
        </React.Fragment>
      ));
    }
    return data?.value;
  };

  const renderhashAddress = () => {
    if (breakAtHash) {
      // Split the address by commas and insert <br /> after each part
      return (data?.value || '').split('#').map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {part.trim()}
          {index < data?.value.split('#').length - 1 && <br />}
        </React.Fragment>
      ));
    }
    return data?.value;
  };

  const customTitle = getFieldData(fields, 'addressTitle');

  if (!breakAtHash) {
    return (
      <td
        style={{
          textAlign: 'left',
          alignItems: 'center',
          paddingBottom: style?.paddingBottom || contentPadding || '6px',
          paddingTop: style?.paddingTop || '0px',
          paddingRight: style?.paddingRight || '0px',
          paddingLeft: style?.paddingLeft || '0px',

          ...style,
        }}
        valign="middle"
        {...tdProps}
      >
        <span
          style={{
            verticalAlign: 'middle',
            display: 'inline-block',
          }}
        >
          <CompanyAddressTypeWrapper
            signature={signature}
            showAwsIcons={showAwsIcons}
            type={type}
            iconDimensions={{ height: size, width: size }}
            styles={{
              height: type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
              width: type === 'letter' || type === 'word' ? 'auto' : '16px',
              fontSize,
              backgroundColor: design?.primaryBrandColor,
              verticalAlign: 'middle',
            }}
            separator={separator}
            customWord={customTitle?.value}
          >
            <img
              src={socialImages?.['location-circle']}
              style={{
                height: '100%',
                width: '100%',
              }}
              width="100%"
              alt="tik-tok-icon"
            />
          </CompanyAddressTypeWrapper>
        </span>
        {customTitle?.value === undefined && type === 'word' ? null : type !==
          'none' ? (
          <>&nbsp;</>
        ) : null}
        <span
          id={TEXT_IDS.ADDRESS}
          className="item-center justify-center"
          style={{
            ...textStyle,
            textAlign: 'left',
            verticalAlign: 'middle',
            // wordWrap: 'break-word',
            // overflowWrap: 'break-word',
            // whiteSpace: 'normal',
            // maxWidth: '200px',
            // // display: 'inline-block',
            // paddingLeft: type === 'none' ? '0px' : '4px',
          }}
        >
          {renderAddress()}
        </span>
      </td>
    );
  }
  if (breakAtHash) {
    return (
      <td
        style={{
          textAlign: 'left',
          alignItems: 'center',
          paddingBottom: style?.paddingBottom || contentPadding || '6px',
          paddingTop: style?.paddingTop || '0px',
          paddingRight: style?.paddingRight || '0px',
          paddingLeft: style?.paddingLeft || '0px',

          ...style,
        }}
        valign="middle"
        {...tdProps}
      >
        <table cellPadding="0" cellSpacing="0" border="0">
          <tr>
            <td>
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                }}
              >
                <CompanyAddressTypeWrapper
                  signature={signature}
                  showAwsIcons={showAwsIcons}
                  type={type}
                  iconDimensions={{ height: size, width: size }}
                  styles={{
                    height:
                      type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
                    width:
                      type === 'letter' || type === 'word' ? 'auto' : '16px',
                    fontSize,
                    backgroundColor: design?.primaryBrandColor,
                    verticalAlign: 'middle',
                  }}
                  separator={separator}
                  customWord={customTitle?.value}
                >
                  <img
                    src={socialImages?.['location-circle']}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    width="100%"
                    alt="tik-tok-icon"
                  />
                </CompanyAddressTypeWrapper>
              </span>
              {customTitle?.value === undefined &&
              type === 'word' ? null : type !== 'none' ? (
                <>&nbsp;</>
              ) : null}
            </td>
            <td>
              <span
                id={TEXT_IDS.ADDRESS}
                className="item-center justify-center"
                style={{
                  ...textStyle,
                  textAlign: 'left',
                  verticalAlign: 'middle',
                }}
              >
                {renderhashAddress()}
              </span>
            </td>
          </tr>
        </table>
      </td>
    );
  }
}

export default CompanyAddress;
