import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';
import { nullable } from 'zod';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_6' },
  name: 'Dr. Adams p',
  position: 'Child Specialist at Sunshine Hospital',
  email: 'adam.p@sunshine.com',
  phone: '077-0987-5752',
  website: 'https://www.sunshinehospital.com',
  companyAddress: '789 Fin, Chicago, IL 60603',
  ctaTitle: 'BOOK A 1:1 CONSULTATION',
  ctaUrl: 'https://www.link.com',
  facebook: 'https://facebook.com/company/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.twitter.com/company/#',
  youtube: 'https://www.youtube.com/company/#',
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_6_1' },
  name: 'JESSICA MOORE',
  position: 'Interior Designer',
  website: 'https://www.housedesign.com',
  ctaTitle: 'Read My Blog',
  ctaUrl: 'https://google.com/#',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
  instagram: 'https://www.instagram.com/#',
  phone: '202-556-8896',
  email: 'j@housedesign.com',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_6_2' },
  name: 'Susan Carter',
  position: 'Sales Consultant',
  email: 'susan@sunshine.com',
  phone: '345-087-1239',
  website: 'https://www.sunshine.comS',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
  instagram: 'https://www.instagram.com/#',
  ctaTitle: 'Schedule a consultation',
  ctaUrl: 'https://bnb.com/#',
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_6_3' },
  name: 'Jenifer Smith',
  position: 'Graphics Design Student',
  companyName: 'Black Smith Designn School',
  companyAddress: '789 Fin, Chicago, IL 60603',
  email: 'Jennifer.smith@gmail.com',
  phone: '(345) 087 - 1239',
  website: 'www.jenifersmith.com',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.twitter.com/company/#',
  socialBanners: [
    {
      link: 'https://behance.com/#',
      name: 'socialBanners-Behance',
      value: 'https://behance.com/#',
      imgUrl:
        'https://static.dev.sendsig.com/asset/behance-social-banner-icon-v1.png?variant=1',
      platform: 'Behance',
    },
    {
      link: 'https://dribbble.com/#',
      name: 'socialBanners-Dribbble',
      value: 'https://dribbble.com/#',
      imgUrl:
        'https://static.dev.sendsig.com/asset/dribbble-social-banner-icon-v1.png?variant=1',
      platform: 'Dribbble',
    },
  ],
};

export const inputValues5 = {
  name: 'DANYAL FISHER',
  position: 'Commercial Director at Archer',
  email: 'danyal.fisher@archer.com',
  companyPhone: '443-554-6089',
  website: 'https://www.archer.com',
  facebook: 'https://facebook.com/company/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.twitter.com/company/#',
};

const Template6 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#204742',
      font: 'Tahoma, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 180,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#0C2724',
            fontSize: 13,
            lineHeight: 1.5,
          },
        },
        headerFont: {
          style: {
            fontSize: 17,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#204742',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#0C2724',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#A9B1AE',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b9453954-f96e-4918-8a2b-b8776b76577a',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#204742',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b9453954-f96e-4918-8a2b-b8776b76577a',
      name: 'template 6',
      image: null,
      key: 'TEMPLATE_6',
      description: null,
      isActive: true,
      config: null,
      templateId: 6,
    },
    name: '6',
  },
  {
    id: '8de3ad02-adeb-441e-8de6-18f425ae7f12',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#DB5C7A',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 190,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#220A12',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#DB5C7A',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 417,
          },
        },
        headshot: {
          style: {
            width: 103,
            borderRadius: 12,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 2,
            color: '#DB5C7A',
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b9453954-f96e-4918-8a2b-b8776b76577a',
    template: {
      id: 'b9453954-f96e-4918-8a2b-b8776b76577a',
      name: 'template 6',
      key: 'TEMPLATE_6',
      image: null,
      description: null,
      isActive: true,
      config: null,
      templateId: 6,
    },
    name: '6-1',
  },
  {
    id: '049424a3-c251-4dc5-8b9e-f1c0ddda5a70',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#DA9E12',
      font: 'Verdana, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 184,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#372908',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#DA9E12',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 233,
          },
        },
        headshot: {
          style: {
            width: 90,
            borderRadius: 4,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 3,
            color: '#DA9E12',
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b9453954-f96e-4918-8a2b-b8776b76577a',
    template: {
      id: 'b9453954-f96e-4918-8a2b-b8776b76577a',
      name: 'template 6',
      key: 'TEMPLATE_6',
      description: null,
      isActive: true,
      config: null,
      templateId: 6,
    },
    name: '6-2',
  },
  {
    id: 'b202d7fe-061b-4d0d-bf2f-795360b00890',
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#450F03',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 184,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#430D0C',
            fontSize: 13,
            lineHeight: 1.5,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'square',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 18,
            color: '#450F03',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 417,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 10,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#450F03',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b9453954-f96e-4918-8a2b-b8776b76577a',
    template: {
      id: 'b9453954-f96e-4918-8a2b-b8776b76577a',
      name: 'template 6',
      key: 'TEMPLATE_6',
      description: null,
      isActive: true,
      config: null,
      templateId: 6,
    },
    name: '6-3',
  },
  {
    id: '3f01537b-27b5-4f51-9dd5-2ae6a563a38c',
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#2E302C',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 184,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#2E302C',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'square',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#2E302C',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 417,
          },
        },
        headshot: {
          style: {
            width: 91,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#2E302C',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b9453954-f96e-4918-8a2b-b8776b76577a',
    template: {
      id: 'b9453954-f96e-4918-8a2b-b8776b76577a',
      name: 'template 6',
      key: 'TEMPLATE_6',
      description: null,
      isActive: true,
      config: null,
      templateId: 6,
    },
    name: '6-4',
  },
];

export default Template6;
