import React, { Suspense } from 'react';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import TemplatePlaceholder from './TemplatePlaceholder';

/**
 * Renders an individual template item with hover effects and PRO badge
 *
 * @param {Object} props - Component props
 * @param {Object} props.template - Template data
 * @param {Function} props.handleTemplateSelect - Function to handle template selection
 * @param {Array} props.allowedTemplates - List of templates the user has access to
 * @param {Boolean} props.hideBranding - Whether to hide branding
 * @param {Function} props.isActive - Function to check if template is active
 * @param {String} props.index - Index for template key
 * @param {Function} props.dynamicTemplate - Function to load the template component
 */
const TemplateItem = ({
  template,
  handleTemplateSelect,
  allowedTemplates,
  hideBranding,
  isActive,
  index,
  dynamicTemplate,
}) => {
  // Get the template component based on the template key
  const TemplateComponent = dynamicTemplate({
    templateKey: template?.template?.key,
  });

  if (!TemplateComponent) return null;

  return (
    <div
      key={template.id || `template-${index}`}
      className="relative mb-4 break-inside-avoid"
    >
      <div
        className={`headshot-border p-4 flex justify-start cursor-pointer overflow-x-hidden
          hover:inset-0 hover:shadow-template hover:bg-primary-foreground hover:bg-opacity-100
          hover:backdrop-blur-md rounded-[8px] w-auto h-full group relative
          ${isActive(template?.id) ? ' shadow-signature active-headshot' : ''} bg-white-0`}
      >
        {/* Show PRO badge if the template is not in the allowed templates list */}
        {!allowedTemplates?.includes?.(template.template.templateId) && (
          <Badge
            className="absolute right-[10px] top-[15px]"
            color="rgb(127, 86, 217)"
          >
            PRO
          </Badge>
        )}

        {/* Template preview with reduced scale */}
        <div className="w-full transform scale-[0.85] translate-x-[-30px] pointer-events-none">
          <Suspense fallback={<TemplatePlaceholder />}>
            <TemplateComponent
              signature={template}
              hideBranding={hideBranding}
            />
          </Suspense>
        </div>

        {/* Use Template button that appears on hover */}
        <div className="hidden group-hover:flex absolute inset-0 items-center justify-center">
          <Button
            className="h-[40px] w-[137px]"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleTemplateSelect(template);
            }}
          >
            Use Template
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateItem;