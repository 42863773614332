import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: null,
  name: 'Alex Carter',
  position: 'Creative Director',
  companyName: 'Carter Creations',
  companyAddress: '1234 Elm Street, Boulder CO 80302',
  phone: '(345) 087-1239',
  website: 'https://cartercreations.com',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  phoneTitle: 'Phone',
  websiteTitle: 'Website.',
  addressTitle: 'Address.',
};

const Template41 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#A15E6C',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 108,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.42,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#A15E6C',
            size: 16,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 450,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#A15E6C',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: null,
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 41',
      image: null,
      key: 'TEMPLATE_41',
      description: null,
      isActive: true,
      config: null,
      templateId: 41,
    },
    name: '41',
  },
];

export default Template41;
