import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Rachel Stewart',
  position: 'Hotel Manager',
  companyName: 'Grandview Resort',
  companyAddress: '567 Ocean Drive, Miami Beach, FL',
  email: 'rachel.stewart@grandviewhotel.com',
  phone: '(345) 087-1239',
  website: 'www.grandviewhotel.com',
  ctaTitle: 'Reserve a Room',
  ctaUrl: 'https://facebook.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/',
};

export const inputValues2 = {
  name: 'Shawn Rogers',
  position: 'CEO of Regildet',
  companyAddress: '24 Sunny St., New York, USA',
  email: 'shawn@regildet.com',
  phone: '(345) 087-1239',
  website: 'https://www.rogers.com',
  ctaTitle: 'Book your seat on our webinar. Book a ticket',
  ctaUrl: 'https://facebook.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.instagram.com/#',
  phoneTitle: 'Phone',
  websiteTitle: 'Website.',
  addressTitle: 'Address.',
  emailTitle: 'Email.',
};

export const inputValues3 = {
  name: 'Davika Peterson',
  position: 'Sales Executive',
  department: 'Inbound sales',
  companyName: 'Viha sales & marketing',
  companyAddress: '984 Penn Rd. NY 102',
  email: 'davika@viham.com',
  website: 'https://viham.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_17_3',
  ctaBannerUrl: 'https://yourwebsite.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

const Template17 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#1E5E09',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 108,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#092B00',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 18,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1E5E09',
            size: 18,
          },
        },
        separator: {
          style: {
            color: '#1E5E09',
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00246B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 17',
      image: null,
      key: 'TEMPLATE_17',
      description: null,
      isActive: true,
      config: null,
      templateId: 17,
    },
    name: '17',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#db1f48',
      font: 'Times New Roman, Times, serif',
      theme: 'LIGHT',
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 270,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#2d2d2d',
            fontSize: 13,
            lineHeight: 1.42,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        ctaBanner: {
          style: {
            width: 450,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#db1f48',
            width: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 17,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 16,
            color: '#db1f48',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '18e7ed0f-7110-4128-9855-eceec54b4622',
    headshotId: null, // No headshot ID provided
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00246B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '18e7ed0f-7110-4128-9855-eceec54b4622',
      name: 'template 17',
      image: null,
      key: 'TEMPLATE_17',
      description: null,
      isActive: true,
      config: null,
      templateId: 17,
    },
    name: '17-1',
  },
  {
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#67483c',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 108,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 11,
            lineHeight: 1.5,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 112,
            borderRadius: 13,
          },
        },
        ctaBanner: {
          style: {
            width: 304,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#67483c',
            width: 3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 16,
            color: '#67483c',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '18e7ed0f-7110-4128-9855-eceec54b4622',
    template: {
      id: '18e7ed0f-7110-4128-9855-eceec54b4622',
      name: 'template 17',
      image: null,
      key: 'TEMPLATE_17',
      description: null,
      isActive: true,
      config: null,
      templateId: 17,
    },
    name: '17-3',
  },
];

export default Template17;
