import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_33' },
  name: 'Anjali Kumar',
  position: 'Fashion Designer',
  email: 'anjali.kumar@adorafashion.com',
  phone: '(345) 087 - 1239',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  website: 'https://www.adorafashion.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_33',
  ctaUrl: 'https://yourwebsite.com',
  ctaBannerUrl: 'https://facebook.com/company/#',
  facebook: 'https://facebook.com/company/#',
  instagram: 'https://www.instagram.com/company/#',
  tiktok: 'https://www.tiktok.com/company/#',
  etsy: 'https://www.etsy.com/company/#',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_33_1' },
  name: 'RIMA BOSTON',
  department: 'Student, Pursuing PHD',
  email: 'rima.boston@gmail.com',
  phone: '857-787-3377',
  emailTitle: 'Email.',
  phoneTitle: 'Phone.',
  ctaTitle: 'Read My Blogs',
  ctaUrl: 'https://yourwebsite.com/#',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_33_2' },
  name: 'Lily Foster',
  position: 'Beauty & Skincare Expert',
  companyAddress: '853 Plymouth Road, Slough, SL1 4LP.',
  email: 'lily.foster@gmail.com',
  phone: '+1 (555) 319-6078',
  website: 'www.lilyfoster.com',
  tiktok: 'https://www.tiktok.com/#',
  instagram: 'https://www.instagram.com/#',
  facebook: 'https://www.facebook.com/#',
  youtube: 'https://www.youtube.com/#',
  whatsapp: 'https://whatsapp.com/#',
};

const Template33 = [
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a2',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#0E2C29',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#102320',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'square',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#0E2C29',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
            borderRadius: 12,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
            borderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 12,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#0E2C29',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#56B7FF',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#E09100',
      font: 'Tahoma',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 220,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#472101',
            fontSize: 14,
            lineHeight: 1.6,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 20,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 8,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#E09100',
            width: 1,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 16,
            color: '#E09100',
          },
        },
        companyLogo: {
          style: {
            width: 123,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'eed97775-ce17-434b-a161-5e2a64922ae2',
    headshotId: null,
    template: {
      id: 'eed97775-ce17-434b-a161-5e2a64922ae2',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33-1',
  },
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a2',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#2B554E',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 300,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#102320',
            fontSize: 14,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 18,
            color: '#2B554E',
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 10,
          },
        },
        separator: {
          style: {
            color: '#2B554E',
            width: 1,
          },
        },
        signOff: {
          style: {
            fontSize: 13,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#56B7FF',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33-2',
  },
];

export default Template33;
