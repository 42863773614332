/* eslint-disable no-undef */
import { useState, useEffect, useCallback } from 'react';

const useExtensionDetection = (extensionId) => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Function to check if extension is installed
  const checkExtension = useCallback(async () => {
    try {
      // Method 1: Check for web accessible resource
      const response = await fetch(
        `chrome-extension://${extensionId}/installed.json`,
      ).catch(() => ({ ok: false }));

      if (response.ok) {
        setIsInstalled(true);
        setIsLoading(false);
        return true;
      }

      // Method 2: Try messaging the extension (commented out but available if needed)
      // if (window.chrome && window.chrome.runtime) {
      //   try {
      //     window.chrome.runtime.sendMessage(
      //       extensionId,
      //       { action: 'checkInstallation' },
      //       (response) => {
      //         if (response && response.isInstalled) {
      //           setIsInstalled(true);
      //           return true;
      //         } else {
      //           setIsInstalled(false);
      //           return false;
      //         }
      //         setIsLoading(false);
      //       },
      //     );
      //   } catch (e) {
      //     setIsInstalled(false);
      //     setIsLoading(false);
      //     return false;
      //   }
      // } else {
      //   setIsInstalled(false);
      //   setIsLoading(false);
      //   return false;
      // }

      setIsInstalled(false);
      setIsLoading(false);
      return false;
    } catch (error) {
      setIsInstalled(false);
      setIsLoading(false);
      return false;
    }
  }, [extensionId]);

  useEffect(() => {
    // Check extension status only once when component mounts
    checkExtension();

    // Set up message listener
    const handleMessage = (event) => {
      if (event.data && event.data.action === 'extensionInstalled') {
        setIsInstalled(true);
      }
    };

    window.addEventListener('message', handleMessage);

    // Clean up
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [extensionId, checkExtension]);

  return { isInstalled, isLoading, checkExtension };
};

export default useExtensionDetection;
