/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from 'AppContext';
import { useUserPermission, useGetRoleForWorkspace } from 'hooks/user';
import { DEFAULT_DESIGN_STYLE, FONT_FAMILY, FEATURE_KEYS, ROLES } from 'common/constants';
import LoaderComponent from 'components/LoaderComponent';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetSignatureList } from 'hooks/signature';
import TemplateCard from 'modules/Templates/components/Template/components/TemplateCard';
import AlertDialog from 'components/AlertDialog';
import useComponentToHtml from 'helpers/useComponentToHtml';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { useUpsertSignatureHtml } from 'hooks/user-signature-html';
import { toast } from 'hooks/use-toast';


function ChromeExtenstion() {
  const {
    state: { currentUser },
  } = useAppContext();
  const { workspaceId } = useWorkspaceId();
  const [loading, setLoading] = useState(false);
  const [getSignatureListing, states] = useGetSignatureList();
  const { loading: signatureListingLoading } = states;
  const [listingData, setListingData] = useState([]);
  const sigRef = useRef();
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [selectedSignatureId, setSelectedSignatureId] = useState(null);
  const { convertToHtml } = useComponentToHtml();
  const [upsertSignatureHtml] = useUpsertSignatureHtml();
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  useEffect(() => {
    const id = localStorage.getItem("SELECTED_SIGNATURE");
    if (id) {
      setSelectedSignatureId(id);
    }
  }, []);

  const getSignatureListHandler = async (skip = 0) => {
    setLoading(true);
    const res = await getSignatureListing({
      variables: {
        pagination: {
          skip,
          limit: 'max',
        },
        where: {
          workspaceId,
        },
      },
    });

    if (res) {
      const signatures = res?.data?.signatures?.data ?? [];
      setListingData(signatures);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (workspaceId) {
      getSignatureListHandler();
    }
  }, [workspaceId]);

  const handleTemplateSelect = (data) => {
    // console.log(data)
    setSelectedSignature(data);
    sigRef.current.open();
  };

  const sendSignatureToBackend = async (signature) => {
    try {
      const htmls = [];
      const hideBranding =
        isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
        role === ROLES.TEAMMATE ||
        role === ROLES.MANAGER;

      if (!signature || !signature.template?.key) {
        // console.error("Invalid signature or missing template key");
        return false;
      }

      const SignatureComponent = dynamicTemplate({
        templateKey: signature.template.key,
      });

      const html = convertToHtml(SignatureComponent, {
        signature,
        showAwsIcons: true,
        hideBranding,
      });
      htmls.push({ html });

      // Send HTML to backend
      const response = await upsertSignatureHtml({
        variables: {
          data: {
            userId: currentUser.id,
            platform: 'chrome_extension',
            html: htmls,
          },
        },
      });

      return !!(response?.data?.upsertSignatureHtml.html)
    } catch (error) {
      return false;
    }
  };

  const handleAlertOk = async () => {
    setLoading(true);
    const success = await sendSignatureToBackend(selectedSignature);

    if (success) {
      localStorage.setItem("SELECTED_SIGNATURE", selectedSignature.id);
      setSelectedSignatureId(selectedSignature.id);
      toast({
        description: <>Signature successfully connected!</>,
        duration: 3000,
        closeicn: "success",
      });
    } else {
      toast({
        description: <>Failed to connect signature. Please try again.</>,
        duration: 5000,
        closeicn: "destructive",
      });
      return;
    }

    setLoading(false);
    sigRef.current.close();
  };

  const formatListData = (data) => {
    data.forEach((e) => {
      if (!e?.design?.styles) {
        e.design.styles = DEFAULT_DESIGN_STYLE;
      }
      if (!e?.design?.font) {
        e.design.font = FONT_FAMILY;
      }
    });
    return data;
  };

  return (
    <div className="bg-primary-foreground  p-[24px]">
      <div className="relative">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
          {signatureListingLoading || loading ? (
            <LoaderComponent
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <div className="flex flex-wrap gap-2 p-4 ">
              {!!listingData?.length &&
                formatListData(listingData).map((data) => (
                  <div
                    key={data.id}
                    className="relative"
                  >
                    {data.id === selectedSignatureId && (
                      <div className="absolute top-3 right-3 z-10 text-primary-800 text-xs px-2 py-1 rounded-md">
                        Connected
                      </div>
                    )}
                    <TemplateCard
                      key={data.id}
                      count={listingData?.length || 1}
                      data={data}
                      hideAction
                      handleTemplateCardOnClick={() => handleTemplateSelect(data)}
                      templateCardClassname={`${data.id === selectedSignatureId ? "border-primary shadow-signature" : ""}`}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
        <AlertDialog
          ref={sigRef}
          handleOk={handleAlertOk}
          title="Chrome Extension Signature"
          message="Please note that this action will completely replace any existing signature you currently have configured in the Chrome extension. Would you like to proceed?"
        />
      </div>
    </div>
  );
}

export default ChromeExtenstion;