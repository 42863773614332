import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_20' },
  name: 'Steven Harris',
  position: 'Human Resources Manager',
  companyLogo: { url: 'companylogo_TEMPLATE_20' },
  email: 'steven@logicwind.com',
  phone: '(345) 087-1239',
  website: 'https://www.logicwind.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};
export const inputValues2 = {
  headshotUrl: { url: 'headshot_TEMPLATE_20_1' },
  name: 'JANE SMITH',
  position: 'Electrical Engineer',
  companyName: 'Sucuri',
  companyAddress: '24 Sunny St., New York, USA',
  email: 'steven@sucuri.com',
  phone: '(345) 087-1239',
  website: 'https://sucuri.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

const Template20 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#CC313D',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 150,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#CC313D',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 80,
          },
        },
        ctaBanner: {
          style: {
            width: 300,
          },
        },
        headshot: {
          style: {
            width: 78,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#CC313D',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 400,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7f56d9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '100px',
        width: '100px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 20',
      image: null,
      key: 'TEMPLATE_20',
      description: null,
      isActive: true,
      config: null,
      templateId: 20,
    },
    name: '20',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#ff0d63',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 150,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        icon: {
          type: 'square',
          style: {
            size: 16,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 125,
            borderRadius: 14,
          },
        },
        ctaBanner: {
          style: {
            width: 300,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#ff0d63',
            width: 2,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 20,
            color: '#ff0d63',
          },
        },
        companyLogo: {
          style: {
            width: 80,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'a35731bf-e35e-4845-a7b3-901270879426',
    headshotId: null,
    template: {
      id: 'a35731bf-e35e-4845-a7b3-901270879426',
      name: 'template 20',
      image: null,
      key: 'TEMPLATE_20',
      description: null,
      isActive: true,
      config: null,
      templateId: 20,
    },
    name: '20-1',
  },
];

export default Template20;
