import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Karen Williams',
  position: 'Real Estate Agent',
  companyName: 'Salford & Co.',
  email: 'karen.williams@salfordco.com',
  phone: '(345) 087 - 1239',
  website: 'www.salfordco.com',
  facebook: 'https://facebook.com/company/#',
  instagram: 'https://www.instagram.com/company/#',
  ctaBannerImg: 'ctabanner_TEMPLATE_12',
  ctaBannerUrl: 'https://yourwebsite.com',
  headshotUrl: { url: 'headshot_TEMPLATE_14' },
};

const Template12 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#0E2F4F',
      font: 'Arial',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 141,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#0E2F4F',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#0E2F4F',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 499,
            borderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 0,
          },
        },
        separator: {
          style: {
            color: '#0E2F4F',
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 12',
      image: null,
      key: 'TEMPLATE_12',
      description: null,
      isActive: true,
      config: null,
      templateId: 12,
    },
    name: '12',
  },
];

export default Template12;
