import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Aarav Mehta',
  position: 'CEO',
  companyLogo: { url: 'companylogo_TEMPLATE_48' },
  email: 'aarav.mehta@keithston.com',
  phone: '+91-98765-43210',
  ctaUrl: 'https://linkedin.com/#',
  ctaBannerUrl: 'https://linkedin.com/#',
  companyAddress: '56 Corporate Avenue Mumbai 400001',
  phoneTitle: 'direct',
  emailTitle: 'email:',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  youtube: 'https://www.youtube.com/company/#',
  googlereview: 'https://www.google.com/#',
  linktree: 'https://www.google.com/#',
  twitter: 'https://www.twitter.com/#',
};

const Template48 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#000c66',
      font: 'Tahoma,Geneva,Sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000c66',
            fontSize: 13,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'originalrounded',
          style: {
            color: '#000c66',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 120,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#000c66',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 48',
      image: null,
      key: 'TEMPLATE_48',
      description: null,
      isActive: true,
      config: null,
      templateId: 48,
    },
    name: '48',
  },
];

export default Template48;
