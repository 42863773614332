/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_TEAMMATE = gql`
  mutation CreateTeammate(
    $data: CreateTeammateDataInput!
    $where: CreateTeammateWhereInput!
  ) {
    createTeammate(data: $data, where: $where) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        isActive
        lastActiveOn
        earlyAccess
        signatureLimit
        featureAccess
        onboardingStatus
        isCouponUsed
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
        }
        planId
        status
        groupId
        signatureStatus
        informationStatus
        workspaces
        phone
        position
        department
      }
    }
  }
`;
export const UPDATE_TEAMMATE = gql`
  mutation UpdateTeammate(
    $data: UpdateTeammateDataInput!
    $where: UpdateTeammateDataWhereInput!
  ) {
    updateTeammate(data: $data, where: $where) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        isActive
        lastActiveOn
        earlyAccess
        signatureLimit
        featureAccess
        onboardingStatus
        isCouponUsed
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
        }
        planId
        status
        groupId
        signatureStatus
        informationStatus
        workspaces
        phone
        position
        department
      }
    }
  }
`;

export const DELETE_TEAMMATE = gql`
  mutation DeleteTeammate($data: DeleteTeammateInput!) {
    deleteTeammate(data: $data) {
      data
      message
    }
  }
`;

export const ASSIGN_GROUP_TO_TEAMMATES = gql`
  mutation AssignGroupToTeammates($data: AssignGroupToTeammatesInput!) {
    assignGroupToTeammates(data: $data) {
      message
      data {
        id
        workspaceTeammateDetailsId
        workspaceId
        signatureId
        userId
        roleId
        groupId
        brandId
        createdAt
        updatedAt
        deletedAt
        workspaceTeammateDetails
        signature
      }
    }
  }
`;

export const UPDATE_TEAMMATE_SIGNATURE = gql`
  mutation UpdateTeammateSignature(
    $data: CreateSignatureInput!
    $where: TeammateSignatureUniqueWhereInput
  ) {
    updateTeammateSignature(data: $data, where: $where) {
      id
      name
      information {
        personalInformation {
          name
          position
          department
        }
        businessInformation {
          companyName
          companyAddress
          companyLogo
          logoWidth
        }
        contactInformation {
          email
          phone
          website
          websiteShortLink
          emailShortLink
        }
        socialLinks
        shortSocialLinks
        footer
      }
      design {
        primaryBrandColor
        font
        theme
        icons
        styles
      }
      cta {
        title
        url
        shortUrl
      }
      templateId
      headshotId
      headshot {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
        base64Image
        createdAt
        updatedAt
      }
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
      fields
      workspaceId
    }
  }
`;

export const UPSERT_TEAMMATE_SIGNATURE = gql`
  mutation UpsertTeammateSignature(
    $data: CreateSignatureInput!
    $where: TeammateSignatureWhereInput!
  ) {
    upsertTeammateSignature(data: $data, where: $where) {
      id
      name
      design {
        primaryBrandColor
        font
        theme
        icons
        styles
      }
      cta {
        title
        url
        shortUrl
      }
      templateId
      headshotId
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
      fields
      workspaceId
    }
  }
`;

export const SEND_INVITE_TEAMMATE = gql`
  mutation SendInviteTeammate($data: SendInviteTeammateInput!) {
    sendInviteTeammate(data: $data) {
      message
    }
  }
`;

export const CREATE_TEAMMATES_QUEUE = gql`
  mutation CreateTeammatesQueue($data: CreateMultipleTeammatesInput!) {
    createTeammatesQueue(data: $data) {
      data {
        id
        email
        name
        profileImage
        role
        isActive
        lastActiveOn
        earlyAccess
        signatureLimit
        featureAccess
        onboardingStatus
        isCouponUsed
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
        }
        planId
        status
        groupId
        signatureStatus
        informationStatus
        workspaces
        phone
        position
        department
      }
    }
  }
`;

export const ASSIGN_GROUP_TO_TEAMMATES_QUEUE = gql`
  mutation AssignGroupToTeammatesQueue($data: AssignGroupToTeammatesInput!) {
    assignGroupToTeammatesQueue(data: $data) {
      message
    }
  }
`;
