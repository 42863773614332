import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_31' },
  name: 'John Thompson',
  position: 'Real Estate Agent',
  companyName: 'Thompson Realty Group',
  companyAddress: '1234 Elm Street, CO 80202',
  footer:
    'The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof”',
  email: 'jt@thompsonrealty.com',
  phone: '+1 (555) 123-9876',
  website: 'https://www.thompsonrealty.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

const Template34 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#a8071a',
      font: 'Times New Roman, Times, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'rounded',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#a8071a',
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#a8071a',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#a8071a',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b697',
      name: 'template 34',
      image: null,
      key: 'TEMPLATE_34',
      description: null,
      isActive: true,
      config: null,
      templateId: 34,
    },
    name: '34',
  },
];

export default Template34;
