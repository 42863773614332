/* eslint-disable import/prefer-default-export */

import { useLazyQuery, useMutation } from '@apollo/client';
import { fileUpload, resizeImage } from 'common/utils';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  ASSIGN_GROUP_TO_TEAMMATES,
  CREATE_TEAMMATE,
  DELETE_TEAMMATE,
  SEND_INVITE_TEAMMATE,
  UPDATE_TEAMMATE,
  UPDATE_TEAMMATE_SIGNATURE,
  UPSERT_TEAMMATE_SIGNATURE,
  CREATE_TEAMMATES_QUEUE,
  ASSIGN_GROUP_TO_TEAMMATES_QUEUE,
} from 'modules/Teammates/graphql/Mutation';
import {
  GET_PROFILE_IMAGE_SIGNED_URL,
  GET_TEAMMATES,
  GET_TEAMMATES_BY_GROUP_ID,
  GET_TEAMMATE_BY_ID,
} from 'modules/Teammates/graphql/Queries';
import { IMAGE_TYPE } from 'common/constants';
import { toast } from './use-toast';

export const useGetTeammates = () => {
  const {
    state: { teammates },
    setTeammates,
  } = useWorkspaceContext();

  const [getTeammates] = useLazyQuery(GET_TEAMMATES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTeammates(res?.getTeammates?.data ?? teammates);
    },
    onError: (error) => {
      // eslint-disable-next-line no-undef
      // window.location.href = '/app/';
    },
  });

  return [getTeammates];
};

export const useGetTeammateById = () => {
  const [getTeammateById, states] = useLazyQuery(GET_TEAMMATE_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {},
    onError: (error) => {
      // Handle error, e.g., navigate to a not-found page
      // window.location.href = '/app/';
    },
  });

  return [getTeammateById, states];
};
export const useGetTeammatesByGroupId = () => {
  const [getTeammatesByGroupId, states] = useLazyQuery(
    GET_TEAMMATES_BY_GROUP_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {},
      onError: (error) => {
        // Handle error, e.g., navigate to a not-found page
        // window.location.href = '/app/';
      },
    },
  );

  return [getTeammatesByGroupId, states];
};

export const useCreateTeammate = () => {
  const [createTeammate] = useMutation(CREATE_TEAMMATE, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [createTeammate];
};

export const useCreateTeammatesQueue = () => {
  const [createTeammatesQueue] = useMutation(CREATE_TEAMMATES_QUEUE, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [createTeammatesQueue];
};

export const useUpdateTeammate = () => {
  const [updateTeammate, states] = useMutation(UPDATE_TEAMMATE, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [updateTeammate, states];
};
export const useDeleteTeammate = () => {
  const {
    state: { workspaceTeammates },
    setWorkspaceTeammates,
  } = useWorkspaceContext();
  const [deleteTeammate] = useMutation(DELETE_TEAMMATE, {
    onCompleted: (res) => {
      const deletedIds = res.deleteTeammate.data.map((id) => id);
      const updatedTeammates = workspaceTeammates.filter(
        ({ id }) => !deletedIds.includes(id),
      );
      setWorkspaceTeammates(updatedTeammates);
    },
    onError: (error) => {},
  });

  return [deleteTeammate];
};

export const useAssignGroupToTeammates = () => {
  const {
    state: { teammates },
    setTeammates,
  } = useWorkspaceContext();

  const [assignGroupToTeammates, states] = useMutation(
    ASSIGN_GROUP_TO_TEAMMATES,
    {
      onCompleted: (res) => {
        const updatedTeammatesMap = new Map(
          res.assignGroupToTeammates.data.map((updatedTeammate) => [
            updatedTeammate.id,
            updatedTeammate,
          ]),
        );
        const updatedTeammates = teammates.map((teammate) =>
          updatedTeammatesMap.has(teammate.id)
            ? updatedTeammatesMap.get(teammate.id)
            : teammate,
        );

        setTeammates(updatedTeammates);
      },
      onError: (error) => {},
    },
  );
  return [assignGroupToTeammates, states];
};

export const useGetProfileImageUploadSignedUrl = () => {
  const [getProfileImageUploadSignedUrl] = useLazyQuery(
    GET_PROFILE_IMAGE_SIGNED_URL,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  return [getProfileImageUploadSignedUrl];
};

export const useGetUserProfileImageUrl = () => {
  const [getProfileImageUploadSignedUrl] = useGetProfileImageUploadSignedUrl();

  const handleProfileImageUpload = async (files = null) => {
    if (!files || files.length === 0) return;
    let file = files[0];
    const isGif = file.type === IMAGE_TYPE.gif;
    if (!isGif) {
      file = await resizeImage(file, 200);
      if (!file) return;
    }
    const { name } = file || {};
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${filename}.${ext}`;
    try {
      const res = await getProfileImageUploadSignedUrl({
        variables: {
          data: {
            fileName: newFilename,
          },
        },
      });
      if (res) {
        const { signedUrl, key } = res?.data?.getProfileImageUploadSignedUrl;
        await fileUpload(signedUrl, file);
        const url = key && `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
        return url;
      }
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: 'Something went wrong',
      });
    }
  };

  return { handleProfileImageUpload };
};

export const useUpdateTeammateSignature = () => {
  const [updateTeammateSignature, states] = useMutation(
    UPDATE_TEAMMATE_SIGNATURE,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {},
      onError() {},
    },
  );

  return [updateTeammateSignature, states];
};

export const useUpsertTeammateSignature = () => {
  const [upsertTeammateSignature, states] = useMutation(
    UPSERT_TEAMMATE_SIGNATURE,
    {
      fetchPolicy: 'no-cache', // Adjust the fetch policy as needed
      onCompleted: (data) => {
        // console.log('Teammate signature upserted successfully:', data);
      },
      onError: (error) => {
        // console.error('Error upserting teammate signature:', error);
      },
    },
  );

  // Return the mutation function and its states
  return [upsertTeammateSignature, states];
};

export const useSendInviteTeammate = () => {
  const [sendInviteTeammate, states] = useMutation(SEND_INVITE_TEAMMATE, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [sendInviteTeammate, states];
};

export const useAssignGroupToTeammatesQueue = () => {
  const [assignGroupToTeammatesQueue, states] = useMutation(
    ASSIGN_GROUP_TO_TEAMMATES_QUEUE,
    {
      onCompleted: (res) => {
        const { message } = res.assignGroupToTeammatesQueue;
        // eslint-disable-next-line no-console
        console.log(message);
      },
      onError: (error) => {},
    },
  );
  return [assignGroupToTeammatesQueue, states];
};
