/* eslint-disable react/no-array-index-key */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { map } from 'lodash';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import { TEMPLATE_ORDER, useFilteredTemplates } from 'hooks/template';
import { useRouter } from 'hooks/router';
import LoaderComponent from 'components/LoaderComponent';
import { dynamicTemplate } from './utils/dynamicTemplate';
import { useTemplateFiltering } from './hooks/useTemplateFiltering';
import { useTemplateScroll } from './hooks/useTemplateScroll';
import TemplateItem from './components/TemplateItem';
import TemplatePlaceholder from './components/TemplatePlaceholder';
import {
  CATEGORY_MODELS,
  INITIAL_LOAD_DELAY,
  INITIAL_TEMPLATE_COUNT,
} from './utils/constants';
import { createOrganizedCategoryMap } from './utils/categoryUtils';

// Pre-organize category map for faster lookups
const organizedCategoryMap = createOrganizedCategoryMap();

/**
 * TemplatesModel Component
 * Displays a dialog with template options organized by category
 *
 * @param {Object} props - Component props
 * @param {Function} props.handleTemplateSelect - Function to handle template selection
 * @param {Object} props.dialogProps - Dialog component props
 * @param {Object} ref - Forward ref
 */
const TemplatesModel = forwardRef(
  ({ handleTemplateSelect, dialogProps = {} }, ref) => {
    const { navigate } = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [visibleRightCount, setVisibleRightCount] = useState(0);
    const [visibleLeftCount, setVisibleLeftCount] = useState(0);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const dialogContentRef = useRef(null);
    const scrollListenerRef = useRef(null);

    const { allowedTemplates = [], isFeaturesAllowed } = useUserPermission();
    const { templates, loading } = useFilteredTemplates();
    const isActive = useCallback(() => false, []);
    const { role } = useGetRoleForWorkspace();

    // Use custom hook for template filtering
    const { leftSideData, rightSideData } = useTemplateFiltering(
      templates,
      selectedCategory,
      TEMPLATE_ORDER,
      organizedCategoryMap,
    );

    // Calculate initial visible counts
    const initialRightCount = useMemo(
      () => Math.min(INITIAL_TEMPLATE_COUNT, rightSideData.length),
      [rightSideData],
    );

    const initialLeftCount = useMemo(
      () => Math.min(INITIAL_TEMPLATE_COUNT, leftSideData.length),
      [leftSideData],
    );

    // Open dialog and initialize state
    const handleOpenDialog = useCallback(() => {
      setIsOpen(true);
      setInitialLoadComplete(false);

      // Set initial counts
      setVisibleRightCount(initialRightCount);
      setVisibleLeftCount(initialLeftCount);

      // Mark initial load as complete after a delay
      setTimeout(() => {
        setInitialLoadComplete(true);
      }, INITIAL_LOAD_DELAY);
    }, [initialRightCount, initialLeftCount]);

    // Expose methods to parent component via ref
    useImperativeHandle(ref, () => ({
      open: handleOpenDialog,
      close: () => {
        setIsOpen(false);
        navigate('?');
      },
      toggle: () => {
        if (isOpen) {
          ref.current.close();
        } else {
          handleOpenDialog();
        }
      },
    }));

    // Reset visible counts when category changes
    useEffect(() => {
      if (isOpen) {
        setInitialLoadComplete(false);
        setVisibleRightCount(initialRightCount);
        setVisibleLeftCount(initialLeftCount);

        setTimeout(() => {
          setInitialLoadComplete(true);
        }, INITIAL_LOAD_DELAY);
      }
    }, [selectedCategory, isOpen, initialRightCount, initialLeftCount]);

    // Use custom hook for scroll handling
    useTemplateScroll(
      isOpen,
      initialLoadComplete,
      dialogContentRef,
      scrollListenerRef,
      rightSideData.length,
      leftSideData.length,
      setVisibleRightCount,
      setVisibleLeftCount,
    );

    // Calculate hideBranding once
    const hideBranding = useMemo(
      () =>
        isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
        role === ROLES.TEAMMATE ||
        role === ROLES.MANAGER,
      [isFeaturesAllowed, role],
    );

    // Show loading state while templates are loading
    // if (loading || allowedTemplates.length === 0) {
    //   return <LoaderComponent />;
    // }

    // Get visible templates based on current counts
    const visibleRightTemplates = rightSideData.slice(0, visibleRightCount);
    const visibleLeftTemplates = leftSideData.slice(0, visibleLeftCount);

    // Check if there are more templates to load
    const hasMoreTemplates =
      rightSideData.length > visibleRightCount ||
      leftSideData.length > visibleLeftCount;

    return (
      <div>
        {isOpen && (
          <Dialog
            open={isOpen}
            onOpenChange={ref?.current?.close}
            {...dialogProps}
          >
            <DialogContent
              className="max-w-[980px] max-h-[calc(100vh_-_100px)] w-full h-full p-0 rounded-[8px]
              flex flex-col bg-white-0"
              closeclass="top-6"
            >
              <DialogHeader className="w-full h-[80px] rounded-t-[8px] bg-primary-foreground rounded-b-[0px]">
                <DialogTitle className="!flex !justify-start items-center ml-4 h-[80px]">
                  Choose email signature template
                </DialogTitle>
              </DialogHeader>

              <div
                className="space-y-4 overflow-auto p-4"
                ref={dialogContentRef}
              >
                {/* Category tabs */}
                <Tabs
                  defaultValue="All"
                  onValueChange={(value) => setSelectedCategory(value)}
                >
                  <TabsList className="flex justify-center bg-transparent px-1 rounded-full mb-4 sticky top-0 z-10 backdrop-blur-sm">
                    {CATEGORY_MODELS.map((category) => (
                      <TabsTrigger
                        key={category}
                        value={category}
                        className="rounded-full flex items-center justify-center border-0 bg-transparent data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer mr-4 p-4 min-w-[100px]"
                      >
                        {category}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </Tabs>

                {!initialLoadComplete ? (
                  // Show placeholders during initial load
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-4">
                      {Array.from({
                        length: Math.min(6, initialRightCount),
                      }).map((_, index) => (
                        <TemplatePlaceholder
                          key={`right-placeholder-${index}`}
                        />
                      ))}
                    </div>
                    <div className="space-y-4">
                      {Array.from({
                        length: Math.min(6, initialLeftCount),
                      }).map((_, index) => (
                        <TemplatePlaceholder
                          key={`left-placeholder-${index}`}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-4">
                    {/* Right side templates */}
                    <div className="space-y-4">
                      {map(visibleRightTemplates, (template, index) => (
                        <TemplateItem
                          key={`right-${index}`}
                          template={template}
                          handleTemplateSelect={handleTemplateSelect}
                          allowedTemplates={allowedTemplates}
                          hideBranding={hideBranding}
                          isActive={isActive}
                          index={`right-${index}`}
                          dynamicTemplate={dynamicTemplate}
                        />
                      ))}
                    </div>

                    {/* Left side templates */}
                    <div className="space-y-4">
                      {map(visibleLeftTemplates, (template, index) => (
                        <TemplateItem
                          key={`left-${index}`}
                          template={template}
                          handleTemplateSelect={handleTemplateSelect}
                          allowedTemplates={allowedTemplates}
                          hideBranding={hideBranding}
                          isActive={isActive}
                          index={`left-${index}`}
                          dynamicTemplate={dynamicTemplate}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {/* Loading indicator for infinite scroll */}
                {initialLoadComplete && hasMoreTemplates && (
                  <div className="flex justify-center mt-2 mb-4">
                    <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
                  </div>
                )}
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  },
);

export default TemplatesModel;
