import { Button } from 'components/ui/button';
import React, { useRef, useState } from 'react';

import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { useAppContext } from 'AppContext';
import { useWorkspaceTemplateFeatures } from 'hooks/workspace-template';
import { Badge } from 'components/ui/badge';
import { ArrowLeft, Loader2, LoaderCircle } from 'lucide-react';
import TemplatesModel from 'components/TemplatesModel';
import { keyBy, partition } from 'lodash';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES, SOCIAL_LINKS } from 'common/constants';
import { useRouter } from 'hooks/router';
import { useWorkspaceId } from 'hooks/workspace';
import SignatureWarning from 'components/SignatureWarning';
import LinkWarning from 'components/LinkWarning';

const WorkspaceTempatePreview = ({
  isWorkspaceTemplateChanged,
  workspaceTemplate,
  handleSaveWorkspaceTemplate,
  handleSetWorkspaceTemplate,
  queueStatus,
  queueLoading,
}) => {
  const { state: { signatureWidth, signaturecta } , handleSubscriptionModal } = useAppContext();
  const templatesModalRef = useRef();
  const [loading, setLoading] = useState(false);
  const { isUserUsingProFeatures } = useWorkspaceTemplateFeatures({
    workspaceTemplate,
  });
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();
  const { navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();

  const { fields } = workspaceTemplate || {};
  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  const isProFeature = isUserUsingProFeatures();
  const FinalTemplate = dynamicTemplate({
    templateKey: workspaceTemplate?.template?.key,
  });

  const handleTemplateSelect = async (data) => {
    const updatedSignature = { ...workspaceTemplate };
    // Create a new fields array
    const newFields = data.fields;

    const variableFieldsName = [
      'name',
      'position',
      'department',
      'phone',
      'email',
      'companyLogo',
      'headshotUrl',
    ];
    const signatureFields = [...(workspaceTemplate?.fields || [])];
    const [variableFields, nonVariableFields] = partition(newFields, (field) =>
      variableFieldsName.includes(field.name),
    );

    const nonVariableFieldsMap = keyBy(nonVariableFields, 'name');

    // Replace objects in nonVariableFields with those in variableFields if they have the same name
    const updatedFields = signatureFields.map((field) => {
      if (nonVariableFieldsMap[field.name]) {
        return nonVariableFieldsMap[field.name];
      }
      return field;
    });

    const submutData = {
      ...updatedSignature,
      fields: updatedFields,
      cta: data?.cta,
      template: data?.template || updatedSignature.template,
      design:
        {
          ...updatedSignature.design,
          font: data?.design?.font,
          styles: data?.design?.styles,
        } || data?.design,
      templateId: data?.templateId || updatedSignature.templateId,
    };
    // if (isCreate === 'true') {
    //   submutData.headshotUrl = data?.headshotUrl;
    //   submutData.fields = newFields;
    //   submutData.design = data?.design;
    // }
    submutData.fields = signatureFields;
    submutData.design = data?.design;
    // if (isCreate === 'false') {

    // }

    // setSignature(submutData);
    handleSetWorkspaceTemplate(submutData);
    templatesModalRef?.current?.toggle();
  };

  const handleBackClick = async () => {
    navigate(`/app/workspace/${workspaceId}/templates`);
  };

  return (
    <>
      <TemplatesModel
        dialogProps={{ onOpenChange: templatesModalRef?.current?.toggle }}
        ref={templatesModalRef}
        handleTemplateSelect={handleTemplateSelect}
      />
      <div className="flex flex-col h-full w-full overflow-hidden">
        <header className="p-4 flex items-center justify-between">
          <h1 className="flex items-center text-lg font-bold  px-6 m-0">
            <Button
              onClick={handleBackClick}
              variant="text"
              className="px-0 py-0 mr-2"
            >
              <ArrowLeft />
            </Button>
            {workspaceTemplate?.name}
          </h1>
          {queueStatus.delayed > 0 && (
            <div className="flex justify-between px-[24px] py-[18px] items-center">
              <div className="flex items-center gap-4 bg-transparent p-0 border-none">
                {/* Loader Animation */}
                {queueLoading && (
                  <div className="flex items-center gap-2">
                    <LoaderCircle className="w-5 sm:w-6 h-5 sm:h-6 text-blue-500 animate-spin" />
                    <span className="text-xs sm:text-sm text-gray-600 font-medium">
                      Processing Workspace Template Update...
                    </span>
                  </div>
                )}

                {/* Queue Status Info */}
                <div className="flex items-center gap-2 text-xs sm:text-sm font-medium text-gray-700">
                  ⏳ <span>Teammate Template Update Pending:</span>{' '}
                  <b className="text-yellow-600">{queueStatus.delayed}</b>
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-3">
            <div
              className="flex items-center cursor-pointer underline text-[#144CC7] font-primary font-bold"
              onClick={() => {
                templatesModalRef?.current?.open();
              }}
            >
              Change Template
            </div>
            {isProFeature ? (
              <div className={`text-regular-base font-primary  `}>
                You are using{' '}
                <Badge className="bg-primary border-[transparent] px-2 mr-[3px] h-auto  text-white-0 rounded-4">
                  PRO
                </Badge>
                features{'  '}
                {/* <Button
              onClick={handleRevertToFree}
              style={{ padding: 0 }}
              variant="link"
            >
              <u>Revert to free</u>
            </Button> */}
              </div>
            ) : (
              <div />
            )}
            <div className="flex gap-4">
              <Button
                onClick={async () => {
                  if (isUserUsingProFeatures()) {
                    handleSubscriptionModal(true);
                    return;
                  }
                  setLoading(true);
                  await handleSaveWorkspaceTemplate();
                  setLoading(false);
                }}
                disabled={loading || !isWorkspaceTemplateChanged}
              >
                Save Template
                {loading && <Loader2 className="animate-spin ml-2 h-5 w-5" />}
              </Button>
              {/* <Button
              onClick={() => {
                if(installSignatureLoading) return
                if (isUserUsingProFeatures()) {
                  handleSubscriptionModal(true);
                  return;
                }
                handlePublishWorkspaceTemplate();
              }}
            >
              Publish Changes
            </Button> */}
            </div>
          </div>
        </header>
        <div className=" h-full overflow-y-scroll w-full  bg-white-0 rounded-[8px] mt-2 ml-5">
          {signatureWidth > 600 && (
            <div>
              <SignatureWarning width={signatureWidth} />
            </div>
          )}
          {signaturecta > 600 && (
            <div>
              <SignatureWarning width={signaturecta} />
            </div>
          )}
          { socialFields.length > 5 &&(
            <div>
              <LinkWarning length={socialFields.length}/>
            </div>
          )}
          {/* signature-body */}
          <div className="pt-4 px-4">
            {FinalTemplate ? (
              <div>
                <FinalTemplate
                  signature={workspaceTemplate}
                  showAwsIcons={false}
                  hideBranding={hideBranding}
                />
              </div>
            ) : (
              <>
                <Button>Please select signature</Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceTempatePreview;
