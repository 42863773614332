/* eslint-disable no-undef */
/* eslint-disable no-async-promise-executor */
import { PublicClientApplication } from '@azure/msal-browser';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  loginRequest,
  msalConfig,
} from 'modules/Integrations/utils/msalConfig';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_MICROSOFT_USERS,
  GET_MICROSOFT_ADDIN_CONNECTION_STATUS,
  GET_MICROSOFT_CONNECTION_STATUS,
} from 'modules/Integrations/components/MicrosoftIntegration/graphql/Queries';
import {
  generateCodeChallenge,
  generateCodeVerifier,
  storeVerifier,
} from 'lib/utils';
import {
  SET_MICROSOFT_ACCESS_TOKEN,
  SET_MICROSOFT_EMAIL_SIGNATURES,
  REMOVE_MICROSOFT_ACCESS_TOKEN,
} from 'modules/Integrations/components/MicrosoftIntegration/graphql/Mutation';
import React, { useEffect, useCallback } from 'react';

let msalInstance = null;
let initializePromise = null;

const initializeMsal = async () => {
  if (!initializePromise) {
    initializePromise = new Promise(async (resolve, reject) => {
      try {
        msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        // console.log('MSAL instance initialized successfully.');
        resolve(msalInstance);
      } catch (error) {
        // console.error('Error initializing MSAL instance:', error);
        reject(error);
      }
    });
  }
  return initializePromise;
};

export const useMicrosoftConnect = () => {
  const connectMicrosoft = async () => {
    try {
      const instance = await initializeMsal();

      const verifier = generateCodeVerifier();
      const challenge = await generateCodeChallenge(verifier);

      // Store the verifier for later use
      storeVerifier(verifier);

      // Add code_challenge to loginRequest
      const updatedLoginRequest = {
        ...loginRequest,
        codeChallenge: challenge,
        codeChallengeMethod: 'S256',
      };

      await instance.loginRedirect(updatedLoginRequest);
    } catch (error) {
      // console.error('Microsoft login error:', error);
    }
  };

  return { connectMicrosoft };
};

export const useMicrosoftDisconnect = () => {
  const disconnectMicrosoft = async () => {
    try {
      const instance = await initializeMsal();
      await instance.logoutRedirect();
    } catch (error) {
      // console.error('Microsoft logout error:', error);
    }
  };

  return { disconnectMicrosoft };
};

export const handleRedirectPromise = async () => {
  try {
    const instance = await initializeMsal();
    const result = await instance.handleRedirectPromise();
    if (result) {
      // console.log('result', result);
      // console.log('Redirect handled successfully');
      return result.account;
    }
  } catch (error) {
    // console.error('Error handling redirect:', error);
  }
  return null;
};

export const useSetMicrosoftEmailSignatures = () => {
  const [setMicrosoftEmailSignatures, { data, loading, error }] = useMutation(
    SET_MICROSOFT_EMAIL_SIGNATURES,
    {
      onCompleted: (res) => {
        // console.log('Email signatures set successfully:', res);
      },
      onError: (err) => {
        // console.error('Failed to set email signatures:', err);
      },
    },
  );

  return [setMicrosoftEmailSignatures, { data, loading, error }];
};

export const useSetMicrosoftAccessToken = () => {
  const [setMicrosoftAccessToken, { data, loading, error }] = useMutation(
    SET_MICROSOFT_ACCESS_TOKEN,
    {
      onCompleted: (res) => {
        // console.log('Microsoft token exchanged successfully:', res);
      },
      onError: (err) => {
        // console.error('Failed to exchange Microsoft token:', err);
      },
    },
  );

  return [setMicrosoftAccessToken, { data, loading, error }];
};

export const useGetMicrosoftUsers = () => {
  const [getMicrosoftUsers, states] = useLazyQuery(GET_MICROSOFT_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      // console.log('Fetched Microsoft organization users:', res);
    },
    onError: (err) => {
      // console.error('Failed to fetch Microsoft organization users:', err);
    },
  });

  return [getMicrosoftUsers, states];
};

export const useMicrosoftAddinConnectionStatus = (
  userId,
  workspaceId,
  email,
) => {
  const [getMicrosoftAddinConnectionStatus, { data, loading, error }] =
    useLazyQuery(GET_MICROSOFT_ADDIN_CONNECTION_STATUS, {
      fetchPolicy: 'network-only',
      variables: { where: { userId, workspaceId, email } },
      onError: (err) => {
        // console.error(
        //   'Failed to check Microsoft add-in connection status',
        //   err,
        // );
      },
    });

  // Memoized function to prevent re-creating it on every render
  const fetchAddinStatus = useCallback(() => {
    if (userId && workspaceId) {
      getMicrosoftAddinConnectionStatus();
    }
  }, [userId, workspaceId, getMicrosoftAddinConnectionStatus]);

  useEffect(() => {
    fetchAddinStatus();
  }, [fetchAddinStatus]);

  return {
    isConnected: data?.getMicrosoftAddinConnectionStatus?.isConnected || false,
    loading,
    error,
  };
};

export const useMicrosoftConnectionStatus = () => {
  const { setIsMicrosoftConnected } = useWorkspaceContext();
  const [getMicrosoftConnectionStatus, { data, loading, error }] = useLazyQuery(
    GET_MICROSOFT_CONNECTION_STATUS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const isConnected = res?.getMicrosoftConnectionStatus?.isConnected;
        setIsMicrosoftConnected(isConnected);
      },
      onError: (err) => {
        // console.error('Failed to check Microsoft connection status', err);
        setIsMicrosoftConnected(false); // Optionally handle the error state
      },
    },
  );

  return [getMicrosoftConnectionStatus, { data, loading, error }];
};

export const useRemoveMicrsoftAccessToken = () => {
  const [removeMicrosoftAccessToken, { data, loading, error }] = useMutation(
    REMOVE_MICROSOFT_ACCESS_TOKEN,
    {
      onCompleted: (res) => {
        // console.log('Access token removed successfully', res);
      },
      onError: (err) => {
        // console.error('Failed to remove access token', err);
      },
    },
  );

  return [removeMicrosoftAccessToken, { data, loading, error }];
};
