import React from 'react';
import * as Sentry from '@sentry/react';
import MyFallbackComponent from 'components/MyFallbackComponent';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new BrowserTracing()], // Enables performance monitoring
    tracesSampleRate: 1.0,
  });
}

const SentryErrorBoundary = ({ children }) => {
  if (process.env.REACT_APP_ENV === 'production') {
    return (
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
  return children;
};

export default SentryErrorBoundary;
