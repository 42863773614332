/* eslint-disable no-undef */
import { useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from './use-toast';
import useSentryCapture from './sentry';

// IMPORTANT: This must exactly match what's configured in Google Cloud Console
export const redirectUri =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://app.syncsignature.com'
    : 'https://app.dev.syncsignature.com';

const cookies = new Cookies();

// Define scopes
const SCOPES = {
  USER_READ: 'https://www.googleapis.com/auth/admin.directory.user.readonly',
  // GROUP_READ:
  //   'https://www.googleapis.com/auth/admin.directory.group.member.readonly',
  // ORG_READ: 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
};

// Load Google Identity Services API
const loadGoogleLibrary = () =>
  new Promise((resolve) => {
    if (window.google && window.google.accounts) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = () => {
      resolve();
    };
    document.body.appendChild(script);
  });

const useGoogleWorkspaceUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { captureError } = useSentryCapture();

  // Clear token to start fresh
  const clearToken = () => {
    cookies.remove('access_token', { path: '/' });
  };

  // Function to list Google Workspace users
  const listGoogleWorkspaceUsers = async (token, modelRef) => {
    if (!token) {
      toast({
        description:
          'Google API client not initialized. Please authenticate and try again.',
        closeicn: 'destructive',
      });
      return null;
    }

    setLoading(true);

    try {
      // Make the API request
      const response = await axios.get(
        'https://admin.googleapis.com/admin/directory/v1/users',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
          params: {
            customer: 'my_customer',
            maxResults: 500,
            orderBy: 'email',
          },
        },
      );

      const { users } = response.data;

      const usersWithDetails = users.map((user) => ({
        id: user.id,
        name: user?.name?.fullName,
        email: user.primaryEmail,
        position: user?.organizations?.[0]?.title || '',
        department: user?.organizations?.[0]?.department || '',
        phone: user?.phones?.[0]?.value || '',
        profileImage: null,
        aliases: user.aliases || [],
        domainAliases: user.nonEditableAliases || [],
      }));

      setUsers(usersWithDetails);
      modelRef.current.open();
    } catch (error) {
      // console.error('Error fetching users:', error);

      if ([403, 400].includes(error?.response?.status)) {
        toast({
          description:
            'You need to be an admin of the Google Workspace to activate this integration.',
          closeicn: 'destructive',
        });
      } else {
        toast({
          description:
            'Failed to fetch users. Please try authenticating again.',
          closeicn: 'destructive',
        });
      }

      captureError(error, { feature: 'google-workspace-users-list' });
    } finally {
      setLoading(false);
    }
  };

  // Request authorization with incremental support
  const requestGoogleAuthorization = async (scope) => {
    // Handle both string and array inputs for scope
    let scopeString;
    if (Array.isArray(scope)) {
      scopeString = scope.join(' ');
    } else if (typeof scope === 'string') {
      scopeString = scope;
    } else {
      scopeString = '';
    }

    // console.log('Requesting authorization with scope:', scopeString);

    try {
      await loadGoogleLibrary();

      return new Promise((resolve, reject) => {
        if (
          !window.google ||
          !window.google.accounts ||
          !window.google.accounts.oauth2
        ) {
          reject(new Error('Google OAuth library not loaded'));
          return;
        }

        // Simple token client with just include_granted_scopes
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: scopeString, // Use the validated string
          prompt: 'consent',
          callback: (tokenResponse) => {
            if (tokenResponse.error) {
              // console.error('Error obtaining token:', tokenResponse.error);
              reject(tokenResponse.error);
            } else {
              cookies.set('access_token', tokenResponse.access_token, {
                path: '/',
                secure: true,
                sameSite: 'Strict',
                maxAge: 3600,
              });

              resolve(tokenResponse.access_token);
            }
          },
          ux_mode: 'redirect',
          redirect_uri: redirectUri,
        });

        // Request the token without extra parameters
        tokenClient.requestAccessToken();
      });
    } catch (error) {
      // console.error('Error in authorization:', error);
    }
  };

  // Main entry point for authentication
  const handleFetchGoogleWorkspaceUsers = async () => {
    try {
      clearToken();
      return await requestGoogleAuthorization(SCOPES.USER_READ);
    } catch (error) {
      // console.error('Error in Google Workspace auth flow:', error);
      captureError(error, { feature: 'google-workspace-auth' });
      toast({
        description: 'Authentication failed. Please try again.',
        closeicn: 'destructive',
      });
      return null;
    }
  };

  // Request additional scopes incrementally
  const requestAdditionalScopes = async (additionalScope) => {
    try {
      let scopeString;

      // Handle both string and array inputs
      if (Array.isArray(additionalScope)) {
        // Join array elements with a space (OAuth2 standard for multiple scopes)
        scopeString = additionalScope.join(' ');
      } else if (typeof additionalScope === 'string') {
        scopeString = additionalScope;
      } else {
        scopeString = '';
      }

      if (!scopeString) {
        throw new Error('Invalid scope provided');
      }

      // console.log('Processing scopes:', scopeString);

      // Don't clear existing token for incremental auth
      return await requestGoogleAuthorization(scopeString);
    } catch (error) {
      // console.error('Error requesting additional scopes:', error);
      captureError(error, { feature: 'google-workspace-additional-scopes' });
      toast({
        description: 'Failed to request additional permissions.',
        closeicn: 'destructive',
      });
      return null;
    }
  };

  return {
    users,
    loading,
    listGoogleWorkspaceUsers,
    handleFetchGoogleWorkspaceUsers,
    requestAdditionalScopes,
    SCOPES,
  };
};

export default useGoogleWorkspaceUsers;
