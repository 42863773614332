import { CATEGORY_MAP_MODELS } from "./constants";

/**
 * Pre-organizes the category map for faster lookups
 * Creates a flat map of template names to their categories
 *
 * @returns {Object} Organized category map for quick lookups
 */
export const createOrganizedCategoryMap = () => {
  const organizedMap = {};

  Object.entries(CATEGORY_MAP_MODELS).forEach(([templateId, templates]) => {
    Object.entries(templates).forEach(([templateName, categories]) => {
      organizedMap[templateName] = {
        templateId: parseInt(templateId, 10),
        categories,
      };
    });
  });

  return organizedMap;
};

/**
 * Gets template names that belong to a specific category
 *
 * @param {String} category - The category to filter by
 * @param {Array} templateOrder - The order of templates
 * @param {Object} organizedCategoryMap - The organized category map
 * @returns {Array} Template names in the specified category
 */
export const getTemplateNamesByCategory = (category, templateOrder, organizedCategoryMap) => {
  if (category === 'All') {
    return templateOrder;
  }

  return templateOrder.filter(templateName => {
    const info = organizedCategoryMap[templateName];
    return info && info.categories.includes(category);
  });
};

/**
 * Splits template names into two balanced groups
 *
 * @param {Array} templateNames - Template names to split
 * @returns {Object} Object with rightTemplateNames and leftTemplateNames
 */
export const splitTemplateNames = (templateNames) => {
  const midpoint = Math.ceil(templateNames.length / 2);

  return {
    rightTemplateNames: templateNames.slice(0, midpoint),
    leftTemplateNames: templateNames.slice(midpoint),
  };
};

/**
 * Creates Sets for faster lookups
 *
 * @param {Array} rightTemplateNames - Template names for right side
 * @param {Array} leftTemplateNames - Template names for left side
 * @returns {Object} Object with Sets for right and left sides
 */
export const createTemplateSets = (rightTemplateNames, leftTemplateNames) => ({
    rightTemplateMap: new Set(rightTemplateNames),
    leftTemplateMap: new Set(leftTemplateNames),
  });