import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_QUEUE_STATUS = gql`
  query GetQueueStatus($queueName: String!) {
    getQueueStatus(queueName: $queueName) {
      queueName
      waiting
      active
      completed
      failed
      delayed
    }
  }
`;
