import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_11', format: 'gif' },
  name: 'Sarah Wong',
  position: 'Digital Marketing Manager',
  email: 'sarah.w@sparkmedia.com',
  phone: '(987) 654-3210',
  website: 'https://www.moonstudio.com',
  ctaBannerImg: null,
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_TEMPLATE_11_1' },
  name: 'Maya Green',
  position: 'Graphic Designer',
  companyName: 'Maya designs',
  email: 'maya@mayadesigns.com',
  phone: '7897899789',
  ctaTitle: 'Design Portfolio',
  ctaUrl: 'https://bnb.com',
  ctaBannerImg: null,
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_TEMPLATE_11_2' },
  name: 'David Lee',
  position: 'Founder',
  companyName: 'Trendy Threads',
  email: 'david@trendythreads.com',
  ctaBannerImg: null,
  socialBanners: [
    {
      imgUrl:
        'https://static.dev.sendsig.com/asset/linkedin-social-banner-icon-v2.png?variant=2',
      link: 'https://www.linkedin.com',
      platform: 'LinkedIn',
    },
  ],
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_TEMPLATE_11_3' },
  name: 'Johana Carter',
  position: 'Real Estate Agent',
  companyName: 'Carter Realty',
  email: 'johana@carterrealty.com',
  phone: '(555) 123-4567',
  ctaTitle: 'Book an appointment',
  ctaUrl: 'http://bnb.com',
  ctaBannerImg: null,
  socialBanners: null,
};

export const inputValues5 = {
  headshotUrl: { url: 'headshot_TEMPLATE_11_4' },
  name: 'Ryan Adams',
  position: 'Content Writer, Freelance',
  email: 'ryan@techinsights.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_11_4',
  ctaBannerUrl: 'https://www.bnb.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  socialBanners: null,
};

const Template11 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#CC8C8C',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        font: {
          style: {
            color: '#171616',
            fontSize: 13,
            lineHeight: 1.39,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 20,
            color: '#CC8C8C',
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        headshot: {
          style: {
            width: 150,
            borderRadius: 4,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#FFFFFF',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 11',
      image: null,
      key: 'TEMPLATE_11',
      description: null,
      isActive: true,
      config: null,
      templateId: 11,
    },
    name: '11',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#4A9C83',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 267,
            borderRadius: 5,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'square',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#4A9C83',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 91,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#4A9C83',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 11',
      image: null,
      key: 'TEMPLATE_11',
      description: null,
      isActive: true,
      config: null,
      templateId: 11,
    },
    name: '11-1',
  },
  {
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#956B70',
      font: 'Tahoma,Geneva,Sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#A2816C',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#956B70',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 78,
            borderRadius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#956B70',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 11',
      image: null,
      key: 'TEMPLATE_11',
      description: null,
      isActive: true,
      config: null,
      templateId: 11,
    },
    name: '11-2',
  },
  {
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#3E5A95',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 294,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#180F40',
            fontSize: 13,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#3E5A95',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 116,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#3E5A95',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 11',
      image: null,
      key: 'TEMPLATE_11',
      description: null,
      isActive: true,
      config: null,
      templateId: 11,
    },
    name: '11-3',
  },
  {
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#5C4EBB',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 294,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#180F40',
            fontSize: 12,
            lineHeight: 1.16,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#5C4EBB',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 293,
          },
        },
        headshot: {
          style: {
            width: 106,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#5C4EBB',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 11',
      image: null,
      key: 'TEMPLATE_11',
      description: null,
      isActive: true,
      config: null,
      templateId: 11,
    },
    name: '11-4',
  },
];

export default Template11;
