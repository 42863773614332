/* eslint-disable import/prefer-default-export */
import { useMutation } from '@apollo/client';
import {
  UPSERT_SIGNATURE_HTML_MUTATION,
  DELETE_SIGNATURE_HTML_MUTATION,
} from 'modules/UserSignatureHtml/graphql/Mutation';

export const useUpsertSignatureHtml = () => {
  const [upsertSignatureHtml] = useMutation(UPSERT_SIGNATURE_HTML_MUTATION, {
    onCompleted: (res) => {
      // You can add any additional callback logic here
    },
    onError: () => {},
  });

  return [upsertSignatureHtml];
};

export const useDeleteSignatureHtml = () => {
  const [deleteSignatureHtml] = useMutation(DELETE_SIGNATURE_HTML_MUTATION, {
    onCompleted: (res) => {
      // You can add any additional callback logic here
    },
    onError: () => {},
  });

  return [deleteSignatureHtml];
};
