import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_7' },
  name: 'John Thompson',
  position: 'Real Estate Agent at Liceria & Co.',
  email: 'john.thompson@liceriarealty.com',
  phone: '812-845-9292',
  website: 'https://www.liceriarealty.com',
  companyAddress: 'Office at Oxford Street, Collingwood VIC 3066',
  ctaBannerImg: 'ctabanner_TEMPLATE_7',
  ctaBannerUrl: 'https://yourwebsite.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  youtube: 'https://www.youtube.com/#',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_TEMPLATE_7_1' },
  name: 'DAVE VYAN',
  position: 'Fontend Developer at Amazon',
  website: 'https://www.amazon.in',
  companyPhone: '+1 (555) 123-9876',
  companyEmail: 'dave.vyan@amazon.in',
  github: 'https://github.com/#',
  instagram: 'https://www.instagram.com/#',
  twitter: 'https://www.twitter.com/#',
  shortLinks: {
    email: 'https://gmail.com/#',
    website: 'https://www.bnb.com/#',
    linkedin: 'https://linkedin.com/#',
    twitter: 'https://twitter.com/#',
    github: 'https://github.com/#',
  },
};

const Template7 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#A8071A',
      font: 'Verdana, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 440,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#4E010A',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
            color: '#A8071A',
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 20,
            color: '#A8071A',
          },
        },
        ctaBanner: {
          style: {
            width: 440,
            borderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 10,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#A8071A',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#4E010A',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#a8071a',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 7',
      image: null,
      key: 'TEMPLATE_7',
      description: null,
      isActive: true,
      config: null,
      templateId: 7,
    },
    name: '7',
  },
  {
    id: '8be53886-3c9f-4e2a-8f94-9606f7d1a44f',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#FF9900',
      font: 'Trebuchet MS, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#3a3a3a',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            color: '#3a3a3a',
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          color: '#ff9900',
          fontSize: 18,
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 18,
            color: '#ff9900',
          },
        },
        companyLogo: {
          style: {
            width: 108,
          },
        },
        ctaBanner: {
          style: {
            width: 360,
          },
        },
        headshot: {
          style: {
            width: 110,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#ff9900',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 7',
      key: 'TEMPLATE_7',
      description: null,
      isActive: true,
      config: null,
      templateId: 7,
    },
    name: '7-1',
  },
];

export default Template7;
