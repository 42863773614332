import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import { ChevronRight, Zap } from 'lucide-react';
import { replace } from 'lodash';
import React, { useState } from 'react';
import { useAppContext } from 'AppContext';
import client from 'apollo';
import { fileUpload, getLiveUrl } from 'common/utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { useRouter } from 'hooks/router';
import {
  GET_STYLES,
  HEAD_SHOT_SIGNED_URL,
} from 'modules/Headshot/graphql/Queries';

import PreparingHeadshot from 'components/PreparingHeadshot';
import Button from 'components/Button';
import SideCoverHeadshot from 'assets/images/side-cover-headshot.png';
import {
  CREATE_HEADSHOT,
  PROCCESS_HEADSHOT_IMAGE,
} from 'modules/Headshot/graphql/Mutations';
import { useGetHeadshot } from 'hooks/headshot';
import Cropper from 'components/Cropper';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { useWindowSize } from 'helpers/useWindowSize';
import PublicHeadshotEditor from './PublicHeadshotEditor';

const PublicHeadShot = () => {
  const {
    state: { loading, signature, headshot },
    setSignature,
    setLoading,
    setHeadshotUpdateStatus,
  } = useAppContext();

  const { navigate } = useRouter();
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [getHeadshot] = useGetHeadshot();
  const { size } = useWindowSize();
  const isMobile = ['xs', 'sm', 'md'].includes(size);

  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      variables: {
        pagination: { skip: 0, limit: 6 },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [createHeadshot] = useMutation(CREATE_HEADSHOT, {
    onCompleted: async (res) => {
      await getHeadshot({
        variables: { where: { id: res?.createHeadshot?.data?.id } },
      });

      setSignature({
        ...signature,
        headshotId: res?.createHeadshot?.data?.id,
      });

      setHeadshotUpdateStatus(true);
      navigate('/try-headshot');
    },
    onError() {
      setLoading(false);
    },
  });

  const [processHeadshot] = useMutation(PROCCESS_HEADSHOT_IMAGE, {
    onCompleted: (res) => {
      setLoading(true);
      if (res?.processHeadshotImage) {
        const outputImage = getLiveUrl(res?.processHeadshotImage?.key);

        createHeadshot({
          variables: {
            data: {
              image: outputImage || res?.processHeadshotImage?.key,
            },
          },
        });
      }
    },
    onError: () => {
      setLoading(false);
    },
  });
  const form = useForm();

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.[0]?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: HEAD_SHOT_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          fileName,
        },
      },
    });
    const { signedUrl = '', key = '' } =
      await res?.data?.getHeadshotUploadSignedUrl;

    try {
      await fileUpload(signedUrl, file?.[0]);
      await processHeadshot({
        variables: {
          where: {
            key,
          },
        },
      });

      // navigate(`/onboarding/try-headshot/${id}`);
    } catch (error) {
      setIsUploadFailed(true);
      // message?.error(error?.message);
      toast({
        closeicn: 'destructive',
        description: error?.message,
      });
    }
  };

  const handleFinish = async (values) => {
    const { uploadPicture } = values;
    setLoading(true);
    setIsUploadFailed(false);
    await handleImageUpload(uploadPicture);
    setLoading(false);
  };

  if (!isUploadFailed && loading) {
    return <PreparingHeadshot />;
  }
  if (headshot?.id) {
    return <PublicHeadshotEditor />;
  }

  const uploadPicture = form.watch('uploadPicture');

  return (
    <div className="flex w-full min-h-screen h-full max-h-screen overflow-auto">
      <div
        className={`flex flex-col justify-center w-full bg-primary-foreground p-4 ${isMobile ? 'h-auto' : ''}`}
      >
        <div
          className={`w-full ${isMobile ? 'max-w-full' : 'max-w-[600px]'} mx-auto`}
        >
          <img
            src="/logo.svg"
            height={40}
            width={isMobile ? 150 : 200}
            alt="logo"
            className="mb-6"
          />
          <h1
            className={`text-primary font-primary font-semibold leading-tight ${
              isMobile ? 'text-2xl mb-6' : 'text-[32px] mt-[60px] mb-[60px]'
            }`}
          >
            Professional headshot
          </h1>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleFinish)}
              initialValues={{ remember: true }}
            >
              <FormField
                control={form.control}
                name="uploadPicture"
                className={isMobile ? 'mb-4' : 'mb-[16px]'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Upload your picture</FormLabel>
                    <FormControl>
                      <>
                        <div className="block text-medium-base font-primary py-4" />
                        {field.value ? (
                          <ImageUploadPreview
                            text="Clear"
                            onChange={() => {
                              form.setValue(field.name, '');
                            }}
                            imageUrl={field.value[0].preview}
                          />
                        ) : (
                          <Cropper
                            onOk={(file) => {
                              field.onChange(file);
                              form.setValue(field.name, [file]);
                            }}
                            customCropSize={{
                              aspect: 1 / 1,
                              circularCrop: false,
                            }}
                            baseDraggerClassname={`${isMobile ? 'h-[80px]' : 'h-[102px]'} bg-[rgba(0,0,0,0.02)]`}
                          />
                        )}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                htmlType="submit"
                className={`w-full mt-2 ${isMobile ? 'h-[36px]' : 'h-[40px]'}`}
                disabled={!uploadPicture || uploadPicture.length === 0}
                loading={!isUploadFailed && loading}
              >
                <div className="flex items-center space-x-1">
                  <span>Next</span>
                  <ChevronRight
                    width={isMobile ? 16 : 20}
                    height={isMobile ? 16 : 20}
                  />
                </div>
              </Button>
            </form>
          </Form>

          {/* Uncomment and adjust if needed
          {isMobile && (
            <div className="w-full flex justify-center mt-4">
              <Button
                onClick={() => navigate(`/auth/signup?r=ai-headshot`)}
                className="flex items-center justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3] text-white"
              >
                Try AI Headshot
                <Zap size={14} className="ml-1.5 fill-current" />
              </Button>
            </div>
          )}
          */}
        </div>
      </div>
      {!isMobile && (
        <div
          className="flex w-1/2 h-full min-h-screen gap-[10px] shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${SideCoverHeadshot})`,
          }}
        />
      )}
    </div>
  );
};

export default PublicHeadShot;
