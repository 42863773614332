import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_16' },
  name: 'Meet Patel',
  position: 'CEO at Harmony Interior',
  email: 'meet.patel@harmonyinterior.com',
  website: 'https://www.harmonyinterior.com',
  companyAddress: '24 Sunny St., New York, USA',
  ctaBannerUrl: 'https://yourwebsite.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_16',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  youtube: 'https://www.youtube.com/',
  pinterest: 'https://www.pinterest.com/',
};

const Template16 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#6D503B',
      font: 'Arial, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'text',
            width: 420,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#2E201A',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#6D503B',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
          },
        },
        headshot: {
          style: {
            width: 120,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#6D503B',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 16',
      image: null,
      key: 'TEMPLATE_16',
      description: null,
      isActive: true,
      config: null,
      templateId: 16,
    },
    name: '16',
  },
];

export default Template16;
