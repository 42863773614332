import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_2' },
  name: 'Alex Martinez',
  position: 'Creating Director at Archer',
  email: 'alex.martinez@archer.com',
  phone: '576-247-6373',
  website: 'www.archer.com',
  linkedin: 'https://linkedin.com/company/#',
  twitter: 'https://www.x.com/#',
  podcast: 'https://www.podcast.com/#',
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_2_1' },
  name: 'CLITON ALLMON',
  position: 'Blog Editor, DIY Housework',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  email: 'cliton.allmon@diyhousework.com',
  phone: '302-498-6619, 212-446-6161',
  website: 'www.diyhousework.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_2_1',
  ctaBannerUrl: 'https://yourwebsite.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://linkedin.com/company/#',
};

export const inputValues3 = {
  headshotUrl: null,
  name: 'Daniel Evans',
  position: 'Senior Accountant',
  companyName: 'Evans Accounting Solutions',
  companyAddress: '2124 Callison Lane Wilmington, DE 19801',
  companyLogo: null,
  footer: null,
  email: 'daniel@evansaccounting.com',
  phone: '(789) 456-1230',
  website: 'https://creativewonders.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  youtube: 'https://youtube.com/company/#',
};

export const inputValues4 = {
  name: 'Emily Parker',
  position: 'Mobile App Developer at TECH DIGITAL',
  companyLogo: { url: 'companylogo_TEMPLATE_2_3' },
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender',
  email: 'emily.parker@techdigital.com',
  website: 'www.www.techdigital.com',
  companyAddress: '564 Lakeland Terrace Southfield, MI 48075',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.x.com/#',
};

export const inputValues5 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_2_4' },
  name: 'Chloe Harris',
  position: 'Personal Yoga Trainer',
  companyName: 'FitLife Coaching Center',
  companyAddress: '1399 Converty Court Gulfport, MS 39503',
  email: 'chloe.harris@fitlife.com',
  phone: '443-554-6089',
  website: 'https://www.yourwebsite.com/#',
  ctaBannerImg: 'ctabanner_TEMPLATE_2_4',
  ctaBannerUrl: 'https://yourwebsite.com/#',
  facebook: 'https://www.facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/#',
  youtube: 'https://www.youtube.com/#',
};

export const inputValues6 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_2_5' },
  name: 'Jake Walker',
  position: 'Luxury Travel Content Creator | Travel With Jake Channel',
  email: 'jake.walker@gmail.com',
  phone: '733 633 4637',
  emailTitle: 'Email.',
  phoneTitle: 'Phone.',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_2_5',
  ctaBannerUrl: 'https://yourwebsite.com',
  tiktok: 'https://www.tiktok.com/#',
  instagram: 'https://www.instagram.com/#',
  facebook: 'https://www.facebook.com/#',
  youtube: 'https://www.youtube.com/#',
  tripadvisor: 'https://www.google.com/#',
};

const Template2 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#A8071A',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 200,
            borderRadius: 2,
          },
        },
        font: {
          style: {
            color: '#4E010A',
            fontSize: 14,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#A8071A',
            size: 19,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 442,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 10,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#A8071A',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 520,
            color: '#BABABA',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FF7A8A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#4F6FE2',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 500,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#1E214D',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        icon: {
          type: 'square',
        },
        socialIcon: {
          type: 'word',
          style: {
            color: '#4F6FE2',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 500,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#4F6FE2',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        headshot: {
          style: {
            width: 140,
            borderRadius: 6,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FF7A8A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2-1',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#CD4B54',
      font: 'Trebuchet MS, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 200,
            borderRadius: 2,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.28,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        icon: {
          type: 'none',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#CD4B54',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 442,
          },
        },
        headshot: {
          style: {
            width: 83,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#CD4B54',
            width: 4,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2-2',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#552EA5',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 200,
            borderRadius: 2,
          },
        },
        font: {
          style: {
            color: '#2A1A4C',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 17,
          },
        },
        icon: {
          type: 'square',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#552EA5',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 442,
          },
        },
        headshot: {
          style: {
            width: 85,
            borderRadius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#552EA5',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 480,
            color: '#A8A8AC',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FF7A8A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2-3',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#22374A', // Dark blue/navy color from Image 4 and 5
      font: 'Verdana, sans-serif', // Font from Image 4
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner', // Using a banner image instead of a button
            width: 400, // Width from Image 7
            borderRadius: 0, // Border radius from Image 7
          },
        },
        font: {
          style: {
            color: '#101A24', // Text color from Image 5
            fontSize: 12, // Font size from Image 5
            lineHeight: 1.4, // Line height from Image 5
          },
        },
        headerFont: {
          style: {
            fontSize: 18, // Name font size from Image 5
          },
        },
        icon: {
          type: 'letter', // Letter icon type selected in Image 4
          style: {
            size: 20, // From Image 4
          },
        },
        socialIcon: {
          type: 'square', // Square social media icon type selected in Image 4
          style: {
            color: '#22374A', // Same as theme color
            size: 20, // From Image 4
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 110, // Profile picture width from Image 2
            borderRadius: 100, // Profile picture radius from Image 2 (100%)
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#22374A', // Same as theme color
            width: 1, // Separator width from Image 5
          },
        },
        footer: {
          style: {
            width: 500, // Footer width from Image 5 and 6
          },
        },
        content: {
          style: {
            width: 600, // Signature width from Image 6
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshot: {
      id: '6e6e1c4d-3b65-449c-aa76-8b79f0abdd94',
      image:
        'https://static.dev.sendsig.com/headshots/process-headshot-1729590200973.png?timestamp=1729590266933',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7f56d9',
        processedImageKey: 'headshots/process-headshot-1729590200973.png',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2-4',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues6),
    design: {
      primaryBrandColor: '#696969',
      font: 'Trebuchet MS',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 480,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#414141',
            fontSize: 13,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'word',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#696969',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 480,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#696969',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'd8cef969-cd60-402c-999b-9f172682abef',
    headshotId: null,
    headshot: {
      id: '2d76c28b-e167-46be-81af-fe0471554919',
      image:
        'https://static.dev.sendsig.com/headshots/process-headshot-1729673221639.png?timestamp=1729673283627',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7f56d9',
        processedImageKey: 'headshots/process-headshot-1729673221639.png',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'd8cef969-cd60-402c-999b-9f172682abef',
      name: 'template 2',
      image: null,
      key: 'TEMPLATE_2',
      description: null,
      isActive: true,
      config: null,
      templateId: 2,
    },
    name: '2-5',
  },
];

export default Template2;
