import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Jennifer Oriley',
  position: 'Sales Manager',
  email: 'jennifer.oriley@salezi.com',
  phone: '(345) 087-1239',
  companyName: 'Salezi & Co.',
  companyPhone: '(345) 087-1210',
  companyEmail: 'help@salezi.com',
  companyAddress: '567 Ocean Drive, Miami Beach, FL',
  website: 'https://www.salezi.com',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com/company/#',
  googlereview: 'https://www.google.com/company/#',
  emailTitle: 'Email.',
  phoneTitle: 'Phone.',
  websiteTitle: 'Website.',
  companyEmailTitle: 'Company Email.',
  companyPhoneTitle: 'Company Phone.',
  addressTitle: 'Address.',
};

const Template38 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#1C31B7',
      font: 'Trebuchet MS',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 108,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#2D2F40',
            fontSize: 13,
            lineHeight: 1.5,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'word',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#1C31B7',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 450,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#1C31B7',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#00246B',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 38',
      image: null,
      key: 'TEMPLATE_38',
      description: null,
      isActive: true,
      config: null,
      templateId: 38,
    },
    name: '38',
  },
];

export default Template38;
