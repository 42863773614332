/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { Route } from 'react-router-dom';
import React from 'react';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { appUrl, FEATURE_KEYS, ROLES } from 'common/constants';
import { useAppContext } from 'AppContext';

const RenderRoute = ({
  children,
  featuresPermission,
  fallbackComponent: Fallback = <div>No access</div>,
}) => {
  const { isFeaturesAllowed } = useUserPermission();
  const userHasPermission = (requiredPermissions) => {
    if (requiredPermissions.length === 0) {
      return true;
    }

    if (isFeaturesAllowed(...requiredPermissions)) {
      return true;
    }

    return false; // Placeholder for actual permission checking logic
  };

  // eslint-disable-next-line no-nested-ternary
  return userHasPermission(featuresPermission) ? children : <Fallback />;
};

const generateRoute = ({
  path,
  component: Component,
  featuresPermission,
  fallbackComponent: FallbackComponent,
}) => (
  <Route
    key={path}
    path={path}
    element={
      <RenderRoute
        featuresPermission={featuresPermission}
        fallbackComponent={FallbackComponent}
      >
        <Component />
      </RenderRoute>
    }
  />
);
const currentAppUrl = window.location.origin;
const checkPlanPermission = (route, planId, role) => {
  if (!planId || !role) {
    return true; // Allow if planId or role is not provided
  }
  // Check if the route has plan-wise permissions and matches the user's planId
  if (route.planWisePermission.includes(planId)) {
    if (route.name === 'WHITELABEL') {
      const isOtherBrandOwner =
        currentAppUrl !== appUrl && role === ROLES.OWNER;
      return isOtherBrandOwner; // WHITELABEL check
    }

    if (route.name === 'BILLING') {
      const showBillingForOnwer = role === ROLES.OWNER;
      return showBillingForOnwer; // Only allow if the role is 1 for BILLING routes
    }

    if (route.name === 'ACCOUNT') {
      const showAccountForOnwer = role === ROLES.OWNER;
      return showAccountForOnwer; // Only allow if the role is 1 for BILLING routes
    }

    return true; // If no special conditions, the route is allowed
  }

  return false; // If the planId does not match, deny access
};

const checkPermission = (routePermission, userRole) =>
  routePermission?.includes(ROLES.ALL) || routePermission?.includes(userRole);

const syncRoute = (route, planId, role, activePlan) => {
  if (route.layout === 'user') {
    if (!planId || !role) return true;

    const hasPlanPermission = checkPlanPermission(route, planId, role);
    const hasRoutePermission = checkPermission(route.permission, role);

    return hasPlanPermission && hasRoutePermission;
  }

  if (route.layout === 'workspace') {
    if (!planId || !role) return true;
    if (route.name === 'ASSETS') {
      return route.permission.includes(role);
    }
    // if (route.name === 'ChromeExtension') {
    //   const isOtherBrandOwner =
    //     currentAppUrl === appUrl && role === ROLES.OWNER;
    //   return isOtherBrandOwner;
    // }

    // if (route.name === 'INTEGRATIONS') {
    //   const featureKeys = activePlan?.features?.map((f) => f.key);
    //   const isFeaturesAllowed = (feature) => {
    //     const isFeaturesInclueded = featureKeys.includes(feature);

    //     return isFeaturesInclueded;
    //   };

    //   if (!isFeaturesAllowed(FEATURE_KEYS.GOOGLE_MARKETPLACE)) return false;
    return route.permission.includes(role);
  }

  return true;
};

export const useGetRoutes = () => {
  const { role = 'ALL' } = useGetRoleForWorkspace();
  const {
    state: { owner },
  } = useAppContext();
  const planId = owner?.planId;
  const activePlan = owner?.activePlan;
  const getRoutesList = (routes) => {
    const result = routes.filter((route) =>
      syncRoute(route, planId, role, activePlan),
    );
    return result;
  };
  const getRoutes = (routes) => {
    const filteredRoutes = routes.filter((route) =>
      syncRoute(route, planId, role, activePlan),
    );
    const result = filteredRoutes.map((route) => generateRoute(route));
    return result;
  };

  return { getRoutes, getRoutesList };
};
