import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_16' },
  name: 'Meet Patel',
  position: 'CEO',
  companyName: 'Expansion Lab',
  email: 'meet@expansionlab.com',
  phone: '(345) 087-1239',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_TEMPLATE_18_1' },
  name: 'David Stephens',
  position: 'Engineering Head',
  email: 'david@datalab.com',
  ctaTitle: 'Hiring Engineers!',
  ctaUrl: 'https://google.com',
  ctaBannerImg: 'ctabanner_TEMPLATE_18_2',
  ctaBannerUrl: 'https://google.com/#',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

const Template18 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#C1000F',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 105,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#C1000F',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 90,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#C1000F',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 18',
      image: null,
      key: 'TEMPLATE_18',
      description: null,
      isActive: true,
      config: null,
      templateId: 18,
    },
    name: '18',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#9c693a',
      font: 'Courier new, courier, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 254,
            borderRadius: 3,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.09,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 16,
          },
        },
        footer: {
          style: {
            color: '#9c693a',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 113,
            borderRadius: 7,
          },
        },
        ctaBanner: {
          style: {
            width: 300,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#9c693a',
            width: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#9c693a',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'bc8e63fb-8018-477d-befb-f0ca39a9c762',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#7F56D9',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'bc8e63fb-8018-477d-befb-f0ca39a9c762',
      name: 'template 18',
      image: null,
      key: 'TEMPLATE_18',
      description: null,
      isActive: true,
      config: null,
      templateId: 18,
    },
    name: '18-1',
  },
];

export default Template18;
