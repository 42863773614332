/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MICROSOFT_USERS = gql`
  query GetMicrosoftUsers($where: GetMicrosoftUsersWhereInput!) {
    getMicrosoftUsers(where: $where) {
      users
    }
  }
`;

export const GET_MICROSOFT_ADDIN_CONNECTION_STATUS = gql`
  query GetMicrosoftAddinConnectionStatus($where: ConnectMicrosoftAddinInput!) {
    getMicrosoftAddinConnectionStatus(where: $where) {
      isConnected
    }
  }
`;

export const GET_MICROSOFT_CONNECTION_STATUS = gql`
  query GetMicrosoftConnectionStatus($where: ConnectMicrosoftConnectionInput!) {
    getMicrosoftConnectionStatus(where: $where) {
      isConnected
    }
  }
`;
