import { gql } from '@apollo/client';

export const CREATE_WORKSPACE_TEAMMATE_DETAILS = gql`
  mutation CreateWorkspaceTeammateDetails(
    $data: CreateWorkspaceTeammateDetailsInput!
  ) {
    createWorkspaceTeammateDetails(data: $data) {
      data {
        id
        name
        position
        email
        department
        phone
        profileImage
        sendInviteFlag
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const DELETE_WORKSPACE_TEAMMATE_DETAILS = gql`
  mutation DeleteWorkspaceTeammateDetails(
    $where: WorkspaceTeammateDetailsWhereInput!
  ) {
    deleteWorkspaceTeammateDetails(where: $where)
  }
`;

export const UPDATE_WORKSPACE_TEAMMATE_DETAILS = gql`
  mutation UpdateWorkspaceTeammateDetails(
    $where: WorkspaceTeammateDetailsWhereInput!
    $data: UpdateWorkspaceTeammateDetailsInput!
  ) {
    updateWorkspaceTeammateDetails(data: $data, where: $where) {
      data {
        id
        name
        position
        email
        department
        phone
        profileImage
        sendInviteFlag
      }
    }
  }
`;

export const UPDATE_WORKSPACE_TEAMMATE_DETAILS_QUEUE = gql`
  mutation updateWorkspaceTeammateDetailsQueue(
    $where: WorkspaceTeammateDetailsWhereInput!
    $data: UpdateWorkspaceTeammateDetailsInput!
  ) {
    updateWorkspaceTeammateDetailsQueue(data: $data, where: $where) {
      data {
        id
        name
        position
        email
        department
        phone
        profileImage
        sendInviteFlag
      }
    }
  }
`;
