/* eslint-disable no-console */
/* eslint-disable no-undef */
import { useAppContext } from 'AppContext';
import { Button } from 'components/ui/button';
import { RefreshCw } from 'lucide-react';
import { useInitializePaddleClient } from 'modules/SubscriptionPage/components/SubscriptionPlans/utils';
import React, { useEffect, useState } from 'react';

const PaddleCheckout = () => {
  const [paddle, setPaddle] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const {
    state: { currentUser },
  } = useAppContext();

  const userId = currentUser?.id;
  // Get the initialize function from the hook
  const { initializePaddleClient } = useInitializePaddleClient();

  // Initialize Paddle using the provided hook
  useEffect(() => {
    initializePaddleClient(setPaddle);
  }, [initializePaddleClient]);

  useEffect(() => {
    const fetchAndValidate = async () => {
      try {
        const txnParam = new URLSearchParams(window.location.search).get(
          '_ptxn',
        );

        if (!txnParam || !/^txn_[a-zA-Z0-9]+$/.test(txnParam)) {
          return;
        }

        setTransactionId(txnParam);
      } catch {
        console.error('Error validating transaction');
      }
    };

    fetchAndValidate();
  }, []);

  const openCheckout = () => {
    paddle.Checkout.open({
      transactionId,
      customData: { userId, planId: 7, tolt_referral: window.tolt_referral },
    });
  };

  useEffect(() => {
    if (paddle && transactionId) {
      try {
        openCheckout();
      } catch (err) {
        console.error('Error opening checkout:', err);
      }
    }
  }, [paddle, transactionId]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-foreground">
      <div className="bg-white-0 shadow-lg rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold text-center mb-4">Processing</h2>
        <p className="text-center text-gray-600 mb-6">
          Please wait while we process your request.
        </p>
        <Button
          onClick={() => window.location.reload()}
          variant="outline"
          className="flex items-center gap-2"
        >
          <RefreshCw className="w-4 h-4" />
          Refresh Page
        </Button>
      </div>
    </div>
  );
};

export default PaddleCheckout;
