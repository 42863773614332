import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_8' },
  name: 'SARAH COLLINS',
  position: 'Senior Financial Consultant',
  companyName: 'Collins Advisory',
  companyAddress: '789 Fin, Chicago, IL 60603',
  email: 'sarah@collinsadvisory.com',
  phone: '+1 (555) 654-7891',
  website: 'https://www.collinsadvisory.com',
  facebook: 'https://www.facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_8_1' },
  name: 'ELIZABETH RODRERG',
  position: 'Job Seeker',
  companyAddress: '789 Fin, Chicago, IL 60603',
  email: 'elizabeth@gmail.com',
  phone: '202-556-8896',
  website: 'https://www.elizabeth.com',
  ctaTitle: "View my CV and let's explore potential collaboration",
  ctaUrl: 'https://www.bnb.com/#',
  facebook: 'https://www.facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.twitter.com/#',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_8_2' },
  name: 'RACHEL SMITH',
  position: 'Interior Designer Freelance',
  email: 'rachel.smith@borcelle.com',
  phone: '(333) 987-6543',
  website: 'www.borcelle.com',
  companyAddress: '1363 Peack Court, CO 80211',
  signOffImgUrl: { url: 'signoff_TEMPLATE_8_2', format: 'gif' },
  pinterest: 'https://www.google.co',
  behance: 'https://www.google.co',
  instagram: 'https://www.instagram.com/',
  linkedin: 'https://www.linkedin.com/',
};

const Template8 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#414141',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 281,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 15,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#414141',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: '100px',
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#414141',
            width: 2,
          },
        },
        footer: {
          style: {
            color: '#7b7b7b',
            width: '390px',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F95402',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
      name: 'template 8',
      image: null,
      key: 'TEMPLATE_8',
      description: null,
      isActive: true,
      config: null,
      templateId: 8,
    },
    name: '8',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#86685B',
      font: 'Verdana, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 341,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#2D2521',
            fontSize: 13,
            lineHeight: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#86685B',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#86685B',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#333537',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
      name: 'template 8',
      image: null,
      key: 'TEMPLATE_8',
      description: null,
      isActive: true,
      config: null,
      templateId: 8,
    },
    name: '8-1',
  },
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#AE873B',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 281,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#3D3D3D',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 19,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#AE873B',
            size: 19,
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 100,
          },
        },
        signOff: {
          type: 'image',
          style: {
            fontSize: 20,
            width: 90,
          },
        },
        separator: {
          style: {
            color: '#AE873B',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
    headshotId: null,
    headshot: {
      id: '1491ff5c-ae7e-4852-9d7a-9ae310149a4a',
      image:
        'https://static.dev.sendsig.com/headshots/headshot-1729926800507.png?timestamp=1729926801531',
      config: {
        output: 'headshots/headshot-1729926804478',
        grayScale: null,
        'background-color': 'linear-gradient(45deg, #FAD961, #F76B1C)',
        processedImageKey: 'headshots/process-headshot-1729926353850.png',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
      name: 'template 8',
      image: null,
      key: 'TEMPLATE_8',
      description: null,
      isActive: true,
      config: null,
      templateId: 8,
    },
    name: '8-2',
  },
];

export default Template8;
