import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Xanthe Robinson',
  position: 'Founder of Flower Shop',
  phone: '(345) 087-1237',
  companyAddress: '853 Plymouth Road, Slough, SL1 4LP',
  website: 'https://www.flowershop.com',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_28',
  ctaBannerUrl: 'https://yourwebsite.com',
  instagram: 'https://www.instagram.com/company/#',
  tiktok: 'https://www.tiktok.com/company/#',
  facebook: 'https://facebook.com/company/#',
  phoneTitle: 'Phone.',
  websiteTitle: 'Website.',
  addressTitle: 'Address.',
};

const Template28 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#32620E',
      font: 'Trebuchet MS',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#143003',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 19,
          },
        },
        icon: {
          type: 'word',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#32620E',
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 420,
            borderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 130,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#32620E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 28',
      image: null,
      key: 'TEMPLATE_28',
      description: null,
      isActive: true,
      config: null,
      templateId: 28,
    },
    name: '28',
  },
];

export default Template28;
