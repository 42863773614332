import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

const inputValues = {
  headshotUrl: { url: 'headshot_TEMPLATE_1' },
  name: 'Olivia Bolton',
  position: 'Marketing Director',
  companyName: 'Brandable',
  email: 'olivia@brandable.com',
  phone: '223 434 322',
  website: 'https://brandable.com',
  instagram: 'https://instagram.com/#',
  facebook: 'https://facebook.com/#',
};

const Template1 = [
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a4',
    fields: getFieldsArray(inputValues),
    design: {
      primaryBrandColor: '#7F56D9',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 115,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 18,
            color: '#7F56D9',
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 250,
            ctaborderRadius: 0,
          },
        },
        headshot: {
          style: {
            width: 90,
            borderRadius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        signOffImgUrl: {
          style: {
            width: 80,
          },
        },
        separator: {
          style: {
            width: 1,
            color: '#7F56D9',
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 500,
            padding: 6,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b7',
    headshotId: null,
    headshot: {},
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b7',
      name: 'template 1',
      image: null,
      key: 'TEMPLATE_1',
      description: null,
      isActive: true,
      config: null,
      templateId: 1,
    },
    name: '1',
  },
];

export default Template1;
