import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_24', format: 'gif' },
  name: 'Jason Hancock',
  position: 'Head of Marketing',
  companyName: 'AV Studios',
  website: 'https://avstudios.arch',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues2 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_24_1' },
  name: 'Marcia Brown',
  position: 'Corporate Lawyer, Neil Karsten',
  companyAddress: '2629 Dogwood Lane Tucson, AZ 85601',
  email: 'marcia@neilkarsten.com',
  phone: '(456) 789-0123',
  website: 'www.neilkarsten.com',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_24_1',
  ctaBannerUrl: 'https://www.brownassociates.com',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.google.com',
};

export const inputValues3 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_24_2' },
  name: 'Kelvin Anderson',
  position: 'Medical Director at Birmingham Clinic',
  companyAddress: '3442 Turnpike Drive Birmingham, AL 35291',
  email: 'kelvin@birminghamclinic.com',
  phone: '(456) 789-0123',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  footer:
    'IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.',
};

export const inputValues4 = {
  headshotUrl: { url: 'headshot_TEMPLATE_24_3' },
  name: 'Emma Brooks',
  position: 'Founder & CEO of InnovateX',
  companyAddress: 'Office at Oxford Street, Collingwood VIC 3066',
  email: 'emma.brooks@innovatex.com',
  phone: '+61 410 309 309',
  website: 'www.innovatex.com',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.twitter.com/company/#',
  ctaTitle: "We're hiring! See open positions →",
  ctaUrl: 'https://www.innovatex.com/careers',
};

export const inputValues5 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_24_4' },
  name: 'Evelyn Parker PHD',
  position: 'University of California, Berkeley',
  email: 'Evelyn.parker@gmail.com',
  phone: '+61 412 170 156',
  website: 'www.universityberkeley.edu',
  linkedin: 'https://www.linkedin.com/',
  twitter: 'https://www.x.com',
};

export const inputValues6 = {
  headshotUrl: { url: 'headshot_new_TEMPLATE_24_5' },
  name: 'Jason Hancock',
  position: 'HR Manager at ZENITH TECHNOLOGIES',
  email: 'jason@zenithtechnologies.com',
  phone: '+61 412 170 156',
  website: 'www.zenithtechnologies.com',
  companyAddress: 'Office at Oxford Street, Collingwood VIC 3066',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.x.com/#',
  ctaTitle: 'Hiring! - Apply in Glassdoor →',
  ctaUrl: 'https://www.yourwebsite.com/#',
};

const Template24 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#000000',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#000000',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 105,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#000000',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24',
  },
  {
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#3C1211',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 430,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#3C1211',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 430,
            ctaborderRadius: 4,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#3C1211',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-1',
  },
  {
    fields: getFieldsArray(inputValues3),
    design: {
      primaryBrandColor: '#45668E',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 212,
            borderRadius: 2,
          },
        },
        font: {
          style: {
            color: '#1D2634',
            fontSize: 12,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 17,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#45668E',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
          },
        },
        headshot: {
          style: {
            width: 151,
            borderRadius: 8,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#45668E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#A7ABB1',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-2',
  },
  {
    fields: getFieldsArray(inputValues4),
    design: {
      primaryBrandColor: '#418B84',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 240,
            borderRadius: 14,
          },
        },
        font: {
          style: {
            color: '#102323',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#418B84',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#418B84',
            width: 3,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-3',
  },
  {
    fields: getFieldsArray(inputValues5),
    design: {
      primaryBrandColor: '#774931',
      font: 'Arial',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#321B16',
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#774931',
            size: 22,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#774931',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        headshot: {
          style: {
            width: 130,
            borderRadius: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-4',
  },
  {
    fields: getFieldsArray(inputValues6),
    design: {
      primaryBrandColor: '#BE719C',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#3C1B2A',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#BE719C',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 120,
            borderRadius: 4,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#BE719C',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-5',
  },
];

export default Template24;
