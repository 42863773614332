import * as Sentry from '@sentry/react';

const useSentryCapture = () => {
  const captureError = (error, tags = {}, extra = {}) => {
    Sentry.captureException(error, {
      tags: { ...tags }, // Add your tags here
      extra: { ...extra }, // Add additional details like response data, status, etc.
    });

    // eslint-disable-next-line no-console
    console.error('Captured error in Sentry:', {
      error,
      tags,
      extra,
    });
  };

  return { captureError };
};

export default useSentryCapture;
