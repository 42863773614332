/* eslint-disable no-restricted-syntax */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useRouter } from 'hooks/router';
import { MagicWandIcon, MegaPhoneIcon, NoteBookIcon } from 'assets/svg';
import { isEqual, omit } from 'lodash';
import {
  useFetchWorkspaceTemplate,
  useUpdateWorkspaceTemplate,
  useUpdateWorkspaceTemplateQueue,
} from 'hooks/workspace-template';
import { useWorkspaceId } from 'hooks/workspace';
import {
  cloneAndRemoveTypename,
  isValidUrl,
  uploadAllImages,
} from 'common/utils';
import LoaderComponent from 'components/LoaderComponent';
import { cn, isValidEmail } from 'lib/utils';
import { useAppContext } from 'AppContext';
import { toast } from 'hooks/use-toast';
import { FIELDS_WITH_ICON, UTAGSFIELDS } from 'common/constants';
import { useParams } from 'react-router-dom';
import { useQueuePolling } from 'hooks/queue';
import WorkspaceTemplateSidebar from './components/WorkspaceTemplateSidebar';
import WorkspaceTemplateInformation from './components/WorkspaceTemplateInformation';
import WorkspaceTempatePreview from './components/WorkspaceTempatePreview';
import WorkspaceTemplateDesign from './components/WorkspaceTemplateDesign';
import WorkspaceTemplateCallToAction from './components/WorkspaceTemplateCallToAction';

const items = [
  // {
  //   key: 'headshot',
  //   icon: UserCircleIcon,
  //   label: 'Headshot',
  //   disabled: false,
  // },
  {
    key: 'information',
    icon: NoteBookIcon,
    label: 'Information',
    disabled: false,
  },
  {
    key: 'design',
    icon: MagicWandIcon,
    label: 'Design',
    disabled: false,
  },
  {
    key: 'cta',
    icon: MegaPhoneIcon,
    label: 'Call to action',
    disabled: false,
  },
];

const TemplateDialog = forwardRef((props, ref) => {
  const {
    navigate,
    location: { pathname, search },
  } = useRouter();
  const {
    state: { baseImages },
  } = useAppContext();

  const { wtid } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [workspaceTemplate, setWorkspaceTemplate] = useState(null);
  const [workspaceTemplateOld, setWorkspaceTemplateOld] = useState(null);

  const { queueStatus, queueLoading, pollQueueStatus } = useQueuePolling(
    'updateWorkspaceTemplateQueue',
  );

  const workspaceTemplateIconCount = workspaceTemplate?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );
  const oldWorkspaceTemplateIconCount = workspaceTemplateOld?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );

  const isIconsChanged = !isEqual(
    workspaceTemplateIconCount,
    oldWorkspaceTemplateIconCount,
  );
  const isWorkspaceTemplateChanged = !isEqual(
    workspaceTemplate,
    workspaceTemplateOld,
  );
  const isDesignStyleChanged =
    !isEqual(workspaceTemplate?.design, workspaceTemplateOld?.design) ||
    isIconsChanged;

  // useImperativeHandle(ref, () => ({
  //   open: () => setIsOpen(true),
  //   close: () => {
  //     setWorkspaceTemplate(null);
  //     setWorkspaceTemplateOld(null);
  //     setIsOpen(false);
  //     navigate('?');
  //   },
  // }));

  const [fetchWorkspaceTemplate] = useFetchWorkspaceTemplate();
  const params = new URLSearchParams(search);
  const templateId = params.get('templateId');
  const workspaceTemplateId = wtid;

  const [activeTab, setActiveTab] = useState('information');

  const [updateWorkspaceTemplate] = useUpdateWorkspaceTemplate();
  const [UpdateWorkspaceTemplateQueue] = useUpdateWorkspaceTemplateQueue();

  const { workspaceId } = useWorkspaceId();

  const [loading, setLoading] = useState(false);

  const handleSetWorkspaceTemplate = (data) => setWorkspaceTemplate(data);

  const fetchCurrentWorkspaceTemplate = async () => {
    setLoading(true);
    const currTemplate = await fetchWorkspaceTemplate({
      variables: {
        where: {
          workspaceTemplateId,
        },
      },
    });
    setWorkspaceTemplate({
      ...cloneAndRemoveTypename(currTemplate?.data?.getWorkspaceTemplate?.data),
    });
    setWorkspaceTemplateOld({
      ...cloneAndRemoveTypename(currTemplate?.data?.getWorkspaceTemplate?.data),
    });
    setLoading(false);
  };

  useEffect(() => {
    if (workspaceTemplateId) {
      fetchCurrentWorkspaceTemplate();
    }
  }, [workspaceTemplateId, templateId]);

  const renderActiveComponent = () => {
    switch (activeTab) {
      case 'design':
        return (
          <WorkspaceTemplateDesign
            workspaceTemplate={workspaceTemplate}
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
          />
        );
      case 'cta':
        return (
          <WorkspaceTemplateCallToAction
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
      case 'information':
      default:
        return (
          <WorkspaceTemplateInformation
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
    }
  };

  // if (!workspaceTemplate) {
  //   return <div>Loading</div>;
  // }
  const handleSaveWorkspaceTemplate = async () => {
    try {
      // Clone the workspace template and remove unnecessary fields
      const formatedWorkspaceTemplate = omit(
        cloneAndRemoveTypename(workspaceTemplate),
        ['id', 'workspaceId'],
      );

      // Upload all images and get the response
      if (isDesignStyleChanged) {
        const response = await uploadAllImages(
          baseImages,
          workspaceTemplate?.id,
        );
        formatedWorkspaceTemplate.design.icons = response;
      }

      const workspaceTemplateFieldsWithValues =
        formatedWorkspaceTemplate?.fields?.map((f) => {
          if (UTAGSFIELDS.includes(f?.name)) {
            if (f.name === 'email' || f.name === 'companyEmail') {
              // if (!isValidEmail(f.value)) {
              //   return { ...f, value: '' };
              // }
              return f;
            }
            if (!isValidUrl(f.value)) {
              return { ...f, value: '' };
            }
          }
          return f;
        });

      // Update the workspace template with the formatted data
      const res = await UpdateWorkspaceTemplateQueue({
        variables: {
          data: {
            ...formatedWorkspaceTemplate,
            fields: workspaceTemplateFieldsWithValues,
          },
          where: {
            templateId: workspaceTemplateId,
            workspaceId,
          },
        },
      });

      // Close the modal or ref after success
      // ref.current.close();

      // Success message (optional)
      pollQueueStatus();
      toast({
        closeicn: 'success',
        description: 'Workspace template saved successfully!',
      });
    } catch (error) {
      // Handle errors and show an error message
      toast({
        closeicn: 'destructive',
        description: `Failed to save the workspace template`,
      });
    }
    // const newWt = await fetchWorkspaceTeammates();
    // return newWt;
  };

  return (
    <div
      className={cn(
        'rounded-[6px] max-h-[calc(100vh_-_70px)] h-full grid grid-cols-[400px_1fr] m-0  border-0 gap-0 overflow-auto',
        { 'grid-cols-[100px_1fr]': loading },
      )}
    >
      {/* {isOpen && (
        <Dialog open={isOpen} onOpenChange={() => ref?.current?.close()}>
          <DialogContent
            className={cn(
              'rounded-[6px] max-w-[1360px] max-h-[calc(100vh_-_100px)] h-full grid grid-cols-[100px_400px_1fr] m-0 p-0 border-0 gap-0',
              { 'grid-cols-[100px_1fr]': loading },
            )}
            hideCloseButton
          > */}

      <>
        {loading ? (
          <LoaderComponent />
        ) : (
          <>
            <div className="overflow-hidden">
              <div className="bg-white-0 overflow-hidden rounded-[inherit]">
                <WorkspaceTemplateSidebar
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  items={items}
                />
              </div>
              <div className=" w-full h-full max-h-[calc(100%_-_70px)] overflow-y-auto bg-primary-foreground">
                {workspaceTemplate && renderActiveComponent()}
              </div>
            </div>
            <div className="bg-[#f5f5f5] rounded-[inherit]">
              {workspaceTemplate && (
                <WorkspaceTempatePreview
                  isWorkspaceTemplateChanged={isWorkspaceTemplateChanged}
                  handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
                  workspaceTemplate={workspaceTemplate}
                  handleSaveWorkspaceTemplate={handleSaveWorkspaceTemplate}
                  queueStatus={queueStatus}
                  queueLoading={queueLoading}
                />
              )}
            </div>
          </>
        )}{' '}
      </>
      {/* </DialogContent>
        </Dialog>
      )} */}
    </div>
  );
});

export default TemplateDialog;
