import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'August Clark',
  position: 'Director at Driveway Media',
  email: 'august.clark@drivewaymedia.com',
  phone: '(345) 087-1239',
  companyAddress: '1702 - The Junomoneta Tower, 395009 (Gujarat) India',
  website: 'https://www.drivewaymedia.com',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com/company/#',
  facebook: 'https://www.facebook.com/company/#',
  podcast: 'https://www.podcast.com/company/#',
};

const Template43 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#A42C3D',
      font: 'Arial',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#A42C3D',
            size: 19,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 130,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#A42C3D',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 43',
      image: null,
      key: 'TEMPLATE_43',
      description: null,
      isActive: true,
      config: null,
      templateId: 43,
    },
    name: '43',
  },
];

export default Template43;
