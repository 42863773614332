/* eslint-disable import/prefer-default-export */
import React, { lazy, Suspense, memo } from 'react';

// Minimal placeholder to use while template is loading
const TemplatePlaceholder = () => (
  <div className="mb-4 max-w-[500px]">
    <div className="headshot-border p-4 rounded-[8px] bg-white-0">
      <div className="animate-pulse flex flex-col space-y-3">
        <div className="h-24 bg-gray-200 rounded-md w-full" />
        <div className="h-3 bg-gray-200 rounded-md w-3/4" />
        <div className="h-3 bg-gray-200 rounded-md w-1/2" />
      </div>
    </div>
  </div>
);

// Template component cache
const loadedTemplates = new Map();

// Object map for template name lookup (much faster than switch statement)
const TEMPLATE_NAME_MAP = {
  1: 'TemplateStyleOne',
  2: 'TemplateStyleTwo',
  3: 'TemplateStyleThree',
  4: 'TemplateStyleFour',
  5: 'TemplateStyleFive',
  6: 'TemplateStyleSix',
  7: 'TemplateStyleSeven',
  8: 'TemplateStyleEight',
  9: 'TemplateStyleNine',
  10: 'TemplateStyleTen',
  11: 'TemplateStyleEleven',
  12: 'TemplateStyleTwelve',
  13: 'TemplateStyleThirteen',
  14: 'TemplateStyleFourteen',
  15: 'TemplateStyleFifthteen',
  16: 'TemplateStyleSixteen',
  17: 'TemplateStyleSeventeen',
  18: 'TemplateStyleEighteen',
  19: 'TemplateStyleNineteen',
  20: 'TemplateStyleTwenty',
  21: 'TemplateStyleTwentyOne',
  22: 'TemplateStyleTwentyTwo',
  23: 'TemplateStyleTwentyThree',
  24: 'TemplateStyleTwentyFour',
  25: 'TemplateStyleTwentyFive',
  26: 'TemplateStyleTwentySix',
  27: 'TemplateStyleTwentySeven',
  28: 'TemplateStyleTwentyEight',
  29: 'TemplateStyleTwentyNine',
  30: 'TemplateStyleThirty',
  31: 'TemplateStyleThirtyOne',
  32: 'TemplateStyleThirtyTwo',
  33: 'TemplateStyleThirtyThree',
  34: 'TemplateStyleThirtyFour',
  35: 'TemplateStyleThirtyFive',
  36: 'TemplateStyleThirtySix',
  37: 'TemplateStyleThirtySeven',
  38: 'TemplateStyleThirtyEight',
  39: 'TemplateStyleThirtyNine',
  40: 'TemplateStyleFourty',
  41: 'TemplateStyleFourtyOne',
  42: 'TemplateStyleFourtyTwo',
  43: 'TemplateStyleFourtyThree',
  44: 'TemplateStyleFourtyFour',
  45: 'TemplateStyleFourtyFive',
  47: 'TemplateStyleFourtySeven',
  48: 'TemplateStyleFourtyEight',
};

// Dynamic import factory with chunk naming
const createLazyComponent = (templateName) =>
  lazy(
    () =>
      import(
        /* webpackChunkName: "template-[request]" */
        `../Templates/${templateName}`
      ),
  );

// Generate the template components map only once
const getTemplateComponent = (templateKey) => {
  // Return from cache if already loaded
  if (loadedTemplates.has(templateKey)) {
    return loadedTemplates.get(templateKey);
  }

  // Parse the key to get the actual component name
  // Example: Convert TEMPLATE_1 to TemplateStyleOne
  const numberMatch = templateKey?.match(/TEMPLATE_(\d+)/);
  if (!numberMatch) return null;

  const templateNumber = parseInt(numberMatch[1], 10);
  const componentName = TEMPLATE_NAME_MAP[templateNumber];

  if (!componentName) return null;

  // Create lazy component
  const LazyComponent = createLazyComponent(componentName);

  // Memoize the component and its wrapper to prevent unnecessary re-renders
  const MemoizedComponent = memo(LazyComponent);

  // Create the wrapped component
  const WrappedComponent = (props) => (
    <Suspense fallback={<TemplatePlaceholder />}>
      <MemoizedComponent {...props} />
    </Suspense>
  );

  // Cache the wrapped component
  loadedTemplates.set(templateKey, WrappedComponent);

  return WrappedComponent;
};

// Function to preload specific templates (can be called early in app initialization)
export const preloadTemplates = (templateKeys = []) => {
  templateKeys.forEach((key) => {
    getTemplateComponent(key);
  });
};

// The main export function
export const dynamicTemplate = ({ templateKey }) =>
  getTemplateComponent(templateKey);
