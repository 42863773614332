/* eslint-disable arrow-body-style */
import React, { useEffect, useRef } from 'react';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useRouter } from 'hooks/router';
import AddActionCard from 'components/AddActionCard';
import { toast } from 'hooks/use-toast';
import TemplateCard from './components/TemplateCard';
import TemplateDialog from './components/TemplateDialog';
import ActionDialog from './components/ActionDialog/ActionDialog';
import CreateTemplateDialog from './components/CreateTemplateDialog';

const Template = () => {
  const {
    state: { workspaceTemplates, groups },
  } = useWorkspaceContext();
  const { navigate, location } = useRouter();

  const actionDialogRef = useRef();
  const createTemplateRef = useRef();
  const templateEditorDialogRef = useRef();

  const params = new URLSearchParams(location.search);
  const workspaceTemplateId = params.get('workspaceTemplateId');

  useEffect(() => {
    if (workspaceTemplateId) {
      templateEditorDialogRef.current.open();
    }
  }, [workspaceTemplateId]);

  const actions = {
    handleEdit: (record) => {
      actionDialogRef.current.onTypeChange('rename');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
    handleDelete: (record) => {
      const findGroupIndex = groups.findIndex(
        (g) => g.templateId === record?.id,
      );
      const isTemplateUsed = findGroupIndex !== -1;

      if (isTemplateUsed) {
        toast({
          closeicn: 'destructive',
          description:
            'Template is already in use. Please remove from relevant groups to delete.',
        });
        return;
      }

      actionDialogRef.current.onTypeChange('delete');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
    handleDuplicate: (record) => {
      actionDialogRef.current.onTypeChange('duplicate');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
    handleAssignToGroup: (record) => {
      actionDialogRef.current.onTypeChange('assign');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
  };

  const handleTemplateCardOnClick = (data) => {
    navigate(`${location.pathname}/${data?.id}?templateId=${data?.templateId}`);
  };

  return (
    <>
      {/* <CreateTemplateDialog ref={createTemplateRef} /> */}
      {/* <TemplateDialog ref={templateEditorDialogRef} /> */}
      <ActionDialog
        ref={actionDialogRef}
        createTemplateRef={createTemplateRef}
      />
      <div className="relative">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
          <div className="flex flex-wrap gap-2 p-4 ">
            <AddActionCard
              handleOnClick={() => {
                actionDialogRef.current.onTypeChange('create');
                actionDialogRef?.current?.open();
              }}
            />
            {!!workspaceTemplates?.length &&
              workspaceTemplates.map((item) => (
                <TemplateCard
                  handleTemplateCardOnClick={handleTemplateCardOnClick}
                  key={item?.id}
                  data={item}
                  count={workspaceTemplates.length}
                  actions={actions}
                  showAwsIconsFlag={false}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
