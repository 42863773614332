import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_7' },
  name: 'Nolan Maddox',
  position: 'Real Estate Agent',
  companyName: 'Maddox Group',
  companyAddress: '1234 Elm Street, CO 80202',
  companyLogo: { url: 'companylogo_TEMPLATE_20' },
  footer: `<p>"<span style="color: rgb(255, 0, 0);">BEWARE! WIRE FRAUD IS ON THE RISE</span>."</p><p><span>Accepting wire instructions by email is dangerous. The sender will never request you to send funds or any sensitive personal information (e.g., credit card numbers, bank account information, routing numbers) via unsecured email. If you receive such a request, you are advised to immediately call the sender.</span></p><p><br></p><p><strong>Links in Warning: </strong></p><p><a href="http://www.google.com" class="http://www.google.com" target="_blank">Consumer Protection Notice</a></p>`,
  email: 'nm@maddoxrealty.com',
  phone: '+1 (555) 123-9999',
  website: 'https://www.maddoxrealty.com',
  ctaTitle: 'FIND YOUR HOME',
  ctaUrl: 'https://bnb.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

export const inputValues2 = {
  name: 'Eric Moyer',
  position: 'Fontend Developer at NEW WAVE',
  website: 'https://www.newwavemedia.com',
  companyLogo: { url: 'companylogo_TEMPLATE_42_1' },
  companyEmail: 'eric.moyer@newwavemedia.com',
  companyPhone: '+1 (555) 123-9876',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com/company/#',
  github: 'https://www.github.com/company/#',
  signOff: 'Kind regards,',
};

const Template42 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#a8071a',
      font: 'Trebuchet MS, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#a8071a',
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 80,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 100,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#a8071a',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#a8071a',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 42',
      image: null,
      key: 'TEMPLATE_42',
      description: null,
      isActive: true,
      config: null,
      templateId: 42,
    },
    name: '42',
  },
  {
    id: '8be53886-3c9f-4e2a-8f94-9606f7d1a44f',
    fields: getFieldsArray(inputValues2),
    design: {
      primaryBrandColor: '#00275B',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#001229',
            fontSize: 12,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#00275B',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
            borderRadius: 4,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 120,
          },
        },
        signOff: {
          style: {
            fontSize: 16,
            color: '#00275B',
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#00275B',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#a8071a',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 42',
      key: 'TEMPLATE_42',
      description: null,
      isActive: true,
      config: null,
      templateId: 42,
    },
    name: '42',
  },
];

export default Template42;
