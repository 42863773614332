/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { MicrosoftLogo } from 'assets/svg';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useFetchWorkspaceById, useWorkspaceId } from 'hooks/workspace';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import {
  useSetMicrosoftAccessToken,
  useRemoveMicrsoftAccessToken,
} from 'hooks/microsoft-integration';
import { encryptToken } from 'common/utils';
import { toast } from 'hooks/use-toast';
import Button from 'components/Button';
import { loginRequest } from 'modules/Integrations/utils/msalConfig';

const MicrosoftIntegration = ({ onIntegrationComplete }) => {
  const {
    state: { isMicrosoftConnected },
    setIsMicrosoftConnected,
  } = useWorkspaceContext();

  const [fetchWorkspaceById] = useFetchWorkspaceById();
  const { workspaceId } = useWorkspaceId();
  const [workspace, setWorkspace] = useState({});
  const [setMicrosoftAccessToken] = useSetMicrosoftAccessToken();
  const [removeMicrosoftAccessToken] = useRemoveMicrsoftAccessToken();
  const { instance, accounts, inProgress } = useMsal();

  const fetchCurrentWorkspace = async () => {
    const res = await fetchWorkspaceById({
      variables: {
        where: {
          id: workspaceId,
        },
      },
    });
    if (res) {
      setWorkspace(res?.data?.getWorkspaceById?.data);
    }
  };

  useEffect(() => {
    fetchCurrentWorkspace();
  }, []);

  const handleMicrosoftConnectClick = async () => {
    try {
      // Check if authentication is already in progress
      if (
        inProgress !== InteractionStatus.Startup &&
        inProgress !== InteractionStatus.Login
      ) {
        try {
          // Force popup login with specific configuration to avoid redirect issues
          const popupWidth = 600;
          const popupHeight = 600;
          const left = window.innerWidth / 2 - popupWidth / 2 + window.screenX;
          const top = window.innerHeight / 2 - popupHeight / 2 + window.screenY;

          // Custom popup configuration
          const customPopupConfig = {
            popupSize: { width: popupWidth, height: popupHeight },
            popupPosition: { top, left },
            popupWindowName: 'microsoftLoginPopup',
            popupWindowAttributes: `width=${popupWidth},height=${popupHeight},top=${top},left=${left},resizable,scrollbars=yes`,
          };

          // Direct popup acquisition of token with no prior login step
          const tokenResponse = await instance.acquireTokenPopup({
            ...loginRequest,
            prompt: 'select_account',
            ...customPopupConfig,
          });

          // Process the token response
          const { idTokenClaims } = tokenResponse;
          const userPrincipalName =
            idTokenClaims?.upn || idTokenClaims?.preferred_username;
          const domain = userPrincipalName
            ? userPrincipalName.split('@')[1]
            : null;

          // Encrypt the token
          const encryptedToken = await encryptToken(tokenResponse?.accessToken);

          if (encryptedToken) {
            try {
              // Save the token to your backend
              await setMicrosoftAccessToken({
                variables: {
                  data: {
                    encryptedAccessToken: encryptedToken,
                    expiresOn: tokenResponse?.expiresOn?.toString(),
                    scopes: Array.isArray(tokenResponse?.scopes)
                      ? tokenResponse.scopes
                      : [tokenResponse?.scopes],
                    domain,
                    workspaceId,
                  },
                },
              });

              setIsMicrosoftConnected(true);
              fetchCurrentWorkspace();
              toast({
                closeicn: 'success',
                description: 'Microsoft Access Token Set Successfully',
              });

              if (onIntegrationComplete) {
                onIntegrationComplete();
              }
            } catch (tokenSaveError) {
              throw new Error(
                `Error saving Microsoft token: ${tokenSaveError.message}`,
              );
            }
          }
        } catch (popupError) {
          // Provide specific error guidance based on error type
          if (popupError.errorCode === 'user_cancelled') {
            toast({
              closeicn: 'warning',
              description: 'Authentication was cancelled. Please try again.',
            });
          } else if (popupError.errorCode === 'popup_window_error') {
            toast({
              closeicn: 'error',
              description:
                'Popup was blocked. Please allow popups for this site and try again.',
            });
          } else {
            toast({
              closeicn: 'error',
              description: 'Microsoft authentication failed. Please try again.',
            });
          }
        }
      } else {
        toast({
          closeicn: 'warning',
          description: 'Authentication already in progress, please wait...',
        });
      }
    } catch (error) {
      toast({
        closeicn: 'error',
        description: 'Failed to connect to Microsoft. Please try again.',
      });
    }
  };

  const handleMicrosoftDisconnectClick = async () => {
    try {
      // First, update the local UI state immediately to ensure UI responsiveness

      // Logout using popup
      await instance.logoutPopup({
        postLogoutRedirectUri: window.location.origin,
        mainWindowRedirect: false,
      });

      setIsMicrosoftConnected(false);

      // Remove the token from your backend
      if (workspaceId) {
        try {
          const response = await removeMicrosoftAccessToken({
            variables: {
              where: {
                workspaceId,
              },
            },
          });

          // Check if the response contains the expected data
          if (response?.data?.disconnectMicrosoft) {
            fetchCurrentWorkspace();
            toast({
              closeicn: 'success',
              description: 'Microsoft successfully disconnected',
            });
          } else if (response?.errors) {
            // Log the error but still proceed with UI updates
            toast({
              closeicn: 'warning',
              description:
                'Partial disconnect: Microsoft session ended but there was an issue with server cleanup.',
            });
          }
        } catch (backendError) {
          toast({
            closeicn: 'warning',
            description:
              'Microsoft session ended but there was a server error.',
          });
        }
      }

      // Always close the dialog after disconnect attempt
      if (onIntegrationComplete) {
        onIntegrationComplete();
      }
    } catch (error) {
      // Revert the UI state since the disconnect failed
      setIsMicrosoftConnected(true);

      toast({
        closeicn: 'error',
        description: 'Error disconnecting from Microsoft. Please try again.',
      });
    }
  };

  const handleInstallClick = () => {
    window.open(
      'https://drive.google.com/drive/folders/1_tWa6edOG2SgPs5iBHhPz6guXhAHveBd?usp=sharing',
      '_blank',
    );
  };

  return (
    <div className="flex flex-col items-center justify-center bg-primary-0">
      <div className="bg-white rounded-lg p-10 shadow-md text-center bg-white-0">
        <div className="max-w-[600px] mx-auto mb-5">
          <div className="flex justify-center mb-6">
            <MicrosoftLogo className="w-1/3" />
          </div>
          <p className="text-gray-600 mb-4 text-[15px]">
            Install and deploy the Syncsignature Add-in for Microsoft 365 to
            streamline email signatures across your organization.
          </p>

          {/* <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <p className="text-gray-600 text-[15px]">
              Click on the button below to install the SyncSignature Add-in from{' '}
              <strong>Microsoft App Source</strong>.
            </p>
            <p className="text-gray-600 text-[15px]">
              You will be redirected to the <strong>Add-in installation</strong>{' '}
              in your Microsoft 365 admin panel. If you are redirected to your
              Outlook Inbox, you are{' '}
              <strong>not connected as a global admin</strong>. In this case,
              the add-in will not function properly.
            </p>
            <p className="text-gray-600 text-[15px]">
              Choose the users for whom you want to deploy the add-in, then
              click <strong>Next</strong>. We recommend selecting{' '}
              <strong>"Entire Organization"</strong>, unless you want to test it
              with a small group first.
            </p>
            <p className="text-gray-600 text-[15px]">
              Click on <strong>"Accept Permissions"</strong> and approve the
              required permissions in the pop-up window.
            </p>
            <p className="text-gray-600 text-[15px]">
              Click on <strong>"Finish Deployment"</strong> to complete the
              installation. The add-in will now be deployed across your{' '}
              <strong>MO365 account</strong> and all Outlook users. According to
              Microsoft, it may take <strong>up to six hours</strong> for the
              app to appear in Outlook, but in practice, it usually takes
              <strong>less than 30 minutes</strong>.
            </p>
            <p className="text-gray-600 text-[15px]">
              Once the installation is complete, you will receive a{' '}
              <strong>confirmation email</strong>. The{' '}
              <strong>installation for microsft button</strong> will be
              activated in your <strong>groups</strong> tab.
            </p>
            {isMicrosoftConnected && workspace?.workspaceDomain && (
              <div>
                <p className="text-[red] text-[15px]">
                  Connected Domain: <span>{workspace?.workspaceDomain}</span>
                </p>
              </div>
            )}
          </div> */}
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <p className="text-gray-600 font-semibold my-0">Step 1:</p>
            <p className="text-gray-600 text-[15px]">
              Install the SyncSignature Microsoft add-in using the link provided
              below. This requires M365 admin access. If you are not an admin,
              please contact your workspace administrator to install the app.
            </p>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg">
            <p className="text-gray-600 font-semibold my-0">Step 2:</p>
            <p className="text-gray-600 text-[15px]">
              You need to authorize with your M365 admin account and then click
              on Connect button below. Once connected, go to Teammates section
              to import all your domain users to start managing signatures for
              them.
            </p>
          </div>
        </div>
        <Button
          className="mr-2"
          onClick={
            isMicrosoftConnected
              ? handleMicrosoftDisconnectClick
              : handleMicrosoftConnectClick
          }
        >
          {isMicrosoftConnected ? 'Disconnect' : 'Connect'}
        </Button>
        <Button onClick={handleInstallClick}>Install Addin</Button>
      </div>
    </div>
  );
};

export default MicrosoftIntegration;
