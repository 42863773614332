/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  useCreateWorkspaceTemplate,
  useDeleteWorkspaceTemplate,
  useUpdateWorkspaceTemplate,
  useUpdateWorkspaceTemplateQueue,
} from 'hooks/workspace-template';
import { useRouter } from 'hooks/router';
import { useUpdateGroup } from 'hooks/group';
import { useWorkspaceId } from 'hooks/workspace';
import {
  DEFAULT_DESIGN_STYLE,
  DISPLAY_MODE,
  DUMMY_HEADSHOT_URL,
  FONT_FAMILY,
  PRIMARY_COLOR,
} from 'common/constants';
import { get, omit } from 'lodash';
import { toast } from 'hooks/use-toast';
import { signatureFieldUpdate } from 'lib/utils';
import { cloneAndRemoveTypename, getMatchingFields } from 'common/utils';

import CreateTemplate from './components/CreateTemplate';
import RenameTemplate from './components/RenameTemplate';
import DeleteTemplate from './components/DeleteTemplate';
import DuplicateTemplate from './components/DuplicateTemplate';
import AssignToGroup from './components/AssignToGroup';

const ActionDialog = forwardRef(({ createTemplateRef }, ref) => {
  const {
    state: { workspaceTemplates },
    setWorkspaceTemplates,
  } = useWorkspaceContext();

  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [data, setData] = useState(null);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    onTypeChange: (t) => setType(t),
    onDataChange: (d) => setData(d),
  }));

  const [deleteWorkspaceTemplate] = useDeleteWorkspaceTemplate();
  const [updateWorkspaceTemplate] = useUpdateWorkspaceTemplate();
  const [createWorkspaceTemplate] = useCreateWorkspaceTemplate();
  const [UpdateWorkspaceTemplateQueue] = useUpdateWorkspaceTemplateQueue();

  const { navigate, location } = useRouter();
  const { workspaceId: id } = useWorkspaceId();
  const [updateGroup] = useUpdateGroup();

  const handleCreateTemplate = async (values, createType) => {
    const { name, signature = {} } = values;
    const { fields = [] } = signature;
    const templateFields = [
      { name: 'name', value: 'Name' },
      { name: 'position', value: 'Position' },
      { name: 'email', value: 'Email' },
      { name: 'department', value: 'Department' },
      { name: 'phone', value: 'Phone' },
      { name: 'headshotUrl', value: DUMMY_HEADSHOT_URL },
    ];
    const matchingFields = getMatchingFields(templateFields, fields);

    const updatedFields = signatureFieldUpdate(fields, matchingFields);

    const data = {
      name,
      design: {
        primaryBrandColor: PRIMARY_COLOR,
        theme: DISPLAY_MODE?.LIGHT,
        font: FONT_FAMILY,
        styles: DEFAULT_DESIGN_STYLE,
        icons: [],
      },
      ...signature,
      fields: updatedFields,
      groupId: null,
    };
    // createTemplateRef?.current?.onDataChange(data);
    // createTemplateRef?.current?.open();

    try {
      const res = await createWorkspaceTemplate({
        variables: {
          data,
          where: {
            workspaceId: id,
          },
        },
      });
      // if (groupId) {
      //   await updateGroup({
      //     variables: {
      //       data: {
      //         templateId: res?.data?.createWorkspaceTemplate?.data?.id,
      //       },
      //       where: {
      //         id: groupId,
      //       },
      //     },
      //   });
      // }
      if (res?.data) {
        toast({
          closeicn: 'success',
          description: 'Created successfully',
        });
      }
      if (!signature) {
        const workspaceTemplateId =
          res?.data?.createWorkspaceTemplate?.data?.id;
        navigate(
          `${location.pathname}?workspaceTemplateId=${workspaceTemplateId}`,
        );
      }
    } catch (error) {
      toast({
        description: 'Error creating template',
        closeicn: 'destructive',
      });
    }
    ref?.current?.close();
  };

  const handleRenameTemplate = async (values) => {
    const { name, templateId } = values;
    const { workspaceId, id: _, ...prevData } = cloneAndRemoveTypename(data);
    try {
      await UpdateWorkspaceTemplateQueue({
        variables: {
          data: { ...prevData, name },
          where: {
            templateId,
            workspaceId: id,
          },
        },
      });
      toast({
        closeicn: 'success',
        description: 'Update successfully',
      });
    } catch (error) {
      toast({
        description: 'Error renaming template',
        closeicn: 'destructive',
      });
    }
    ref?.current?.close();
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteWorkspaceTemplate({
        variables: {
          where: {
            templateId: data?.id,
            workspaceId: id,
          },
        },
      });
      setWorkspaceTemplates(
        workspaceTemplates.filter((template) => template.id !== data?.id),
      );
    } catch (error) {
      toast({
        description: 'Error deleting template',
        closeicn: 'destructive',
      });
    }
    ref?.current?.close();
  };

  const handleAssignToGroup = async (values) => {
    const { groupId } = values;
    // const { templateId } = groups.find((items) => items?.id === groupId);
    // if (templateId) {
    //   setOpen({ state: true, data: groupId });
    // } else {
    await updateGroup({
      variables: {
        data: { templateId: data?.id },
        where: { id: groupId },
      },
    });
    // }
    toast({
      closeicn: 'success',
      description: 'Assign to group successfully',
    });
    ref?.current?.close();
  };

  const getCloneSignatureName = (name) => {
    const parts = name.split('_');
    const lastPart = parts.pop();
    const startName = parts.join('_');
    const numberSuffix = parseInt(lastPart, 10);

    if (Number(numberSuffix)) {
      const newName = `${startName}_${numberSuffix + 1}`;
      const findIndexOfName = workspaceTemplates.findIndex(
        (s) => s.name === newName,
      );
      if (findIndexOfName === -1) {
        return `${startName}_${numberSuffix + 1}`;
      }
    }
    return `${name}_1`;
  };

  const handleDuplicate = async () => {
    // Implement the logic for duplicating a template
    const {
      id,
      updatedAt,
      createdAt,
      name,
      headshotId,
      headshot,
      workspaceId,
      ...restdata
    } = cloneAndRemoveTypename(data);

    const templatedata = {
      ...restdata,
      name: getCloneSignatureName(name),
      groupId: null,
    };

    const res = await createWorkspaceTemplate({
      variables: { data: templatedata, where: { workspaceId } },
    });
    const response = get(res, 'data.createWorkspaceTemplate.data', null);
    if (response) {
      const listDataClone = [...workspaceTemplates, response];
      toast({
        closeicn: 'success',
        description: 'Duplicate successfully',
      });
      setWorkspaceTemplates(listDataClone);
    }

    ref?.current?.close();
  };

  const handleOnClick = (values, createType) => {
    try {
      switch (type) {
        case 'create':
          handleCreateTemplate(values);
          break;
        case 'rename':
          handleRenameTemplate(values);
          break;
        case 'delete':
          handleDeleteTemplate();
          break;
        case 'assign':
          handleAssignToGroup(values);
          break;
        case 'duplicate':
          handleDuplicate();
          break;
        default:
          throw new Error('Invalid modal state type');
      }
    } catch (error) {
      toast({ closeicn: 'destructive', description: 'Something went wrong' });
    }
  };

  const getDialogContent = () => {
    switch (type) {
      case 'create':
        return (
          <CreateTemplate
            onClose={ref?.current?.close}
            handleOnClick={handleOnClick}
          />
        );
      case 'rename':
        return (
          <RenameTemplate
            onClose={ref?.current?.close}
            data={data}
            handleOnClick={handleOnClick}
          />
        );
      case 'delete':
        return (
          <DeleteTemplate
            onClose={ref?.current?.close}
            handleOnClick={handleOnClick}
          />
        );
      case 'duplicate':
        return (
          <DuplicateTemplate
            onClose={ref?.current?.close}
            handleOnClick={handleOnClick}
          />
        );
      case 'assign':
        return (
          <AssignToGroup
            data={data}
            onClose={ref?.current?.close}
            handleOnClick={handleOnClick}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className={`rounded-[6px] ${
          type === 'create' ? 'max-w-xl' : 'max-w-xs'
        }`}
        hideCloseButton
      >
        {isOpen && getDialogContent()}
      </DialogContent>
    </Dialog>
  );
});

export default ActionDialog;
