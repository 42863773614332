import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_33' },
  name: 'Sophia Bennett',
  position: 'Sales Executive',
  companyAddress: 'Site, No. 4, Ferozepur Rd, India',
  email: 'sophia.bennet@donnastroupe.com',
  phone: '+91 83209 28999',
  website: 'www.donnastroupe.com',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_23',
  ctaBannerUrl: 'https://yourwebsite.com',
  facebook: 'https://facebook.com/#',
  instagram: 'https://www.instagram.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
};

const Template23 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#2B554E', // Dark green color from Images 4-6
      font: 'Arial, sans-serif', // Font from Image 5
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'banner', // Using a banner image instead of a button
            width: 400, // Width from Image 7
            borderRadius: 0, // Border radius from Image 7
          },
        },
        font: {
          style: {
            color: '#3D3D3D', // Text color from Image 5
            fontSize: 14, // Font size from Image 5
            lineHeight: 1.5, // Line height from Image 5
          },
        },
        headerFont: {
          style: {
            fontSize: 18, // Name font size from Image 5
          },
        },
        icon: {
          type: 'square', // Square icon type selected in Image 4
          style: {
            size: 20, // Social media icon size from Image 4
          },
        },
        socialIcon: {
          type: 'square', // Square social media icon type selected in Image 4
          style: {
            color: '#2B554E', // Same as theme color
            size: 20, // Social media icon size from Image 4
          },
        },
        companyLogo: {
          style: {
            width: 150, // Approximated from Image 1
          },
        },
        ctaBanner: {
          style: {
            width: 445, // Approximated width of banner in Image 1
          },
        },
        headshot: {
          style: {
            width: 100, // Profile picture width from Image 2
            borderRadius: 0, // Profile picture radius from Image 2 (0%)
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#2B554E', // Same as theme color
            width: 2, // Separator width from Image 5
          },
        },
        footer: {
          style: {
            width: 500, // Footer width from Image 6
          },
        },
        content: {
          style: {
            width: 600, // Signature width from Image 6
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 23',
      image: null,
      key: 'TEMPLATE_23',
      description: null,
      isActive: true,
      config: null,
      templateId: 23,
    },
    name: '23',
  },
];

export default Template23;
