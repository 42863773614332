/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { useAppContext } from 'AppContext';
import GoogleWorkspaceIntegration from '../GoogleWorkspaceIntegration/index';
import MicrosoftIntegration from '../MicrosoftIntegration/index';

const IntegrationDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('google-integration');

  // Expose open and close functions to the parent using `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    type: (t) => setType(t),
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
    },
  }));

  // Handler to close the dialog, will be passed to integration components
  const handleIntegrationComplete = () => {
    setIsOpen(false);
  };

  const componentMap = {
    'google-integration': (
      <GoogleWorkspaceIntegration
        onIntegrationComplete={handleIntegrationComplete}
      />
    ),
    'microsoft-integration': (
      <MicrosoftIntegration onIntegrationComplete={handleIntegrationComplete} />
    ),
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-fit p-1">
        {type && componentMap[type]}
      </DialogContent>
    </Dialog>
  );
});

export default IntegrationDialog;
