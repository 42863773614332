/* eslint-disable import/prefer-default-export */
import AngiCircle from './angi-circle.png';
import EmailCircle from './email-circle.png';
import FacebookCircle from './facebook-circle.png';
import FlickrCircle from './flickr-circle.png';
import GithubCircle from './github-circle.png';
import IMDBCircle from './IMDB-circle.png';
import InstagramCircle from './instagram-circle.png';
import LinkCircle from './link-circle.png';
import LinkdinCircle from './linkdin-circle.png';
import LinksCircle from './links-circle.png';
import LinktreeCircle from './linktree-circle.png';
import LocationCircle from './location-circle.png';
import MediumCircle from './medium-circle.png';
import NextdoorCircle from './nextdoor-circle.png';
import PatreonCircle from './patreon-circle.png';
import PhoneCircle from './phone-circle.png';
import PinterestCircle from './pinterest-circle.png';
import PodcastCircle from './podcast-circle.png';
import RaribleCircle from './rarible-circle.png';
import Rectangle1 from './Rectangle 1.png';
import SkypeCircle from './skype-circle.png';
import TikTokCircle from './tik-tok-circle.png';
import TripadvisorCircle from './tripadvisor-circle.png';
import VimeoCircle from './vimeo-circle.png';
import XCircle from './x-circle.png';
import YelpCircle from './yelp-circle.png';
import YoutubeCircle from './youtube-circle.png';
import BehanceCircle from './behance-circle.png';
import WhatappCircle from './whatsapp-circle.png';
import SoundcloundCircle from './soundclound-circle.png';
import WechatCircle from './wechat-circle.png';
import WeiboCircle from './weibo-circle.png';
import DiscordCircle from './discord-circle.png';
import AngellistCircle from './angellist-circle.png';
import RedditCircle from './reddit-circle.png';
import BlueskyCircle from './bluesky-circle.png';
import DropboxCircle from './dropbox-circle.png';
import GlassdoorCircle from './glassdoor-circle.png';
import SubstackCircle from './substack-circle.png';
import ZillowCircle from './zillow-circle.png';
import ProductHuntCircle from './producthunt-circle.png';
import EtsyCircle from './etsy-circle.png';
import FiverrCircle from './fiverr-circle.png';
import GoogleReviewCircle from './googlereview-circle.png';
import TelegramCircle from './telegram-circle.png';
import TwitchCircle from './twitch-circle.png';
import UpworkCircle from './upwork-circle.png';
import ThreadsCircle from './threads-circle.png';
import DribbbleCircle from './dribbble-circle.png';
import LineCircle from './line-circle.png';

import Angi from './angi.png';
import Facebook from './facebook.png';
import Flickr from './flickr.png';
import Github from './github.png';
import IMDB from './imdb.png';
import Instagram from './instagram.png';
import Link from './link.png';
import Linkdin from './linkedin.png';
// import Links from './link.png';
import Linktree from './linktree.png';
import Medium from './medium.png';
import Nextdoor from './nextdoor.png';
import Pinterest from './pinterest.png';
import Patreon from './patreon.png';
import Podcast from './podcast.png';
import Rarible from './rarible.png';
import Skype from './skype.png';
import TikTok from './tik-tok.png';
import Tripadvisor from './tripadvisor.png';
import Vimeo from './vimeo.png';
import X from './X.png';
import Yelp from './yelp.png';
import Youtube from './youtube.png';
import Behance from './behance.png';
import WhatsApp from './WhatsApp.png';
import Soundcloud from './soundcloud.png';
import Wechat from './wechat.png';
import Weibo from './weibo.png';
import Discord from './discord.png';
import Reddit from './reddit.png';
import Bluesky from './bluesky.png';
import Dropbox from './dropbox.png';
import Glassdoor from './glassdoor.png';
import Substack from './substack.png';
import Zillow from './zillow.png';
import ProductHunt from './producthunt.png';
import Etsy from './etsy.png';
import Fiverr from './fiverr.png';
import GoogleReview from './googlereview.png';
import Telegram from './telegram.png';
import Twitch from './twitch.png';
import Upwork from './upwork.png';
import Threads from './threads.png';
import Dribbble from './dribbble.png';
import Angellist from './angellist.png';
import Line from './line.png';

export const socialImages = {
  'angi-circle': AngiCircle,
  'email-circle': EmailCircle,
  'facebook-circle': FacebookCircle,
  'flickr-circle': FlickrCircle,
  'flicker-circle': FlickrCircle,
  'github-circle': GithubCircle,
  'IMDB-circle': IMDBCircle,
  'imdb-circle': IMDBCircle,
  'instagram-circle': InstagramCircle,
  'link-circle': LinkCircle,
  'linkdin-circle': LinkdinCircle,
  'linkedin-circle': LinkdinCircle,
  'links-circle': LinksCircle,
  'linktree-circle': LinktreeCircle,
  'location-circle': LocationCircle,
  'medium-circle': MediumCircle,
  'nextdoor-circle': NextdoorCircle,
  'patreon-circle': PatreonCircle,
  'phone-circle': PhoneCircle,
  'pinterest-circle': PinterestCircle,
  'podcast-circle': PodcastCircle,
  'rarible-circle': RaribleCircle,
  'Rectangle 1': Rectangle1,
  'skype-circle': SkypeCircle,
  'tik-tok-circle': TikTokCircle,
  'tiktok-circle': TikTokCircle,
  'tripadvisor-circle': TripadvisorCircle,
  'vimeo-circle': VimeoCircle,
  'x-circle': XCircle,
  'twitter-circle': XCircle,
  'yelp-circle': YelpCircle,
  'youtube-circle': YoutubeCircle,
  'behance-circle': BehanceCircle,
  'whatsapp-circle': WhatappCircle,
  'soundcloud-circle': SoundcloundCircle,
  'wechat-circle': WechatCircle,
  'weibo-circle': WeiboCircle,
  'discord-circle': DiscordCircle,
  'angellist-circle': AngellistCircle,
  'reddit-circle': RedditCircle,
  'bluesky-circle': BlueskyCircle,
  'dropbox-circle': DropboxCircle,
  'glassdoor-circle': GlassdoorCircle,
  'substack-circle': SubstackCircle,
  'zillow-circle': ZillowCircle,
  'producthunt-circle': ProductHuntCircle,
  'etsy-circle': EtsyCircle,
  'fiverr-circle': FiverrCircle,
  'googlereview-circle': GoogleReviewCircle,
  'telegram-circle': TelegramCircle,
  'twitch-circle': TwitchCircle,
  'upwork-circle': UpworkCircle,
  'thread-circle': ThreadsCircle,
  'dribbble-circle': DribbbleCircle,
  'line-circle': LineCircle,

  angi: Angi,
  facebook: Facebook,
  flickr: Flickr,
  github: Github,
  imdb: IMDB,
  instagram: Instagram,
  link: Link,
  linkdin: Linkdin,
  linktree: Linktree,
  medium: Medium,
  nextdoor: Nextdoor,
  patreon: Patreon,
  pinterest: Pinterest,
  podcast: Podcast,
  rarible: Rarible,
  skype: Skype,
  tiktok: TikTok,
  tripadvisor: Tripadvisor,
  vimeo: Vimeo,
  yelp: Yelp,
  youtube: Youtube,
  behance: Behance,
  whatsapp: WhatsApp,
  soundcloud: Soundcloud,
  wechat: Wechat,
  weibo: Weibo,
  discord: Discord,
  reddit: Reddit,
  bluesky: Bluesky,
  dropbox: Dropbox,
  glassdoor: Glassdoor,
  substack: Substack,
  zillow: Zillow,
  producthunt: ProductHunt,
  etsy: Etsy,
  fiverr: Fiverr,
  googlereview: GoogleReview,
  telegram: Telegram,
  twitch: Twitch,
  upwork: Upwork,
  threads: Threads,
  dribbble: Dribbble,
  angellist: Angellist,
  twitter: X,
  line: Line,
};
