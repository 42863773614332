/* eslint-disable no-undef */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import {
  useAssignGroupToTeammates,
  useAssignGroupToTeammatesQueue,
  useGetTeammatesByGroupId,
} from 'hooks/teammate';
import { useGroupId, useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import TeammateListing from './components/TeammateListing';

const AddExistingTeammateDialog = forwardRef(({ group, onClose }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    state: { workspaceTeammates },
    setWorkspaceTeammates,
  } = useWorkspaceContext();
  const [assignGroupToTeammates] = useAssignGroupToTeammates();
  const [assignGroupToTeammatesQueue] = useAssignGroupToTeammatesQueue();
  const { workspaceId } = useWorkspaceId();
  const { groupId } = useGroupId();
  const [loading, setLoading] = useState(false);
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      setSearchTerm('');
      if (onClose) {
        onClose();
      }
    },
  }));

  const fetchWorkspaceTeammates = async () => {
    const res = await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  const otherGroupTeammates =
    workspaceTeammates?.filter((wt) => wt.groupId !== groupId) ?? [];

  // const handleAddTeammates = async (values) => {
  //   const res = await assignGroupToTeammates({
  //     variables: {
  //       data: {
  //         groupId,
  //         workspaceId,
  //         ids: values.map((t) => t),
  //       },
  //     },
  //   });
  // if (res?.data?.assignGroupToTeammates) {
  //   // setWorkspaceTeammates([
  //   //   ...workspaceTeammates,
  //   //   ...(res?.data?.assignGroupToTeammates?.data ?? []),
  //   // ]);
  //   ref.current.close();
  //   }
  // };

  const handleAddTeammates = async (values) => {
    setLoading(true);
    try {
      const res = await assignGroupToTeammatesQueue({
        variables: {
          data: {
            groupId,
            workspaceId,
            ids: values.map((t) => t),
          },
        },
      });
      if (res?.data?.assignGroupToTeammatesQueue?.message) {
        await fetchWorkspaceTeammates();
        ref.current.close();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error adding teammates:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = otherGroupTeammates.filter(
    (item) =>
      item?.workspaceTeammateDetails?.email
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.workspaceTeammateDetails?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => ref?.current?.close()}>
      <DialogContent className="max-w-md p-6 rounded-md">
        <DialogTitle className="text-lg font-semibold">
          Add Teammates
        </DialogTitle>
        {/* <DialogDescription>
            Select teammate's emails to assign to{' '}
            <span className=" font-bold">{group?.name}</span>
          </DialogDescription> */}

        <div className="mt-4">
          <Input
            placeholder="Search email, name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <TeammateListing
          teammatesData={filteredData}
          handleAddTeammates={handleAddTeammates}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
});

export default AddExistingTeammateDialog;
