/* eslint-disable no-console */
/* eslint-disable no-undef */
// Detect environment to set appropriate configuration

export const redirectUri = `${window.location.origin}`;

const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

export const msalConfig = {
  auth: {
    clientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
    navigateFrameWait: 0,
    noTokenRenewal: true, // Important: Prevent automatic token renewal which might use redirects
  },
};

// Define the scopes needed for the application
export const loginRequest = {
  scopes: [
    'Mail.Send',
    'MailboxSettings.ReadWrite',
    'User.Read',
    'User.Read.All',
    'Directory.Read.All',
  ],
  prompt: 'select_account',
};

// Configure MSAL after import based on your app needs
export function configureMsal(instance) {
  // Register redirect handler to catch and process any redirects
  instance.handleRedirectPromise().catch(error => {
    console.log("Redirect handling error", error);
  });

  return instance;
}