import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  headshotUrl: { url: 'headshot_TEMPLATE_15' },
  name: 'Anthony Baker',
  position: 'CEO - Interior Design Agency',
  email: 'anthony.baker@unchapter.com',
  phone: '(345) 087-1239',
  website: 'www.unchapter.com',
  ctaTitle: 'BOOK A 1:1 CONSULTATION',
  ctaUrl: 'https://unchapter.com',
  facebook: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com',
  signOff: 'Best regards,',
};

const Template15 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#F42272',
      font: 'Times New Roman',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 15,
          },
        },
        font: {
          style: {
            color: '#5A001C',
            fontSize: 14,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 19,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 19,
            color: '#F42272',
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 100,
            borderRadius: 0,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
            color: '#F42272',
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#F42272',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signature: {
          style: {
            width: 600,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F42272',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 15',
      image: null,
      key: 'TEMPLATE_15',
      description: null,
      isActive: true,
      config: null,
      templateId: 15,
    },
    name: '15',
  },
];

export default Template15;
