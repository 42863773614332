/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SET_MICROSOFT_ACCESS_TOKEN = gql`
  mutation setMicrosoftAccessToken($data: ExchangeMicrosoftTokenInput!) {
    setMicrosoftAccessToken(data: $data) {
      url
    }
  }
`;

export const SET_MICROSOFT_EMAIL_SIGNATURES = gql`
  mutation SetMicrosoftEmailSignatures($where: SetEmailSignatureInput!) {
    setMicrosoftEmailSignatures(where: $where) {
      success
      message
      installedSignatureIds
    }
  }
`;

export const REMOVE_MICROSOFT_ACCESS_TOKEN = gql`
  mutation RemoveMicrosoftAccessToken($where: GetValidAccessTokenInput!) {
    removeMicrosoftAccessToken(where: $where) {
      success
      message
    }
  }
`;
