import React from 'react';

/**
 * Renders a placeholder for templates during loading
 * Displays an animated loading state for templates
 */
const TemplatePlaceholder = () => (
  <div className="mb-4">
    <div className="headshot-border p-4 rounded-[8px] bg-white-0">
      <div className="animate-pulse flex flex-col space-y-3">
        <div className="h-24 bg-gray-200 rounded-md w-full" />
        <div className="h-3 bg-gray-200 rounded-md w-3/4" />
        <div className="h-3 bg-gray-200 rounded-md w-1/2" />
      </div>
    </div>
  </div>
);

export default TemplatePlaceholder;