import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Amy Jones',
  position: 'Founder, SHINE Cosmetic',
  email: 'amy.jones@shine.com',
  phone: '+1 (555) 789-2345',
  companyAddress: '11266 Monarch St. Ste B, Garden Grove, CA 92841',
  website: 'https://www.shine.com',
  facebook: 'https://facebook.com/company/#',
  instagram: 'https://www.instagram.com/company/#',
  tiktok: 'https://www.tiktok.com/company/#',
  youtube: 'https://www.youtube.com/company/#',
};

const Template19 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#145DA0',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 200,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#0E2743',
            fontSize: 13,
            lineHeight: 1.1,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#145DA0',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 116,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 115,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#145DA0',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '411d31b4-5947-4b7c-aa77-f6025dc1dcd1',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#52C41A',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '411d31b4-5947-4b7c-aa77-f6025dc1dcd1',
      name: 'template 19',
      image: null,
      key: 'TEMPLATE_19',
      description: null,
      isActive: true,
      config: null,
      templateId: 19,
    },
    name: '19',
  },
];

export default Template19;
