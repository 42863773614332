/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React from 'react';

const MicrosoftIntegration = () => {
  // const [exchangeMicrosoftToken] = useExchangeMicrosoftToken();
  // const fetchCode = async () => {
  //   const currentUrl = window.location.href;
  //   const extractedCode = getCodeFromUrl(currentUrl);
  //   const pkce_verifier = sessionStorage.getItem('pkce_verifier');

  //   console.log('pkce_verifier', pkce_verifier);
  //   if (extractedCode && pkce_verifier) {
  //     try {
  //       await exchangeMicrosoftToken({
  //         variables: {
  //           where: {
  //             code: extractedCode,
  //             codeVerifier: pkce_verifier,
  //           },
  //         },
  //       });
  //       // Clear the code verifier from session storage
  //       sessionStorage.removeItem('pkce_verifier');
  //       // Redirect to a success page or dashboard
  //     } catch (error) {
  //       console.error('Failed to exchange Microsoft token:', error);
  //       // Redirect to an error page
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchCode();
  // }, []);

  return <div>MicrosoftIntegration</div>;
};

export default MicrosoftIntegration;
