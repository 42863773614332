import React from 'react';
import get from 'lodash/get';
import { TEXT_IDS } from 'common/constants';

const Divider = ({
  style = {},
  signature = {},
  design = {},
  colSpan = 2,
  horizontalBorder = true,
  verticalBorder = false,
}) => {
  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <td
      aria-label="divider"
      className={TEXT_IDS.SEPARATOR}
      colSpan={colSpan}
      style={{
        borderTop: horizontalBorder
          ? `${separatorWidth}px ${separatorType} ${separatorColor}`
          : 'none',
        borderRight: verticalBorder
          ? `${separatorWidth}px ${separatorType} ${separatorColor}`
          : 'none',
        paddingBottom: '8px',

        ...style,
      }}
    />
  );
};

export default Divider;
