/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { clsx } from 'clsx';
import { URL_REGEX } from 'common/utils';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const signatureFieldUpdate = (fields, newFields) => {
  // Create a map from the newFields for quick lookups
  const newFieldsMap = new Map(
    newFields.map((field) => [field.name, { ...field }]),
  );
  // Iterate over fields and update them based on newFieldsMap
  const updatedFields = fields.map((field) => {
    if (newFieldsMap.has(field.name)) {
      return {
        ...field,
        ...newFieldsMap.get(field.name),
        isVariable: true,
      };
    }
    return { ...field, isVariable: false };
  });

  // Add new fields that are not in the original fields
  newFields.forEach((newField) => {
    if (!fields.find((field) => field.name === newField.name)) {
      updatedFields.push({ ...newField, isVariable: false });
    }
  });

  return updatedFields;
};

export const isValidUrl = (url) => {
  const urlPattern = new RegExp(URL_REGEX);
  return !!urlPattern.test(url);
};

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export function getCodeFromUrl(url){
  const urlObj = new URL(url);
  const hashParams = new URLSearchParams(urlObj.hash.slice(1));
  return hashParams.get('code');
}


export function generateCodeVerifier() {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array); // Use browser's crypto API
  return btoa(String.fromCharCode(...array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, ''); // Base64URL encoding
}

// Generate a code challenge from the code verifier
export async function generateCodeChallenge(verifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, ''); // Base64URL encoding
}

export function storeVerifier(verifier) {
  if (typeof window !== 'undefined') {
    try {
      sessionStorage.setItem('pkce_verifier', verifier);
    } catch (error) {
      console.error('Failed to store the PKCE verifier:', error);
    }
  } else {
    console.warn('Session storage is not available in this environment.');
  }
}

export function getStoredVerifier() {
  if (typeof window !== 'undefined') {
    try {
      return sessionStorage.getItem('pkce_verifier');
    } catch (error) {
      console.error('Failed to retrieve the PKCE verifier:', error);
      return null;
    }
  } else {
    console.warn('Session storage is not available in this environment.');
    return null;
  }
}