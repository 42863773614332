/* eslint-disable no-nested-ternary */
import { getTemplateImages, SOCIAL_LINKS } from 'common/constants';

/* eslint-disable import/prefer-default-export */

// List of field configurations
const FIELD_CONFIGS = [
  {
    name: 'headshotUrl',
    type: 'employee',
    label: 'Headshot URL',
    image: true,
    supportGif: true,
  },
  { name: 'name', type: 'employee', label: 'Name' },
  { name: 'position', type: 'employee', label: 'Position' },
  { name: 'department', type: 'employee', label: 'Department' },
  { name: 'companyName', type: 'company', label: 'Company Name' },
  { name: 'companyAddress', type: 'company', label: 'Company Address' },
  {
    name: 'companyLogo',
    type: 'company',
    label: 'Company Logo',
    image: true,
    supportGif: true,
  },
  {
    name: 'companyLogoURL',
    type: 'company',
    label: 'Company Logo URL',
    link: true,
  },
  { name: 'footer', type: 'company', label: 'Footer' },
  { name: 'email', type: 'employee', label: 'Email', link: true },
  { name: 'phone', type: 'employee', label: 'Phone' },
  { name: 'website', type: 'company', label: 'Website', link: true },
  { name: 'ctaTitle', type: 'company', label: 'CTA Title' },
  { name: 'ctaUrl', type: 'company', label: 'CTA URL', link: true },
  { name: 'ctaBannerImg', type: 'company', label: 'CTA Banner', image: true },
  {
    name: 'ctaBannerUrl',
    type: 'company',
    label: 'CTA Banner URL',
    link: true,
  },
  { name: 'tiktok', type: 'company', label: 'tiktok', link: true },
  { name: 'facebook', type: 'company', label: 'Facebook', link: true },
  { name: 'instagram', type: 'company', label: 'Instagram', link: true },
  { name: 'linkedin', type: 'company', label: 'LinkedIn', link: true },
  { name: 'youtube', type: 'company', label: 'YouTube', link: true },
  { name: 'behance', type: 'company', label: 'Behance', link: true },
  { name: 'skype', type: 'company', label: 'Skype', link: true },
  { name: 'linktree', type: 'company', label: 'Linktree', link: true },
  { name: 'twitter', type: 'company', label: 'Twitter', link: true },
  { name: 'github', type: 'company', label: 'Github', link: true },
  { name: 'pinterest', type: 'company', label: 'Pinterest', link: true },
  { name: 'dribbble', type: 'company', label: 'Dribbble', link: true },
  { name: 'wechat', type: 'company', label: 'Wechat', link: true },
  { name: 'whatsapp', type: 'company', label: 'Whatsapp', link: true },
  { name: 'medium', type: 'company', label: 'Medium', link: true },
  { name: 'tripadvisor', type: 'company', label: 'tripadvisor', link: true },
  { name: 'podcast', type: 'company', label: 'podcast', link: true },
  { name: 'etsy', type: 'company', label: 'Etsy', link: true },
  { name: 'googlereview', type: 'company', label: 'Googlereview', link: true },
  {
    name: 'socialBanners',
    type: 'company',
    label: 'Social Banners',
    array: true,
  }, // Ensure it's always an array
  { name: 'companyLogoLink', type: 'company', label: 'Company Logo Link' },
  { name: 'companyPhone', type: 'company', label: 'Company Phone' },
  { name: 'companyEmail', type: 'company', label: 'Company Email' },
  { name: 'signOff', type: 'company', label: 'Sign Off' },
  {
    name: 'customFields',
    type: 'company',
    label: 'Custom Fields',
    array: true,
  }, // Ensure it's always an array
  { name: 'phoneTitle', type: 'company', label: 'Phone Title' },
  { name: 'emailTitle', type: 'company', label: 'Email Title' },
  { name: 'websiteTitle', type: 'company', label: 'Website Title' },
  { name: 'addressTitle', type: 'company', label: 'Address Title' },
  { name: 'companyEmailTitle', type: 'company', label: 'Company Email Title' },
  { name: 'companyPhoneTitle', type: 'company', label: 'Company Phone Title' },
  {
    name: 'signOffImgUrl',
    type: 'company',
    label: 'Sign Off Image URL',
    image: true,
    supportGif: true,
  },
];
// Utility function to build field objects dynamically
const buildFieldObject = (name, value, options = {}) => {
  // Check if we have a format specified for this field
  const imageFormat =
    options.supportGif && value && value.format ? value.format : 'png';

  return {
    name,
    type: options.type || 'company',
    label: options.label || name,
    value: (() => {
      if (options.array) return Array.isArray(value) ? value : []; // Ensure arrays remain arrays, otherwise initialize as []
      if (options.image) {
        // If this is an image that supports GIF and we have an object with both value and format
        if (
          options.supportGif &&
          typeof value === 'object' &&
          value !== null &&
          'url' in value
        ) {
          return getTemplateImages(value.url, value.format || 'png');
        }
        // Otherwise use default behavior
        return getTemplateImages(value, imageFormat);
      }
      return value ?? ''; // Default to an empty string if value is undefined/null
    })(),
    link: options.link ? value : '',
    shortLink: '',
    isVariable: false,
    utagEnabled: options.link || false,
    variableValue: null,
  };
};

// Optimized function to return structured fields - only exclude specific social media fields if they're empty
export const getFieldsArray = (data = {}) =>
  FIELD_CONFIGS.map(({ name, ...options }) => {
    const value = data[name] ?? (options.array ? [] : '');

    // List of social media fields to skip if empty
    const socialMediaFields = SOCIAL_LINKS.reduce((names, link) => {
      names.push(link.name);
      return names;
    }, []);

    // Skip creating specific social media fields if they're empty
    if (socialMediaFields.includes(name) && !value) {
      return null;
    }

    return buildFieldObject(name, value, options);
  }).filter(Boolean); // Remove null entries from the array
