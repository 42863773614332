import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Emily Johnson',
  position: 'Founder of BREW SMITH COFFEE',
  companyAddress: '2546 Ventura Drive Sunnyvale CA 94086',
  companyLogo: { url: 'companylogo_TEMPLATE_32', format: 'gif' },
  email: 'emily.johnson@brewsmith.com',
  phone: '540-678-6526',
  ctaBannerImg: 'ctabanner_new_TEMPLATE_32',
  ctaBannerUrl: 'https://www.yourcompany.com',
};

const Template32 = [
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a2',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#5D3722',
      font: 'Trebuchet MS',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        ctaBanner: {
          style: {
            width: 480,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#371D11',
            fontSize: 13,
            lineHeight: 1.5,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'rounded',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
            color: '#5D3722',
          },
        },
        companyLogo: {
          style: {
            width: 130,
          },
        },
        separator: {
          type: 'solid',
          style: {
            color: '#5D3722',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 480,
            borderRadius: 0,
          },
        },
        background: {
          style: {
            color: '#5D3722',
          },
        },
      },
    },
    cta: {
      title: '',
      url: '',
      shortUrl: '',
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: '',
      config: {
        output: null,
        grayScale: null,
        'background-color': '#56B7FF',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 32',
      image: null,
      key: 'TEMPLATE_32',
      description: null,
      isActive: true,
      config: null,
      templateId: 32,
    },
    name: '32',
  },
];

export default Template32;
