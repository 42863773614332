/* eslint-disable no-undef */
import React, { useState } from 'react';
import { LockKeyhole, RefreshCw } from 'lucide-react';
import useExtensionDetection from 'hooks/chrome-extension';
import { Button } from 'components/ui/button';
import LoaderComponent from 'components/LoaderComponent';
import { toast } from 'hooks/use-toast';
import ChromeExtenstion from './ChromeExtenstion';

// Your extension ID - replace with your actual extension ID
const EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID;
const ChromeExtensionWrapper = () => {
  const [isChecking, setIsChecking] = useState(false);
  const { isInstalled, isLoading, checkExtension } =
    useExtensionDetection(EXTENSION_ID);

  const handleCheckAgain = async () => {
    setIsChecking(true);
    const found = await checkExtension();
    setIsChecking(false);

    if (found) {
      toast({
        description: 'Chrome extension detected successfully!',
        closeicn: 'success',
      });
    } else {
      toast({
        description: 'No extension found. please install it',
        closeicn: 'destructive',
      });
    }
  };

  const renderMessage = () => (
    <p className="mt-2 text-sm text-gray-500">
      Install our Chrome extension to use email signatures in Gmail.
    </p>
  );

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (!isInstalled) {
    return (
      <div className="w-full h-[calc(100vh_-_71px)] flex justify-center items-center bg-primary-foreground">
        <div className="max-w-xs p-6 bg-white rounded-lg text-center bg-white-0">
          <LockKeyhole className="mx-auto h-8 w-8 text-primary" />

          <h2 className="mt-2 text-lg font-semibold">
            Install Chrome Extension
          </h2>

          {renderMessage()}

          <div className="mt-4 flex flex-col space-y-2">
            <Button
              onClick={() => {
                window.open(
                  `https://chromewebstore.google.com/detail/hegdpfhigjejafalbfofgcgjhionhkjg?utm_source=item-share-cb`,
                  '_blank',
                );
              }}
              variant="default"
              className="w-full"
            >
              Install Chrome Extension
            </Button>
            {/* <Button
              onClick={() => {
                window.location.href = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`;
              }}
              variant="default"
              className="w-full"
              disabled="true"
            >
              DOwn
            </Button> */}

            <Button
              onClick={handleCheckAgain}
              variant="outline"
              className="w-full"
              disabled={isChecking}
            >
              {isChecking ? (
                <>
                  <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                  Checking...
                </>
              ) : (
                'Check Again'
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return <ChromeExtenstion />;
};

export default ChromeExtensionWrapper;
