import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Amy Brown',
  position: 'CEO at Dental Pharma',
  companyEmail: 'amy.brown@dentalpharma.com',
  companyPhone: '+61 410 309 309',
  companyAddress: '11266 Monarch St. Ste B, Garden Grove, CA 92841',
  website: 'https://www.dentalpharma.com',
  companyLogo: { url: 'companylogo_TEMPLATE_27' },
  linkedin: 'https://www.linkedin.com/company/#',
  twitter: 'https://www.x.com/company/#',
  facebook: 'https://www.facebook.com/company/#',
};

const Template27 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#002D58',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#002D58',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#002D58',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#002D58',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 27',
      image: null,
      key: 'TEMPLATE_27',
      description: null,
      isActive: true,
      config: null,
      templateId: 27,
    },
    name: '27',
  },
];

export default Template27;
