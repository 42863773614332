import { getTemplateImages } from 'common/constants';
import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'RAHUL SHARMA',
  position: 'Software Engineer at INFOTECH SOLUTIONS',
  companyAddress: '34 MG Road, Bengaluru Karnataka 560001',
  companyPhone: '+91 98765 43210#+91 98765 43210#+91 98765 43212',
  companyEmail: 'rahul.sharma@infotechsolutions.in',
  website: 'https://www.infotechsolutions.in',
  footer: `IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.`,
};

const Template45 = [
  {
    id: '8be53886-3c9f-4e2a-8f94-9606f7d1a44f',
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#0C4160',
      font: 'Verdana',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#092F48',
            fontSize: 12,
            lineHeight: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'rounded',
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#0C4160',
            size: 15,
          },
        },
        companyLogo: {
          style: {
            width: 108,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 141,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#0C4160',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            color: '#AAAAAA',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 45',
      key: 'TEMPLATE_45',
      description: null,
      isActive: true,
      config: null,
      templateId: 45,
    },
    name: '45',
  },
];

export default Template45;
