import { getFieldsArray } from 'common/helpers/getFieldsArray';

export const inputValues1 = {
  name: 'Michael Johnson CTO',
  companyName: 'HEXA TECH INNOVATIONS',
  companyLogo: { url: 'companylogo_new_TEMPLATE_25' },
  email: 'michael@hexatechinnovations.co',
  phone: '+44-20-555-0199',
  website: 'www.hexatechinnovations.co',
  companyAddress: '789 Fin, Chicago, IL 60603',
  ctaTitle: 'Join our team →',
  ctaUrl: 'https://facebook.com/#',
  linkedin: 'https://www.linkedin.com/#',
  twitter: 'https://www.x.com/#',
  github: 'https://www.github.com/#',
  signOff: 'Thanks,',
};

const Template25 = [
  {
    fields: getFieldsArray(inputValues1),
    design: {
      primaryBrandColor: '#244350',
      font: 'Georgia',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored', // From image 6
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#142D38', // From image 5
            fontSize: 13,
            lineHeight: 1.4,
          },
        },
        headerFont: {
          style: {
            fontSize: 18, // From image 5
          },
        },
        icon: {
          type: 'letter', // From image 4
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'rounded', // From image 4
          style: {
            color: '#244350',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120, // From image 2
          },
        },
        separator: {
          style: {
            color: '#244350', // From image 5
            width: 1,
            type: 'solid',
          },
        },
        footer: {
          style: {
            width: 500, // From image 5
            color: '#000000',
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
            type: 'text',
            color: '#244350',
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 25',
      image: null,
      key: 'TEMPLATE_25',
      description: null,
      isActive: true,
      config: null,
      templateId: 25,
    },
    name: '25',
  },
];

export default Template25;
